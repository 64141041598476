/* eslint-disable mobx/missing-observer */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { CopyContent } from './CopyContent';

interface TabulatedRowProps {
	label: string;
	value?: string;
	canCopy?: boolean;
	isExpandable?: boolean;
	showBlank?: string;
	testid?: string;
}

export const TabulatedRow: React.FC<TabulatedRowProps> = ({
	label,
	value,
	canCopy = false,
	isExpandable = false,
	showBlank = '-',
	testid,
}) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const textContainerRef = useRef<HTMLDivElement>(null);

	const toggleExpanded = () => {
		setIsExpanded(!isExpanded);
	};

	useEffect(() => {
		if (!isExpanded && textContainerRef.current) {
			textContainerRef.current.scrollTop = 0;
		}
	}, [isExpanded]);

	const copy: Boolean =
		canCopy && value !== undefined && value !== null && value !== '';

	return (
		<>
			<Stack
				direction={'row'}
				sx={{ justifyContent: 'space-between', width: '100%' }}
			>
				<Box sx={{ textAlign: 'left', flex: '1 0 auto', minWidth: '150px' }}>
					<Typography sx={{ fontWeight: 600 }}>{label}</Typography>
				</Box>
				<Box
					sx={{ textAlign: 'right', flex: '3 1 auto', position: 'relative' }}
				>
					<Typography
						ref={textContainerRef}
						sx={{
							display: '-webkit-box',
							WebkitLineClamp: isExpanded ? 'none' : 3,
							WebkitBoxOrient: 'vertical',
							overflow: isExpanded ? 'auto' : 'hidden',
							textOverflow: 'ellipsis',
							lineHeight: '2rem',
							wordBreak: 'break-all',
							whiteSpace: isExpanded ? 'normal' : 'pre-wrap',
							pr: 2,
							maxHeight: isExpanded ? '20rem' : 'none',
						}}
						data-testid={testid}
					>
						{value || showBlank}
					</Typography>
				</Box>
			</Stack>
			<Box sx={{ textAlign: 'right', flex: '3 1 auto', position: 'relative' }}>
				{copy && value && <CopyContent value={value} />}
				{isExpandable && value && value.length > 100 && (
					<Button
						size="small"
						startIcon={
							<KeyboardArrowDownIcon
								sx={{
									transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
								}}
							/>
						}
						onClick={toggleExpanded}
					>
						{isExpanded ? 'View Less' : 'View More'}
					</Button>
				)}
			</Box>
		</>
	);
};
