import {
	GraphObject,
	Margin,
	Node,
	Panel,
	Part,
	Point,
	Shape,
	Spot,
	Stretch,
	Wrap,
} from 'gojs';
import { textColor } from '../constants';
import {
	clearAllHighlighted,
	highlightDirectConnections,
	isControlPressed,
	recurseHighlightAllAncestors,
	recurseHighlightAllDescendants,
} from '../functions';

export type NodeContainerData = {
	isVisible: boolean;
	isHighlighted?: boolean;
};

export function nodeContainer(width: number, children: GraphObject[]) {
	return new Node('Auto', {
		copyable: false,
		deletable: false,
		shadowOffset: new Point(0, 10),
		shadowBlur: 20,
		shadowColor: '#404142',
		selectionAdorned: false,
		...nodeMouseEvents(),
		selectionChanged: (node) => {
			if (node.diagram && node instanceof Node) {
				const diagram = node.diagram;
				diagram.model.commit((m) => {
					m.set(node, 'isShadowed', node.isSelected);
					node.linksConnected.each((link) => {
						// Check if the link has both to and from nodes
						if (link.toNode && link.fromNode) {
							// if the link connects two nodes both in the selection..
							if (
								diagram.selection.contains(link.toNode) &&
								diagram.selection.contains(link.fromNode)
							) {
								m.set(link, 'isSelected', true);
							} else {
								m.set(link, 'isSelected', false);
							}
						}
					});
				});
			}
		},
	})
		.bind('visible', '', (data: NodeContainerData) => {
			return data.isVisible ?? true;
		})
		.bindObject('isShadowed', 'isHighlighted')
		.add(
			new Shape({
				figure: 'RoundedRectangle',
				fill: 'white',
				strokeWidth: 2,
				parameter1: 6,
				width: width,
				spot1: Spot.TopLeft,
				spot2: Spot.BottomRight,
			})
				.bindObject('stroke', 'isHighlighted', (isHighlighted) => {
					return !!isHighlighted ? '#558FA3' : 'grey';
				})
				.bindObject('strokeWidth', 'isHighlighted', (isHighlighted) => {
					return isHighlighted ? 5 : 1;
				}),
			new Panel(Panel.Vertical, {
				name: 'MainPanel',
				defaultStretch: Stretch.Horizontal,
				width: width,
			}).add(...children),
		);
}

export type NodeBodyData = NodeContainerData & {
	group: string | undefined;
	currentColor: string;
	loc?: Point;
	expanderVisible?: boolean;
};

export function nodeBody(
	headerTextProperty: string,
	createSubHeader: () => go.Panel,
	createSubHeader2: null | (() => go.Panel),
	createMainTable: () => go.GraphObject[],
	treeExpanderBuilder: string | null,
	nodeLinkBase: string,
	configurations: {
		nodeWidth: number;
	} = {
		nodeWidth: 350,
	},
) {
	const headerHeight = 30;

	const children: GraphObject[] = [
		new Panel('Spot', {
			shadowVisible: true,
			height: headerHeight,
			width: configurations.nodeWidth - 1,
			margin: new Margin(0.5),
		}).add(
			new Shape('RoundedTopRectangle', {
				strokeWidth: 0,
				parameter1: 6,
				shadowVisible: true,
				height: headerHeight,
			}).bind('fill', '', (data: NodeBodyData) => data.currentColor || 'white'),
			new Shape('RoundedTopRectangle', {
				fill: 'rgba(0, 0, 0, .08)',
				parameter1: 6,
				shadowVisible: true,
				strokeWidth: 0,
				height: headerHeight,
				width: configurations.nodeWidth - 1,
			}),
			GraphObject.make(
				'HyperlinkText',
				(node: go.Node) => {
					const legalEntityId = node.data.legalEntityId || node.data.entityId;

					if (legalEntityId) {
						return (
							nodeLinkBase +
							'/' +
							(node.data.legalEntityId || node.data.entityId) +
							'/information'
						);
					} else {
						return null;
					}
				},
				(node: go.Node) => node.data[headerTextProperty] || '-',
				{
					alignment: new Spot(0, 0.5, 8, 2),
					alignmentFocus: Spot.Left,
					wrap: Wrap.Fit,
					shadowVisible: true,
					width: configurations.nodeWidth,
					font: '16px bold Roboto, Helvetica, Arial, sans-serif',
					stroke: textColor,
				},
			),
		),

		new Panel(Panel.Vertical, {
			margin: new Margin(-0.5, 0.5, -0.5, 0.5),
			padding: 0,
			defaultSeparatorPadding: 0,
		})
			.bind(
				'background',
				'',
				(data: NodeBodyData) => data.currentColor || 'white',
			)
			.add(createSubHeader()),
	];

	if (createSubHeader2 !== null) {
		children.push(
			new Shape('LineH', {
				stroke: 'grey',
				strokeWidth: 1,
				height: 0,
				stretch: Stretch.Horizontal,
			}),
			createSubHeader2(),
		);
	}

	children.push(
		new Shape('LineH', {
			stretch: Stretch.Horizontal,
			stroke: 'black',
			strokeWidth: 1,
			height: 1,
		}),
		...createMainTable(),
	);

	if (treeExpanderBuilder) {
		children.push(
			GraphObject.make(treeExpanderBuilder, {
				alignment: Spot.Bottom,
				background: 'white',
				width: 24,
				margin: new Margin(0, 0, 0.5, 0),
			}),
		);
	}

	return nodeContainer(configurations.nodeWidth, children)
		.bind('location', '', (data: NodeBodyData) => data.loc ?? undefined)
		.bind('movable', '', (data: NodeBodyData) => {
			return data.group !== 'Standalone';
		});
}

export function nodeMouseEvents(): Pick<
	go.Node,
	'mouseEnter' | 'mouseLeave' | 'doubleClick'
> {
	return {
		mouseEnter: (event, current, _prev) => {
			if (isControlPressed(event)) {
				return;
			}

			let node: go.Node = current as go.Node;
			event.diagram.commit((diagram) => {
				diagram.focus(); //this allows for the shift key to work.
				if (event.shift && event.alt) {
					recurseHighlightAllDescendants(node);
				} else if (event.shift) {
					recurseHighlightAllAncestors(node);
				} else if (event.alt) {
					highlightDirectConnections(node);
				}
			});
		},
		mouseLeave: (event, _current, _prev) => {
			if (isControlPressed(event)) {
				return;
			}

			event.diagram.commit((diagram) => {
				clearAllHighlighted(diagram);
			});
		},
		doubleClick: (event, graphObject) => {
			event.diagram.commit((d) => {
				d.model.commit((m) => {
					if (graphObject instanceof Node) {
						m.set(graphObject, 'isSelected', true);
						graphObject.isSelected = true;
					}

					const hideNonSelectedPart = (part: Part) => {
						if (part.data.category === 'Legend') {
							return;
						} else if (part.data.key === 'Standalone') {
							m.set(
								part.data,
								'isVisible',
								d.selection.filter((x) => x.data.group === 'Standalone').count >
									0,
							);
						} else if (!d.selection.contains(part)) {
							m.set(part.data, 'isVisible', false);
						}
					};
					d.nodes.each(hideNonSelectedPart);
				});
			});
		},
	};
}
