import { debounce, Grid } from '@mui/material';
import go from 'gojs';
import { ReactOverview } from 'gojs-react';
import { observer } from 'mobx-react-lite';
import { VisualizationPreferenceNodeData } from 'modules/clients/customer-api/src/api/visualizations';
import { VisualizationOptionPreference } from 'modules/clients/customer-api/src/userPreference';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { T4View } from 'shared/components/t4View';
import { accountMapPrefix } from 'stonly/pagePrefixes';
import { VisualizationsFooter } from '../_shared/_components/visualizationFooter';
import { useVisualizationDispatch } from '../_shared/_providers/visualizationProvider';
import { freezeLayout, loadHiddenNodes } from '../_shared/functions';
import { useAccountMap } from './_providers/accountMapProvider';
import { AccountMapAccountViewRenderer } from './components/accountMapAccountViewRenderer';
import { AccountMapHeaderComponent } from './components/accountMapHeaderComponent';

export type RadioOption =
	| 'singleColor'
	| 'accountPurpose'
	| 'accountStatus'
	| 'entityRegion';

export type VisualizationAccount = {
	erpCode: string | undefined;
	accountCode: string;
	accountStatus: string | undefined;
	accountType: string | undefined;
	accountCurrencyCode: string | undefined;
	naturalAccountNumber: string | undefined;
	entityRegion: string | undefined;
	entityErpCode: string | undefined;
	counterparty: string | undefined;
	counterpartyBranch: string | undefined;

	key: string;
	group: string | undefined;
	isSubaccount: boolean;
	isForeignAccount: boolean;
	currentColor: string | undefined;
	isVisible: boolean;
	isAccountTypeVisible: boolean;
	isEntityRegionVisible: boolean;
	isAccountPurposeVisible: boolean;
	isAccountStatusVisible: boolean;
};

export type VisualizationLink = {
	key: string;
	from: string;
	to: string;
};

export const AccountMapPage: FC = observer(() => {
	const {
		isLoading,
		updatePreferences,
		optionPreferences,
		data: { accountView },
	} = useAccountMap();
	const setZoom = useVisualizationDispatch();

	const [diagram, setDiagram] = useState<go.Diagram>();
	const [showOverviewMap, setShowOverviewMap] = useState<boolean>(true);

	//#region

	const applyDisplayOverviewMapPreference = useCallback(
		(currentOptionPreferences: VisualizationOptionPreference[]) => {
			if (currentOptionPreferences) {
				setShowOverviewMap(
					!(
						currentOptionPreferences?.find(
							(x) => x.optionId === 'displayOverviewMap',
						)?.hide ?? false
					),
				);
			}
		},
		[],
	);

	const loadPreferences = useCallback(
		(
			currentOptionPreferences:
				| VisualizationOptionPreference[]
				| undefined = optionPreferences,
			currentDiagram: go.Diagram | undefined = diagram,
		) => {
			if (currentOptionPreferences && currentDiagram) {
				currentDiagram.commit((d) => {
					applyDisplayOverviewMapPreference(currentOptionPreferences);
				});
			}
		},
		[applyDisplayOverviewMapPreference, diagram, optionPreferences],
	);

	//#endregion

	//#region Node Preferences

	const updateNodeData = useCallback(
		(currentDiagram: go.Diagram) => {
			const nextNodePreferences: VisualizationPreferenceNodeData[] = [];
			currentDiagram.nodes
				.filter(
					(node) =>
						node.visible &&
						node.position.x !== null &&
						!Number.isNaN(node.position.x) &&
						node.position.y !== null &&
						!Number.isNaN(node.position.y),
				)
				.each((node) => {
					nextNodePreferences.push({
						id: node.data.key,
						key: node.data.key,
						x: node.location.x,
						y: node.location.y,
						visible: true,
					});
				});

			updatePreferences({
				nodes: nextNodePreferences,
			});
		},
		[updatePreferences],
	);

	//#endregion

	const selectionMoved = useCallback(
		(event: go.DiagramEvent) => {
			updateNodeData(event.diagram);
		},
		[updateNodeData],
	);

	const onViewportBoundsChangedHandler = useMemo(
		() =>
			debounce((event: go.DiagramEvent) => {
				setZoom(event.diagram.scale);
			}, 200),
		[setZoom],
	);

	useEffect(() => {
		if (diagram) {
			loadPreferences(optionPreferences, diagram);
			loadHiddenNodes(diagram);
		}
	}, [diagram, loadPreferences, optionPreferences]);

	return (
		<T4View loading={isLoading} disablePadding>
			<Grid
				container
				flexDirection="column"
				sx={{ height: '100%', width: '100%', overflow: 'hidden' }}
			>
				<Grid item xs="auto">
					<AccountMapHeaderComponent diagram={diagram} accountView="account" />
				</Grid>
				<Grid
					item
					xs={true}
					sx={{
						position: 'relative',
						boxSizing: 'unset',

						canvas: {
							outline: 'none',
						},
						'.accountview-gojs-diagram, .entityview-gojs-diagram': {
							width: '100%',
							height: '100%',

							'& .canvas': {
								outline: 'none',
							},
						},
					}}
				>
					{showOverviewMap && (
						<ReactOverview
							initOverview={() =>
								new go.Overview(undefined, {
									contentAlignment: go.Spot.Center,
								})
							}
							divClassName=""
							style={{
								backgroundColor: '#eee',
								height: '150px',
								width: '250px',
								position: 'absolute',
								top: '2rem',
								left: '2rem',
								border: 'solid',
								borderWidth: '1px',
								borderColor: 'black',
								zIndex: 10,
							}}
							observedDiagram={diagram ?? null}
						/>
					)}
					<AccountMapAccountViewRenderer
						nodeDataArray={accountView.nodes}
						linkDataArray={accountView.links}
						diagramListeners={[
							['InitialLayoutCompleted', (event) => setDiagram(event.diagram)],
							['ViewportBoundsChanged', onViewportBoundsChangedHandler],
							['SelectionMoved', selectionMoved],
							['AnimationFinished', (event) => freezeLayout(event.diagram)],
						]}
					/>
				</Grid>
				<Grid
					item
					xs="auto"
					sx={{
						paddingBottom: '1rem',
						justifyContent: 'center',
						position: 'absolute',
						bottom: '1rem',
						zIndex: 5,
					}}
				>
					<VisualizationsFooter
						stonlyId={accountMapPrefix}
						diagram={diagram}
						onResetView={() => {
							if (diagram) {
								updatePreferences({
									nodes: [],
								});
								loadPreferences(optionPreferences, diagram);
							}
						}}
					/>
				</Grid>
			</Grid>
		</T4View>
	);
});
