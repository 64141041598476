import { Box, Grid, Typography, useTheme } from '@mui/material';
import { PaymentParty } from 'modules/clients/apiGateway/payments4/payments';
import { FC } from 'react';

export const PaymentPartyInformation: FC<{
	party: PaymentParty | null;
	partyType: 'Initiator' | 'Payee';
}> = ({ party, partyType }) => {
	const rightColumnSx = {
		textAlign: 'right',
	};

	return (
		<>
			<Grid container item xs={12}>
				<Grid item xs={6}>
					<Typography variant="body1" sx={{ fontWeight: 500 }}>
						{partyType} {partyType === 'Initiator' ? 'Entity' : ''} Name
					</Typography>
				</Grid>
				<Grid item xs={6} sx={rightColumnSx}>
					<Typography variant="body1">{party?.displayName}</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12}>
				<Grid item xs={6}>
					<Typography variant="body1" sx={{ fontWeight: 500 }}>
						{partyType} Counterparty
					</Typography>
				</Grid>
				<Grid item xs={6} sx={rightColumnSx}>
					<Typography variant="body1">{party?.bank.displayName}</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12}>
				<Grid item xs={6}>
					<Typography variant="body1" sx={{ fontWeight: 500 }}>
						{partyType} Account
					</Typography>
				</Grid>
				<Grid item xs={6} sx={rightColumnSx}>
					<Typography variant="body1">{party?.accountDisplayName}</Typography>
				</Grid>
			</Grid>

			<Grid container item xs={12}>
				<PaymentBankAndAccountInformationBox
					bankCode={party?.bank.bankCode}
					operatingCountryCode={party?.bank.countryCode}
					routingCode={party?.bank.aba}
					paymentNumber={party?.accountNumber}
				/>
			</Grid>
		</>
	);
};

export const PaymentBankAndAccountInformationBox: FC<{
	bankCode?: string | null;
	operatingCountryCode?: string | null;
	routingCode?: string | null;
	paymentNumber?: string | null;
}> = ({ bankCode, operatingCountryCode, routingCode, paymentNumber }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				backgroundColor: theme.palette.cornflower[50],
				padding: '.5rem',
				borderRadius: '4px',
				fontSize: '.75rem',
				color: theme.palette.charcoal[500],
				marginTop: '0px !important',
			}}
		>
			<Grid item container xs={12} spacing={1}>
				<Grid item xs={12}>
					<Typography sx={{ fontSize: 'inherit' }}>
						Bank Code: {bankCode}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography sx={{ fontSize: 'inherit' }}>
						Bank Country Code: {operatingCountryCode}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography sx={{ fontSize: 'inherit' }}>
						Routing Code: {routingCode}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography sx={{ fontSize: 'inherit' }}>
						Payment Account Number: {paymentNumber}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};
