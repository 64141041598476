import { debounce, Grid } from '@mui/material';
import go from 'gojs';
import { ReactOverview } from 'gojs-react';
import { observer } from 'mobx-react-lite';
import { VisualizationPreferenceOption } from 'modules/clients/customer-api/src/api/visualizations';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { T4View } from 'shared/components/t4View';
import { orgChartPrefix } from 'stonly/pagePrefixes';
import { VisualizationsFooter } from '../_shared/_components/visualizationFooter';
import { ArrangingLayout } from '../_shared/_extensions/arrangingLayout';
import { useVisualizationDispatch } from '../_shared/_providers/visualizationProvider';
import { freezeLayout, loadHiddenNodes } from '../_shared/functions';
import { OrgChartHeaderComponent } from './_shared/_components/orgChartHeader';
import { OrgChartRenderer } from './_shared/_components/orgChartRenderer';
import { useOrgChart } from './_shared/_providers/orgChartProvider';
import {
	DisplayCompactKey,
	DisplayOverviewMapKey,
} from './models/orgChartTypes';

function getOption(options: VisualizationPreferenceOption[], optionId: string) {
	return options.find((option) => option.optionId === optionId);
}

function isOptionVisible(
	options: VisualizationPreferenceOption[],
	optionId: string,
) {
	return !(getOption(options, optionId)?.hide ?? false);
}

function applyCompactPreference(
	currentDiagram: go.Diagram,
	isCompactView: boolean,
) {
	if (
		isCompactView &&
		((currentDiagram.layout as ArrangingLayout).primaryLayout as go.TreeLayout)
			.alignment !== go.TreeAlignment.Bus
	) {
		(
			(currentDiagram.layout as ArrangingLayout).primaryLayout as go.TreeLayout
		).alignment = go.TreeAlignment.Bus;
		currentDiagram.layout.doLayout(currentDiagram);
	} else if (
		!isCompactView &&
		((currentDiagram.layout as ArrangingLayout).primaryLayout as go.TreeLayout)
			.alignment !== go.TreeAlignment.CenterChildren
	) {
		(
			(currentDiagram.layout as ArrangingLayout).primaryLayout as go.TreeLayout
		).alignment = go.TreeAlignment.CenterChildren;
		currentDiagram.layout.doLayout(currentDiagram);
	}
}

export const OrgChartPage: FC = observer(() => {
	const { isLoading, nodes, links, optionPreferences, updatePreferences } =
		useOrgChart();
	const setZoom = useVisualizationDispatch();

	const [diagram, setDiagram] = useState<go.Diagram>();
	const [showOverviewMap, setShowOverviewMap] = useState<boolean>(false);

	//#region Option Preferences

	const loadPreferences = useCallback(
		(
			currentOptions: VisualizationPreferenceOption[],
			currentDiagram: go.Diagram | undefined,
		) => {
			if (currentDiagram) {
				const isOverviewVisible = isOptionVisible(
					currentOptions,
					DisplayOverviewMapKey,
				);
				const isCompactView = isOptionVisible(
					currentOptions,
					DisplayCompactKey,
				);

				currentDiagram.commit((d) => {
					setShowOverviewMap(isOverviewVisible);
					applyCompactPreference(d, isCompactView);
				});
			}
		},
		[],
	);

	//#endregion

	//#region Node Preferences

	const updateNodeData = useCallback(
		(currentDiagram: go.Diagram) => {
			updatePreferences({
				nodes: [
					...currentDiagram.nodes
						.filter(
							(node) =>
								node.visible &&
								node.position.x !== null &&
								!Number.isNaN(node.position.x) &&
								node.position.y !== null &&
								!Number.isNaN(node.position.y),
						)
						.map((node) => ({
							id: node.data.key,
							key: node.data.key,
							x: node.location.x,
							y: node.location.y,
							visible: true,
						})),
				],
			});
		},
		[updatePreferences],
	);

	//#endregion

	//#endregion

	const selectionMoved = useCallback(
		(event: go.DiagramEvent) => {
			updateNodeData(event.diagram);
		},
		[updateNodeData],
	);

	const onViewportBoundsChangedHandler = useMemo(
		() =>
			debounce((event: go.DiagramEvent) => {
				setZoom(event.diagram.scale);
			}, 200),
		[setZoom],
	);

	useEffect(() => {
		loadPreferences(optionPreferences, diagram);
		loadHiddenNodes(diagram);
	}, [diagram, loadPreferences, nodes, optionPreferences]);

	return (
		<T4View loading={isLoading} disablePadding>
			<Grid
				container
				flexDirection="column"
				sx={{ height: '100%', width: '100%', overflow: 'hidden' }}
			>
				<Grid item xs="auto">
					<OrgChartHeaderComponent diagram={diagram} />
				</Grid>
				<Grid
					item
					xs={true}
					sx={{
						position: 'relative',
						boxSizing: 'unset',

						canvas: {
							outline: 'none',
						},
						'.orgchart-gojs-diagram': {
							width: '100%',
							height: '100%',
						},
					}}
				>
					{showOverviewMap && (
						<ReactOverview
							initOverview={() =>
								new go.Overview(undefined, {
									contentAlignment: go.Spot.Center,
								})
							}
							divClassName="overview"
							style={{
								backgroundColor: '#eee',
								height: '150px',
								width: '250px',
								position: 'absolute',
								top: '2rem',
								left: '2rem',
								border: 'solid',
								borderWidth: '1px',
								borderColor: 'black',
								zIndex: 10,
								outline: 'none',
							}}
							observedDiagram={diagram ?? null}
						/>
					)}
					<OrgChartRenderer
						nodeDataArray={nodes as any[]}
						linkDataArray={links}
						diagramListeners={[
							['InitialLayoutCompleted', (event) => setDiagram(event.diagram)],
							['ViewportBoundsChanged', onViewportBoundsChangedHandler],
							['SelectionMoved', selectionMoved],
							['AnimationFinished', (event) => freezeLayout(event.diagram)],
						]}
					/>
				</Grid>
				<Grid
					item
					xs="auto"
					sx={{
						paddingBottom: '1rem',
						justifyContent: 'center',
						position: 'absolute',
						bottom: '1rem',
						zIndex: 5,
					}}
				>
					<VisualizationsFooter
						stonlyId={orgChartPrefix}
						diagram={diagram}
						onResetView={() => {
							if (diagram) {
								updatePreferences({
									nodes: [],
								});
							}
						}}
					/>
				</Grid>
			</Grid>
		</T4View>
	);
});
