import { ArrowDropDown, Cached } from '@mui/icons-material';
import { Alert, Breadcrumbs, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { T4View } from 'shared/components/t4View';
import { NOT_FOUND_MESSAGING } from 'shared/constants/cannotDisplayMessaging';
import { AddFolderButton } from 'shared/dashboardPage/addFolderButton';
import { AddReportButton } from 'shared/dashboardPage/addReportButton';
import { DashboardOptions } from 'shared/dashboardPage/dashboardOptions';
import { useSigma } from '../_providers/sigmaProvider';
import { SigmaButton } from './sigmaButton';
import { SigmaNavMenu } from './sigmaNavMenu';

export const SigmaEmbed: FC = observer(() => {
	const {
		initializing,
		sigmaUrl,
		workbooks,
		workbook,
		folders,
		folder,
		generateSigmaUrl,
		path,
		loadingNewItem,
	} = useSigma();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [openFolderAdd, setOpenFolderAdd] = useState(false);
	const [openReportAdd, setOpenReportAdd] = useState(false);
	const [showWarning, setShowWarning] = useState(true);

	const [menuId, setMenuId] = useState<string | null>(null);
	const [lastOpened, setLastOpened] = useState<string | null>(null);

	useEffect(() => {
		if (lastOpened !== menuId && menuId) {
			setLastOpened(menuId);
		}
	}, [menuId, lastOpened]);

	const handleClickBreadcrumb = useCallback(
		(event: React.MouseEvent<HTMLElement>, id: string) => {
			if (!anchorEl) {
				setMenuId(id);
				setAnchorEl(event.currentTarget);
			} else {
				setAnchorEl(null);
				setMenuId(null);
			}
		},
		[anchorEl],
	);

	const handleOpenFolderAdd = () => {
		setAnchorEl(null);
		setMenuId(null);
		setOpenFolderAdd(true);
	};

	const handleOpenReportAdd = () => {
		setAnchorEl(null);
		setMenuId(null);
		setOpenReportAdd(true);
	};

	const breadcrumbs = useMemo(() => {
		return (
			<Breadcrumbs aria-label="breadcrumb" separator="/">
				{path?.map((id) => {
					const folder = folders.find((f) => f.id === id);
					const workbook = workbooks.find((w) => w.id === id);
					return (
						<SigmaButton
							key={id}
							onClick={(event) =>
								handleClickBreadcrumb(
									event,
									folder?.parentId ?? workbook?.folderId ?? '',
								)
							}
							style={{ cursor: 'pointer' }}
						>
							{(folder && folder.name) || (workbook && workbook.name)}{' '}
							<ArrowDropDown />
						</SigmaButton>
					);
				})}
				<SigmaButton
					key={workbook?.id}
					onClick={(event) => {
						if (!loadingNewItem) {
							handleClickBreadcrumb(
								event,
								workbook?.folderId ?? folder?.id ?? '',
							);
						}
					}}
					style={{ cursor: 'pointer' }}
				>
					{workbook && workbook.name}
					{loadingNewItem && 'Loading...'}
					{!workbook && !loadingNewItem && 'Select'}
					<ArrowDropDown />
				</SigmaButton>
			</Breadcrumbs>
		);
	}, [
		path,
		folders,
		workbooks,
		workbook,
		loadingNewItem,
		folder,
		handleClickBreadcrumb,
	]);

	return (
		<T4View disablePadding={true} loading={initializing}>
			<AddFolderButton
				open={openFolderAdd}
				setOpen={setOpenFolderAdd}
				parentId={lastOpened ?? ''}
			/>
			<AddReportButton open={openReportAdd} setOpen={setOpenReportAdd} />
			<Grid container spacing={0.5} direction="column" sx={{ height: '100%' }}>
				{showWarning && (
					<Alert
						severity="info"
						onClose={() => {
							setShowWarning(false);
						}}
						sx={{ margin: 2, marginBottom: 0 }}
					>
						<b>Previewing Analytics Studio Beta. </b>This feature is currently
						under development, and unexpected changes may occur.
					</Alert>
				)}

				{folders.length > 0 && (
					<Grid item marginLeft={2}>
						<Grid container marginBottom={0} height={50}>
							<Grid item xs={7}>
								{breadcrumbs}
							</Grid>
							<Grid item container xs={5} justifyContent="flex-end">
								<SigmaButton
									startIcon={<Cached />}
									onClick={() => generateSigmaUrl()}
								/>
								<DashboardOptions />
							</Grid>
						</Grid>
					</Grid>
				)}

				{anchorEl && (
					<SigmaNavMenu
						folderId={folders.find((f) => f.id === menuId)?.id ?? 'root'}
						anchorEl={anchorEl}
						setAnchorEl={setAnchorEl}
						menuId={menuId}
						setMenuId={setMenuId}
						handleOpenFolderAdd={handleOpenFolderAdd}
						handleOpenReportAdd={handleOpenReportAdd}
					/>
				)}

				{!loadingNewItem && (
					<Grid item sx={{ flex: 2, display: 'flex' }}>
						{workbooks.filter((x) => x.folderId === folder?.id).length !== 0 ? (
							<iframe
								title="Report"
								width="100%"
								style={{ flex: 1, border: 'none' }}
								src={sigmaUrl}
							/>
						) : (
							<CannotDisplay
								headingText="Whoops! No reports here yet..."
								bodyText="Let's change that!"
								imageSrc={NOT_FOUND_MESSAGING.IMAGE}
							/>
						)}
					</Grid>
				)}
			</Grid>
		</T4View>
	);
});
