import { Divider, Typography } from '@mui/material';
import { QueryObserverResult } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import {
	FC,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { FormModal } from 'shared/components/formModal';
import { useClients } from 'shared/hooks/useClients';
import { EntityType, getObjectName } from '../../entity4Constants';
import { DeleteResult } from '../../listPage/useListPage';
import { IListPageDto } from '../repositories/frontendRepository';

export type DeleteObjectModalProps = {
	isOpen: boolean;
	objectType: EntityType;
	objectCode?: string;
	objectId?: string;
	closeModal: () => void;
	parentObjectId?: string;
	afterDelete?: () => Promise<QueryObserverResult<IListPageDto, Error>>;
};

export const DeleteObjectModal: FC<DeleteObjectModalProps> = ({
	isOpen,
	objectType,
	objectCode,
	objectId,
	closeModal,
	parentObjectId,
	afterDelete,
}) => {
	const defaultDescription = useMemo(
		() =>
			`Are you sure you want to delete this ${getObjectName(
				objectType,
			).toLowerCase()}? This action cannot be undone.`,
		[objectType],
	);
	const defaultTitle = 'Confirm Delete';

	const { enqueueSnackbar } = useSnackbar();
	const { customerApiClient } = useClients();

	const [loading, setLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<string[]>([]);
	const [transformedErrors, setTransformedErrors] = useState<ReactNode>();
	const [isCancelButtonOnly, setIsCancelButtonOnly] = useState<boolean>(false);
	const [description, setDescription] = useState<string>(defaultDescription);
	const [title, setTitle] = useState<string>(defaultTitle);

	useEffect(() => {
		setDescription(defaultDescription);
	}, [objectType]);

	const pushErrorSnackbar = useCallback(() => {
		enqueueSnackbar(
			`System failed to delete ${getObjectName(objectType)}${
				objectCode ? `, ${objectCode}.` : '.'
			}`,
			{
				variant: 'error',
			},
		);
	}, []);

	const deleteObject = useCallback(
		async (
			type: EntityType,
			id?: string,
			parentObjectId?: string,
		): Promise<DeleteResult> => {
			let response: DeleteResult = {};
			let result;

			if (!type || !id) {
				response.errors = {
					id: ['No object Id provided.'],
				};
				return response;
			}

			switch (type) {
				case EntityType.Entity:
					result = await customerApiClient.entity4.entities.delete({
						id,
					});
					break;
				case EntityType.Partner:
					result = await customerApiClient.entity4.partners.delete({
						id,
					});
					break;
				case EntityType.Counterparty:
					result = await customerApiClient.entity4.counterparties.delete({
						id,
					});
					break;
				case EntityType.Account:
					result = await customerApiClient.entity4.accounts.delete({
						id,
					});
					break;
				case EntityType.Staff:
					result = await customerApiClient.entity4.staff.delete({
						id,
					});
					break;
				case EntityType.Subaccount:
					result = await customerApiClient.entity4.accounts.subaccounts.delete({
						accountId: parentObjectId || '',
						id,
					});
					break;
			}

			if (
				result.data.value.errors &&
				Object.keys(result.data.value.errors).length
			) {
				response = {
					errors: result?.data?.value?.errors as any,
				};
			} else if (!result.data.value.errors) {
				response = {
					errors: {
						unknown: ['An error occurred when trying to delete.'],
					},
				};
			}

			return response;
		},
		[],
	);

	const handleDeleteResponse = useCallback((deleteResponse: DeleteResult) => {
		const objectName = getObjectName(objectType);

		if (!deleteResponse.errors) {
			enqueueSnackbar(
				`The ${objectName}${
					objectCode ? `, ${objectCode}` : ' '
				} has been deleted.`,
				{
					variant: 'success',
				},
			);
			afterDelete?.();
			closeModal();
		} else {
			let objectsWithErrors = Object.keys(deleteResponse.errors).sort();

			if (objectsWithErrors.includes('Cash4')) {
				objectsWithErrors = [
					...objectsWithErrors.filter((x) => x !== 'Cash4'),
					'Cash4 associations',
				];
			}

			setIsCancelButtonOnly(true);
			setDescription(
				`${objectName} cannot be deleted because there is data associated with this record.`,
			);
			setTitle(`Delete ${objectName}`);
			setTransformedErrors(
				<CannotDeleteObjectContent objectsNames={objectsWithErrors} />,
			);
			pushErrorSnackbar();
		}
	}, []);

	return (
		<FormModal
			open={isOpen}
			loading={loading}
			submitDisabled={loading}
			submitButtonColor="error"
			submitButtonLabel="Delete"
			cancelButtonOnly={isCancelButtonOnly}
			title={title}
			description={description}
			errors={errors}
			children={transformedErrors}
			onSubmit={async () => {
				setErrors([]);
				setLoading(true);

				try {
					handleDeleteResponse(
						await deleteObject(objectType, objectId, parentObjectId),
					);
				} catch (error: any) {
					setErrors([
						`An error occurred when trying to delete ${getObjectName(
							objectType,
						)}.`,
					]);
					pushErrorSnackbar();
				} finally {
					setLoading(false);
				}
			}}
			onClose={() => {
				// Reset our state.
				closeModal();
				setErrors([]);
				setLoading(false);
				setTransformedErrors([]);
				setIsCancelButtonOnly(false);
				setDescription(defaultDescription);
				setTitle(defaultTitle);
			}}
		/>
	);
};

type CannotDeleteObjectContentProps = {
	objectsNames: string[];
};
const CannotDeleteObjectContent: FC<CannotDeleteObjectContentProps> = ({
	objectsNames,
}) => {
	return (
		<>
			<Typography sx={{ fontWeight: 'bold', px: 2 }}>
				Associated Data
			</Typography>
			{objectsNames.map((objectName) => {
				return (
					<>
						<Divider sx={{ pt: 1 }} />
						<Typography sx={{ pt: 1, px: 2 }}>{objectName}</Typography>
					</>
				);
			})}
		</>
	);
};
