import { DateRange } from '@mui/x-date-pickers-pro';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import { FC, useState } from 'react';
import T4DateRangePicker from 'shared/components/dateRangePicker/t4DateRangePicker';
import { useReconciliationsContext } from '../_providers/reconciliationsProvider';

export type C4ReconciliationsDateRangePickerProps = {};

export const C4ReconciliationsDateRangePicker: FC<C4ReconciliationsDateRangePickerProps> =
	observer(() => {
		const { dateRange, setDateRange } = useReconciliationsContext();
		const [currentDateRange, setCurrentDateRange] = useState<DateRange<Moment>>(
			dateRange && dateRange[0] && dateRange[1]
				? dateRange
				: [moment().subtract(1, 'day'), moment()],
		);

		return (
			<T4DateRangePicker
				defaultValue={currentDateRange}
				disableFuture
				onAccept={(dateRange) => {
					setCurrentDateRange(dateRange);
					if (
						!!dateRange[0] &&
						dateRange[0]?.isValid() &&
						!!dateRange[1] &&
						dateRange[1]?.isValid()
					) {
						setDateRange(dateRange);
					}
				}}
				sx={{ marginBottom: 0 }}
			/>
		);
	});
