import { Point } from 'gojs';
import { observer } from 'mobx-react-lite';
import {
	Account,
	Counterparty,
	LegalEntity,
	Relationship,
	VisualizationPreferencesReq,
} from 'modules/clients/customer-api/src/api/visualizations';
import moment, { Moment } from 'moment';
import {
	createContext,
	FC,
	ReactNode,
	useContext,
	useMemo,
	useState,
} from 'react';
import {
	useUserVisualizationPreference,
	UseUserVisualizationPreferenceProps,
} from '../../_shared/_hooks/useUserVisualizationPreference';
import { useVisualizationDataQuery } from '../../_shared/_hooks/useVisualizationDataQuery';
import {
	AccountPurposeColors,
	AccountStatusColors,
	CardOptionsDisplayKey,
	DisplayAccountTypeKey,
	DisplayClosedAccountsKey,
	DisplayFlagKey,
	DisplayLegendKey,
	DisplaySubaccountsKey,
	RegionColors,
} from '../models/accountMapTypes';

export type VisualizationAccount = {
	id: string;
	parentId: string | undefined;
	legalEntityId: string | undefined;
	erpCode: string | undefined;
	accountCode: string;
	accountStatus: string | undefined;
	accountType: string | undefined;
	accountCurrencyCode: string | undefined;
	naturalAccountNumber: string | undefined;
	entityName: string | undefined;
	entityRegion: string | undefined;
	entityErpCode: string | undefined;
	counterparty: string | undefined;
	counterpartyBranch: string | undefined;
	purpose: string | undefined;

	key: string;
	group: string | undefined;
	isSubaccount: boolean;
	isForeignAccount: boolean;
	currentColor: string | undefined;
	isVisible: boolean;
	isAccountTypeVisible: boolean;
	isEntityRegionVisible: boolean;
	isAccountPurposeVisible: boolean;
	isAccountStatusVisible: boolean;
	entityViewFundingStructure?: 'left' | 'right';
	visibleFields: string[];
};

export type VisualizationLink = {
	key: string;
	from: string;
	to: string;
	isSubaccount?: boolean;
	type?: string;
	movement?: string;
	isLeftSide?: boolean;
};

type AccountFundingStructure = [
	string,
	{
		funded: string[];
		funding: string[];
	},
];

function fromToFilter(asOfDate: Moment) {
	return (x: Relationship) =>
		(x.from === undefined && x.to === undefined) ||
		(x.from === undefined && asOfDate <= moment(x.to)) ||
		(asOfDate >= moment(x.from) && x.to === undefined) ||
		(asOfDate >= moment(x.from) && asOfDate <= moment(x.to));
}

//#region Constants

const initialPreferences: VisualizationPreferencesReq = {
	options: [
		// Chart Options
		{
			optionId: 'displayStandaloneAccounts',
			hide: false,
		},
		{
			optionId: 'displaySubaccounts',
			hide: true,
		},
		{
			optionId: 'displayClosedAccounts',
			hide: false,
		},
		{
			optionId: 'displayLegend',
			hide: false,
		},
		{
			optionId: 'displayOverviewMap',
			hide: false,
		},

		// Card Options
		{
			optionId: 'cardOptionsColor',
			hide: false,
			value: 'accountPurpose',
		},
		{
			optionId: 'displayAccountType',
			hide: false,
		},
		{
			optionId: 'displayFlag',
			hide: false,
		},

		// Information Options
		{
			optionId: 'accountStatus',
			hide: false,
		},
		{
			optionId: 'openDate',
			hide: false,
		},
		{
			optionId: 'closeDate',
			hide: false,
		},
		{
			optionId: 'accountType',
			hide: false,
		},
		{
			optionId: 'purpose',
			hide: false,
		},
		{
			optionId: 'counterpartyBranch',
			hide: false,
		},
		{
			optionId: 'entityRegion',
			hide: false,
		},
		{
			optionId: 'entityErpCode',
			hide: false,
		},
		{
			optionId: 'generalLedgerAccountNumber',
			hide: false,
		},
	],
};

//#endregion

//#region Context

type AccountMapContextProps = Pick<
	UseUserVisualizationPreferenceProps,
	| 'updatePreferences'
	| 'optionPreferences'
	| 'nodePreferences'
	| 'views'
	| 'createView'
	| 'selectView'
	| 'overwriteView'
	| 'deleteView'
> & {
	isLoading: boolean;
	data: {
		accountView: {
			nodes: any[];
			links: VisualizationLink[];
		};
		entityAccountView: {
			nodes: any[];
			links: any[];
		};
	};
};

const AccountMapContext = createContext<AccountMapContextProps>(
	{} as AccountMapContextProps,
);

//#endregion

//#region Provider

export type AccountMapProviderProps = {
	visualizationKey: string;
	children: ReactNode;
};

export const AccountMapProvider: FC<AccountMapProviderProps> = observer(
	({ visualizationKey, children }) => {
		const {
			isLoading: isLoadingPreferences,
			updatePreferences,
			nodePreferences,
			optionPreferences,
			views,
			createView,
			selectView,
			overwriteView,
			deleteView,
		} = useUserVisualizationPreference(visualizationKey, initialPreferences);
		const { loading: loadingData, data } = useVisualizationDataQuery();

		const [initializing, setInitializing] = useState(true);

		const visualizationData = useMemo<AccountMapContextProps['data']>(() => {
			const asOfDate = moment().startOf('day');

			const getOption = (optionId: string) => {
				return optionPreferences.find((option) => option.optionId === optionId);
			};

			const isOptionVisible = (optionId: string) => {
				return !(getOption(optionId)?.hide ?? false);
			};

			const colorBy = (() => {
				const colorByPreference = getOption(CardOptionsDisplayKey)?.value;

				switch (colorByPreference) {
					case 'accountPurpose':
						return 'accountPurpose';
					case 'accountStatus':
						return 'accountStatus';
					case 'entityRegion':
						return 'entityRegion';
					case 'singleColor':
						return 'singleColor';
					default:
						return 'entityRegion';
				}
			})();

			const opt = {
				isClosedAccountsVisible: isOptionVisible(DisplayClosedAccountsKey),
				isSubaccountsVisible: isOptionVisible(DisplaySubaccountsKey),
				isFlagVisible: isOptionVisible(DisplayFlagKey),
				isAccountTypeVisible: isOptionVisible(DisplayAccountTypeKey),
				isEntityRegionVisible: colorBy === 'entityRegion',
				isAccountPurposeVisible: colorBy === 'accountPurpose',
				isAccountStatusVisible: colorBy === 'accountStatus',
				isLegendVisible: isOptionVisible(DisplayLegendKey),
				isOrphanGroupVisible: isOptionVisible('displayStandaloneAccounts'),
				visibleFields: [
					'accountStatus',
					'openDate',
					'closeDate',
					'accountType',
					'purpose',
					'counterpartyBranch',
					'entityRegion',
					'entityErpCode',
					'generalLedgerAccountNumber',
				].filter(
					(x) => optionPreferences.find((y) => y.optionId === x)?.hide !== true,
				),
			};

			const getNodeColor = (data: {
				purpose: string | undefined;
				accountStatus: string | undefined;
				entityRegion: string | undefined;
			}) => {
				let color: string = 'white';

				switch (colorBy) {
					case 'accountPurpose':
						const accountPurposeColor = AccountPurposeColors.get(
							data['purpose'] ?? '',
						);
						if (accountPurposeColor) {
							color = accountPurposeColor;
						}
						break;

					case 'accountStatus':
						const accountStatusColor = AccountStatusColors.get(
							data['accountStatus'] ?? '',
						);
						if (accountStatusColor) {
							color = accountStatusColor;
						}
						break;

					case 'entityRegion':
						const entityRegionColor = RegionColors.get(
							data['entityRegion'] ?? '',
						);
						if (entityRegionColor) {
							color = entityRegionColor;
						}
						break;

					case 'singleColor':
						const singleColor = optionPreferences?.find(
							(x) => x.optionId === 'userDefinedColor',
						)?.value;
						if (singleColor) {
							color = singleColor;
						}
						break;
				}

				return color;
			};

			const getNodeLocation = (
				object: Account | LegalEntity | { id: string },
			) => {
				const locationData = nodePreferences.find((x) => x.key === object.id);
				if (locationData) {
					return new Point(locationData.x, locationData.y);
				}
			};

			//#region Helpers

			function getLegalEntity(id: string): LegalEntity | undefined {
				return data?.legalEntities?.find(
					(x) =>
						x.accounts
							?.filter(fromToFilter(asOfDate))
							.some((x) => x.objectId === id),
				);
			}

			function getCounterparty(id?: string): Counterparty | undefined {
				return data?.counterparties?.find((x) => x.id === id);
			}

			function isAccountVisible(
				accountStatus: string | undefined,
				isSubaccount: boolean = false,
			): boolean {
				if (
					(isSubaccount && !opt.isSubaccountsVisible) ||
					(accountStatus === 'Closed' && !opt.isClosedAccountsVisible)
				) {
					return false;
				}

				return true;
			}

			const filteredAccounts: Account[] = data?.accounts ?? [];

			const accountFundingStructures =
				data?.accounts
					?.map<AccountFundingStructure>((account) => {
						const funding =
							account.fundedAccounts
								?.filter(fromToFilter(asOfDate))
								.map((x) => x.objectId) ?? [];
						const funded =
							filteredAccounts
								?.filter(
									(x) =>
										x.fundedAccounts
											?.filter(fromToFilter(asOfDate))
											?.some((y) => y.objectId === account.id),
								)
								.map((x) => x.id) ?? [];

						return [
							account.id,
							{
								funded: funded,
								funding: funding,
							},
						];
					})
					?.sort(
						(a, b) =>
							b[1].funded.length +
							b[1].funding.length -
							(a[1].funded.length + a[1].funding.length),
					) ?? [];

			let accountFundingStructureGrouping: string[][] = [];
			accountFundingStructures.forEach((fundingStructure) => {
				let grouping = accountFundingStructureGrouping.find((x) =>
					x.includes(fundingStructure[0]),
				);
				if (grouping === undefined) {
					grouping = [fundingStructure[0]];
					accountFundingStructureGrouping.push(grouping);
				}

				grouping.push(
					...fundingStructure[1].funded.filter((x) => !grouping?.includes(x)),
				);
				grouping.push(
					...fundingStructure[1].funding.filter((x) => !grouping?.includes(x)),
				);
			});

			//#endregion

			if (!loadingData) {
				let accountNodes =
					data?.accounts?.flatMap<VisualizationAccount>((account) => {
						const counterparty = getCounterparty(account.counterpartyId);
						const counterpartyUltimateParent =
							counterparty?.ultimateParent?.substring(0, 4);
						const counterpartyBranch = counterparty?.anglicizedLegalName;
						const legalEntity = getLegalEntity(account.id);

						const legalEntityId = legalEntity?.id;
						const entityName = legalEntity?.anglicizedLegalName;
						const entityRegion = legalEntity?.entityRegion;
						const erpCode = legalEntity?.erpCode;
						const isForeignAccount =
							counterparty?.country && legalEntity?.country
								? counterparty.country !== legalEntity.country
								: false;

						return [
							{
								key: account.id,
								isSubaccount: false,
								isForeignAccount: isForeignAccount,
								group: undefined,
								erpCode: erpCode,
								purpose: account.purpose,

								id: account.id,
								parentId: undefined,
								legalEntityId: legalEntityId,
								accountCode: account.code,
								accountStatus: account.status,
								accountType: account.type,
								accountCurrencyCode: account.currencyCode,
								naturalAccountNumber: account.number,

								entityName: entityName,
								entityRegion: entityRegion,
								entityErpCode: erpCode,

								counterparty: counterpartyUltimateParent,
								counterpartyBranch: counterpartyBranch,

								loc: getNodeLocation(account),
								isVisible: isAccountVisible(account?.status),
								currentColor: getNodeColor({
									accountStatus: account.status,
									purpose: account.purpose,
									entityRegion: entityRegion,
								}),
								isAccountTypeVisible: opt.isAccountTypeVisible,
								isEntityRegionVisible: opt.isEntityRegionVisible,
								isAccountPurposeVisible: opt.isAccountPurposeVisible,
								isAccountStatusVisible: opt.isAccountStatusVisible,
								visibleFields: opt.visibleFields,
							},
							...(account.subaccounts?.map<VisualizationAccount>(
								(subaccount) => ({
									key: subaccount.id,
									isSubaccount: true,
									isForeignAccount: isForeignAccount,
									group: undefined,
									erpCode: erpCode,
									purpose: subaccount.purpose,

									id: subaccount.id,
									parentId: account.id,
									legalEntityId: legalEntityId,
									accountCode: subaccount.code,
									accountStatus: subaccount.status,
									accountType: subaccount.type,
									accountCurrencyCode: subaccount.currencyCode,
									naturalAccountNumber: subaccount.number,

									entityName: entityName,
									entityRegion: entityRegion,
									entityErpCode: erpCode,

									counterparty: counterpartyUltimateParent,
									counterpartyBranch: counterpartyBranch,

									loc: getNodeLocation(subaccount),
									isVisible: isAccountVisible(account?.status, true),
									currentColor: getNodeColor({
										accountStatus: subaccount.status,
										purpose: subaccount.purpose,
										entityRegion: entityRegion,
									}),
									isAccountTypeVisible: opt.isAccountTypeVisible,
									isEntityRegionVisible: opt.isEntityRegionVisible,
									isAccountPurposeVisible: opt.isAccountPurposeVisible,
									isAccountStatusVisible: opt.isAccountStatusVisible,
									visibleFields: opt.visibleFields,
								}),
							) ?? []),
						];
					}) ?? [];

				const visibleAccountNodes = accountNodes.filter((x) => x.isVisible);

				const legalEntityNodes =
					data?.legalEntities
						?.map((legalEntity) => {
							const accountsCount = visibleAccountNodes.filter(
								(x) => x.legalEntityId === legalEntity.id,
							).length;

							return {
								key: legalEntity.id,
								category: 'entityOrg',
								entityId: legalEntity.id,
								entityCode: legalEntity.entityCode,
								entityCountry: legalEntity.country,
								entityErpCode: legalEntity.erpCode,
								entityName: legalEntity.anglicizedLegalName,
								isFlagVisible: opt.isFlagVisible,
								countChildren: accountsCount,
								loc: getNodeLocation(legalEntity),
							};
						})
						?.filter((x) => x.countChildren > 0) ?? [];

				const accountLinks =
					data?.accounts
						?.filter((account) => isAccountVisible(account.status))
						?.flatMap<VisualizationLink>(
							({ id, fundedAccounts, subaccounts }) => [
								...(fundedAccounts
									?.filter(fromToFilter(asOfDate))
									?.filter((x) =>
										visibleAccountNodes.some((y) => y.id === x.objectId),
									)
									.map(({ objectId, fundingDirection, cashFlowMovement }) => ({
										key: `${id}-${objectId}`,
										from: id,
										to: objectId,
										type: fundingDirection,
										movement: cashFlowMovement,
									})) ?? []),
								...(subaccounts
									?.filter((subaccount) =>
										isAccountVisible(subaccount.status, true),
									)
									?.map(({ id: subaccountId }) => ({
										key: `${id}-${subaccountId}`,
										from: id,
										to: subaccountId,
										type: 'Subaccount',
										isSubaccount: true,
									})) ?? []),
							],
						) ?? [];

				const entityLinks = legalEntityNodes.flatMap<VisualizationLink>(
					(legalEntityNode) => {
						const allLegalEntityAccountNodes = accountNodes.filter(
							(accountNode) =>
								accountNode.isVisible &&
								accountNode.legalEntityId === legalEntityNode.entityId,
						);
						const legalEntityAccountNodes = allLegalEntityAccountNodes.filter(
							(accountNodce) => !accountNodce.isSubaccount,
						);

						const nodeLinkGroups = legalEntityAccountNodes.map(
							(accountNode) => ({
								node: accountNode,
								links: accountLinks.filter(
									(link) =>
										(link.from === accountNode.id &&
											legalEntityAccountNodes.some(
												(node) => node.id === link.to,
											)) ||
										(link.to === accountNode.id &&
											legalEntityAccountNodes.some(
												(node) => node.id === link.from,
											)),
								),
							}),
						);

						const maxLinks =
							nodeLinkGroups.reduce(
								(acc, group) => Math.max(acc, group.links.length),
								0,
							) + 1;
						return nodeLinkGroups
							.reduce<
								{
									node: VisualizationAccount;
									links: VisualizationLink[];
								}[][]
							>((acc, linkGroup) => {
								// find or create grouping
								let foundGrouping = acc.find((x) =>
									x.find((x) =>
										x.links.some(
											(x) =>
												x.to === linkGroup.node.id ||
												x.from === linkGroup.node.id,
										),
									),
								);
								if (foundGrouping === undefined) {
									foundGrouping = [];
									acc.push(foundGrouping);
								}
								foundGrouping.push(linkGroup);

								// find all extended groupings
								let currentCount = foundGrouping.length;
								do {
									currentCount = foundGrouping.length;

									acc.slice().forEach((x, index) => {
										if (x === foundGrouping) {
											return;
										}

										const extendedGrouping = x.find((x) =>
											x.links.some(
												(x) =>
													x.to === linkGroup.node.id ||
													x.from === linkGroup.node.id,
											),
										);
										if (extendedGrouping) {
											acc.splice(index, 1);
											foundGrouping?.push(extendedGrouping);
										}
									});
								} while (currentCount !== foundGrouping.length);

								return acc;
							}, [])
							.map((group) =>
								group.sort((a, b) => {
									let aValue = -a.links.length;
									let bValue = -b.links.length;

									if (a.node.purpose === 'Concentration') {
										aValue -= maxLinks;
									}
									if (b.node.purpose === 'Concentration') {
										bValue -= maxLinks;
									}

									return aValue - bValue;
								}),
							)
							.flatMap<VisualizationLink>((grouping) => {
								const processAccounts = grouping.map(
									(x) =>
										data?.accounts?.find(
											(account) => account.id === x.node.id,
										)!,
								);

								const processedAccounts: string[] = [];
								const processedLinks: string[] = [];
								function getLinks(account: Account): VisualizationLink[] {
									if (processedAccounts.includes(account.id)) {
										return [];
									} else {
										processedAccounts.push(account.id);
									}

									// account -> funding accounts
									const fundingLinks = accountLinks
										.filter(
											(link) =>
												!processedLinks.includes(link.key) &&
												link.from === account.id &&
												allLegalEntityAccountNodes.some(
													(node) => node.id === link.to,
												),
										)
										.map((x) => ({ ...x }));

									// account -> funded by accounts (inverse)
									const fundedByLinks = accountLinks
										.filter(
											(link) =>
												!processedLinks.includes(link.key) &&
												link.to === account.id &&
												allLegalEntityAccountNodes.some(
													(node) => node.id === link.from,
												),
										)
										.map((link) => {
											if (link.isSubaccount) {
												return link;
											}

											return {
												...link,
												from: link.to,
												to: link.from,
												isLeftSide: true,
											};
										});

									processedLinks.push(
										...fundingLinks.map((x) => x.key),
										...fundedByLinks.map((x) => x.key),
									);
									const nextAccounts = processAccounts.filter(
										(x) =>
											fundingLinks.some((link) => link.to === x.id) ||
											fundedByLinks.some((link) => link.to === x.id),
									);

									return [
										...fundingLinks,
										...fundedByLinks,
										...nextAccounts.flatMap(getLinks),
									];
								}

								const winningAccount = processAccounts.find(
									(account) => account.id === grouping[0].node.id,
								)!;

								return [
									{
										key: `${legalEntityNode.entityId}-${winningAccount.id}`,
										from: legalEntityNode.entityId,
										to: winningAccount.id,
										isSubaccount: false,
										type: 'Entity',
									},
									...getLinks(winningAccount),
								];
							});
					},
				);
				accountNodes = accountNodes.map((accountNode) => {
					if (
						entityLinks.some(
							(link) =>
								link.from === accountNode.id && link.isLeftSide === true,
						)
					) {
						accountNode.entityViewFundingStructure = 'left';
					} else {
						accountNode.entityViewFundingStructure = 'right';
					}

					return {
						...accountNode,
						entityViewFundingStructure: entityLinks.some(
							(link) => link.to === accountNode.id && link.isLeftSide === true,
						)
							? 'left'
							: 'right',
					};
				});

				//#region Account View Legend Nodes

				const legalEntities =
					data?.legalEntities
						?.filter((x) => (x.accounts?.length ?? 0) > 0)
						.sort() ?? [];
				const entityRegions =
					legalEntities
						?.filter((x) => x.entityRegion !== undefined)
						.map((x) => x.entityRegion) ?? [];
				const distinctEntityRegions = [...new Set(entityRegions)].filter(
					Boolean,
				);
				const accountTypes = filteredAccounts
					?.filter((x) => x.type !== undefined)
					.map((x) => x.type)
					.sort();
				const distinctAccountTypes = [...new Set(accountTypes)].filter(Boolean);
				const accountStatuses = filteredAccounts
					?.filter((x) => x.status !== undefined)
					.map((x) => x.status);
				const distinctAccountStatuses = [...new Set(accountStatuses)].filter(
					Boolean,
				);
				const accountPurposes = filteredAccounts
					?.filter((x) => x.purpose !== undefined)
					.map((x) => x.purpose);
				const distinctAccountPurposes = [...new Set(accountPurposes)].filter(
					Boolean,
				);

				const legendNodes = [
					{
						key: 'Legend',
						isGroup: true,
						category: 'Legend',
						loc: getNodeLocation({ id: 'Legend' }),
						accountTypes: distinctAccountTypes,
						entityRegions: distinctEntityRegions,
						hasForeignAccount: accountNodes.some((x) => x.isForeignAccount),
						hasManualLink:
							filteredAccounts.some(
								(x) =>
									x.fundedAccounts?.some(
										(x) => x.cashFlowMovement === 'Manual',
									),
							) ?? false,
						hasAutomaticLink:
							filteredAccounts.some(
								(x) =>
									x.fundedAccounts?.some(
										(x) => x.cashFlowMovement === 'Automatic',
									),
							) ?? false,
						hasOneWayLink:
							filteredAccounts.some(
								(x) =>
									x.fundedAccounts?.some(
										(x) => x.fundingDirection === 'One-Way',
									),
							) ?? false,
						hasTwoWayLink:
							filteredAccounts.some(
								(x) =>
									x.fundedAccounts?.some(
										(x) => x.fundingDirection === 'Two-Way',
									),
							) ?? false,
						hasSubaccount: accountNodes.some((x) => x.isSubaccount),
						hideConnectors: accountLinks.length === 0, //this is done in the account view.
						accountStatuses: distinctAccountStatuses,
						accountPurposes: distinctAccountPurposes,
						isSubaccountVisible: opt.isSubaccountsVisible,
						isAccountTypeVisible: opt.isAccountTypeVisible,
						isEntityRegionVisible: opt.isEntityRegionVisible,
						isAccountStatusVisible: opt.isAccountStatusVisible,
						isAccountPurposeVisible: opt.isAccountPurposeVisible,
						isLegendVisible: opt.isLegendVisible,
					},
				];

				//#endergion

				//#region Entity Account Links

				//#endregion

				const accountViewNodes: any[] = [];
				accountViewNodes.push(
					...accountNodes.map((x) => ({
						...x,
						group: accountLinks.some((y) => y.from === x.key || y.to === x.key)
							? undefined
							: 'Standalone',
					})),
				);
				accountViewNodes.push({
					key: 'Standalone',
					text: 'Standalone Accounts',
					isGroup: true,
					isOrphanGroupVisible: opt.isOrphanGroupVisible,
				});
				accountViewNodes.push(...legendNodes);

				const entityAccountViewNodes: any[] = [];
				entityAccountViewNodes.push(...legalEntityNodes);
				entityAccountViewNodes.push(
					...accountNodes.map((x) => ({
						...x,
						group: entityLinks.some(
							(link) => link.to === x.key || link.from === x.key,
						)
							? undefined
							: 'Standalone',
					})),
				);
				entityAccountViewNodes.push({
					key: 'Standalone',
					text: 'Standalone Accounts',
					isGroup: true,
					isOrphanGroupVisible: opt.isOrphanGroupVisible,
					loc: getNodeLocation({ id: 'Standalone' }),
				});
				entityAccountViewNodes.push(...legendNodes);

				setInitializing(false);
				return {
					accountView: {
						nodes: accountViewNodes,
						links: accountLinks,
					},
					entityAccountView: {
						nodes: entityAccountViewNodes,
						links: entityLinks,
					},
				};
			}

			return {
				accountView: {
					nodes: [],
					links: [],
				},
				entityAccountView: {
					nodes: [],
					links: [],
				},
			};
		}, [
			data?.accounts,
			data?.legalEntities,
			data?.counterparties,
			loadingData,
			optionPreferences,
			nodePreferences,
		]);

		return (
			<AccountMapContext.Provider
				value={{
					isLoading: isLoadingPreferences || initializing,
					data: visualizationData,
					updatePreferences: updatePreferences,
					optionPreferences: optionPreferences,
					nodePreferences: nodePreferences,
					views: views,
					createView: createView,
					selectView: selectView,
					overwriteView: overwriteView,
					deleteView: deleteView,
				}}
			>
				{children}
			</AccountMapContext.Provider>
		);
	},
);

//#endregion

//#region Hook

export type UseAccountMap = AccountMapContextProps;

export function useAccountMap(): UseAccountMap {
	return useContext(AccountMapContext);
}

//#endregion
