import * as go from 'gojs';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { T4Diagram, T4DiagramProps } from '../../_shared/_components/t4Diagram';
import { ArrangingLayout } from '../../_shared/_extensions/arrangingLayout';
import {
	getDiagram,
	setupDiagramEvents,
} from '../../_shared/_templates/diagram';
import { link } from '../../_shared/_templates/link';
import { accountMapLegend } from '../_templates/accountMapLegend';
import { accountNode } from '../_templates/accountNode';
import { standaloneAccounts } from '../_templates/standaloneAccounts';

export const AccountMapAccountViewRenderer: FC<
	Pick<T4DiagramProps, 'diagramListeners' | 'nodeDataArray' | 'linkDataArray'>
> = observer(({ diagramListeners, ...rest }) => {
	const diagram = useMemo(() => {
		const diagram = getDiagram(
			{
				initialAutoScale: go.AutoScale.Uniform,
				initialContentAlignment: go.Spot.Center,
				layout: new ArrangingLayout({
					spacing: new go.Size(400, 50),
					side: go.Spot.RightSide,
					primaryLayout: new go.TreeLayout({
						angle: 90,
						arrangementSpacing: new go.Size(50, 50),
						arrangement: go.TreeArrangement.Horizontal,
					}),
					arrangingLayout: new go.TreeLayout({
						angle: 90,
						arrangement: go.TreeArrangement.Horizontal,
						arrangementSpacing: new go.Size(50, 50),
					}),
					sideLayout: new go.TreeLayout({
						arrangement: go.TreeArrangement.Horizontal,
						arrangementSpacing: new go.Size(50, 50),
					}),
				}),
				linkTemplate: link(true),
				nodeTemplate: accountNode('TreeExpanderButton'),
				groupTemplateMap: new go.Map([
					{ key: '', value: standaloneAccounts() },
					{ key: 'Legend', value: accountMapLegend() },
				]),
			},
			diagramListeners,
		);
		setupDiagramEvents(diagram);

		diagram.undoManager.isEnabled = true;
		diagram.toolManager.mouseWheelBehavior = go.WheelMode.Zoom;

		return diagram;
	}, [diagramListeners]);

	return (
		<T4Diagram
			divClassName="accountview-gojs-diagram"
			style={{
				background: 'white',
			}}
			initDiagram={() => diagram}
			nodeDataArray={rest.nodeDataArray}
			linkDataArray={rest.linkDataArray}
		/>
	);
});
