import { Box, Divider, Grid, Typography } from '@mui/material';
import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';
import {
	PaymentTemplate,
	PaymentTemplateStatusTypes,
} from 'modules/clients/apiGateway/payments4/paymentTemplates';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { dateTimeReadFormat, formatDate } from 'shared/utilities/dateUtilities';

export const TemplateHistoryTab: FC<{
	template: PaymentTemplate | undefined;
}> = ({ template }) => {
	const history = useMemo(
		() =>
			template?.statusHistory
				.filter(
					(x) =>
						x.paymentTemplateStatusType !==
						PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Archived],
				)
				.sort((a, b) => {
					return moment(b.createdOn).diff(moment(a.createdOn));
				}) ?? [],
		[template],
	);

	return (
		<div role="tabpanel">
			<Grid container sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
				{history.map((value, index) => {
					return (
						<Grid
							key={index}
							item
							xs={12}
							sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
						>
							<Typography variant="body1" fontWeight={500}>
								{}
							</Typography>
							<Typography variant="body1">
								{formatDate(
									moment(value.createdOn),
									dateTimeReadFormat,
								)?.toUpperCase()}
							</Typography>
							<Typography variant="body1" fontWeight={500}>
								{value.paymentTemplateStatusType ===
								PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Draft]
									? 'Updated - Pending Approval'
									: value.paymentTemplateStatusType}
							</Typography>
							<Typography variant="body1">
								{value.createdBy?.email ?? value.createdBy?.userId}
							</Typography>
							{value?.paymentTemplateStatusType ===
								PaymentTemplateStatusTypes[
									PaymentTemplateStatusTypes.Rejected
								] && (
								<Grid container item xs={12}>
									<T4Alert
										severity="error"
										sx={{
											'&.MuiPaper-root': {
												height: '100%',
												width: '100%!important',
											},
										}}
									>
										<Box
											sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
										>
											<Typography variant="body2" fontWeight={500}>
												Rejection Reason
											</Typography>
											<Typography variant="caption">{value.reason}</Typography>
										</Box>
									</T4Alert>
								</Grid>
							)}

							{index !== history.length - 1 && (
								<Divider sx={{ paddingTop: '0.5rem' }} />
							)}
						</Grid>
					);
				})}
			</Grid>
		</div>
	);
};
