/* eslint-disable mobx/missing-observer */
import { Divider, MenuItem, Tooltip } from '@mui/material';
import { FC, useMemo } from 'react';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { stonlyData } from 'stonly/functions';

export type Action = {
	action: ActionsEnum;
	isDisabled?: boolean;
	isDisabledTooltipText?: string;
	callback: () => void;
	stonlyId?: string;
};

export enum ActionsEnum {
	view,
	edit,
	delete,
	reconcile,
}

export type ActionsProps = {
	stonlyId: string;
	actions: Action[];
	id?: string;
};

export const Actions: FC<ActionsProps> = ({ stonlyId, actions, id }) => {
	const viewAction = useMemo(
		() =>
			actions?.filter((action) => {
				return action.action === ActionsEnum.view;
			})[0] ?? undefined,
		[actions],
	);
	const editAction = useMemo(
		() =>
			actions?.filter((action) => {
				return action.action === ActionsEnum.edit;
			})[0] ?? undefined,
		[actions],
	);
	const deleteAction = useMemo(
		() =>
			actions?.filter((action) => {
				return action.action === ActionsEnum.delete;
			})[0] ?? undefined,
		[actions],
	);

	const reconcileAction = useMemo(
		() =>
			actions?.filter((action) => {
				return action.action === ActionsEnum.reconcile;
			})[0] ?? undefined,
		[actions],
	);

	const shouldDisplayMenu = viewAction || editAction || deleteAction;

	if (shouldDisplayMenu) {
		return (
			<OverflowMenu
				iconButtonProps={{
					...stonlyData({
						id: stonlyId,
					}),
				}}
				id={id ?? ''}
			>
				{viewAction && (
					<MenuItem
						disabled={viewAction.isDisabled === true}
						onClick={() => {
							viewAction.callback?.();
						}}
						{...stonlyData({
							id: viewAction.stonlyId ?? '',
						})}
					>
						View
					</MenuItem>
				)}
				{editAction && (
					<MenuItem
						disabled={editAction.isDisabled === true}
						onClick={() => {
							editAction.callback?.();
						}}
						{...stonlyData({
							id: editAction.stonlyId ?? '',
						})}
					>
						Edit
					</MenuItem>
				)}

				{reconcileAction && (
					<Tooltip
						title={
							reconcileAction.isDisabled === true
								? reconcileAction.isDisabledTooltipText ?? ''
								: ''
						}
					>
						<span>
							<MenuItem
								disabled={reconcileAction.isDisabled === true}
								onClick={() => {
									reconcileAction.callback?.();
								}}
								{...stonlyData({
									id: reconcileAction.stonlyId ?? '',
								})}
							>
								Create Reconciliation
							</MenuItem>
						</span>
					</Tooltip>
				)}

				{deleteAction && <Divider />}
				{deleteAction && (
					<Tooltip
						title={
							deleteAction.isDisabled === true
								? deleteAction.isDisabledTooltipText ?? ''
								: ''
						}
					>
						<span>
							<DeleteMenuItem
								disabled={deleteAction.isDisabled === true}
								onClick={() => {
									deleteAction.callback?.();
								}}
								{...stonlyData({
									id: deleteAction.stonlyId ?? '',
								})}
							/>
						</span>
					</Tooltip>
				)}
			</OverflowMenu>
		);
	} else {
		return <></>;
	}
};
