import {
	Box,
	Button,
	Card,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
} from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { observer } from 'mobx-react-lite';
import { SigmaElement } from 'modules/clients/customer-api/src/sigmaEmbed';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSigmaDashboard } from '../_providers/dashboardProvider';
import { SigmaEmbed } from './sigmaEmbed';
import { Cancel, Remove } from '@mui/icons-material';

export type WidgetPickerProps = {
	open: boolean;
	onClose: () => void;
	onSubmit: (visuals: SigmaElement[]) => void;
	loading?: boolean;
};

export const WidgetPicker: FC<WidgetPickerProps> = observer(
	({ open, onClose, onSubmit, loading }) => {
		const { visuals, dashboard, hardcodedComponents } = useSigmaDashboard();
		const [selectedVisuals, setSelectedVisuals] = useState<SigmaElement[]>([]);
		const [statusFilter, setStatusFilter] = useState<string>('Featured');
		const [typeFilter, setTypeFilter] = useState<string>('');
		const [columnFilter, setColumnFilter] = useState<string>('');
		const dashboardVisuals = (dashboard?.widgets ?? []).map(
			(x) => `${x.workbookId}-${x.elementId}`,
		);

		const filteredVisuals = useMemo(() => {
			const dashboardVisuals = (dashboard?.widgets ?? []).map(
				(x) => `${x.workbookId}-${x.elementId}`,
			);

			return (visuals ?? [])
				.filter(
					(x) => !dashboardVisuals.includes(`${x.workbookId}-${x.elementId}`),
				)
				.filter((x) => x.status === statusFilter)
				.filter((x) =>
					typeFilter === '' ? true : x.vizualizationType === typeFilter,
				)
				.filter((x) =>
					columnFilter === '' ? true : x.columns.includes(columnFilter),
				);
		}, [dashboard?.widgets, visuals, statusFilter, typeFilter, columnFilter]);

		const reports = useMemo(() => {
			return Array.from(new Set(filteredVisuals.map((v) => v.workbookName)));
		}, [filteredVisuals]);

		const types = useMemo(() => {
			const dashboardVisuals = (dashboard?.widgets ?? []).map(
				(x) => `${x.workbookId}-${x.elementId}`,
			);
			return Array.from(
				new Set(
					filteredVisuals
						.filter(
							(x) =>
								!dashboardVisuals.includes(`${x.workbookId}-${x.elementId}`),
						)
						.filter((v) => v.vizualizationType !== '')
						.map((v) => v.vizualizationType),
				),
			);
		}, [filteredVisuals]);

		const allTypes = useMemo(() => {
			const dashboardVisuals = (dashboard?.widgets ?? []).map(
				(x) => `${x.workbookId}-${x.elementId}`,
			);
			return Array.from(
				new Set(
					visuals
						.filter(
							(x) =>
								!dashboardVisuals.includes(`${x.workbookId}-${x.elementId}`),
						)
						.filter((v) => v.vizualizationType !== '')
						.map((v) => v.vizualizationType),
				),
			);
		}, [visuals]);

		const allColumns = useMemo(() => {
			const dashboardVisuals = (dashboard?.widgets ?? []).map(
				(x) => `${x.workbookId}-${x.elementId}`,
			);
			const flattenedColumns = visuals
				.filter(
					(x) => !dashboardVisuals.includes(`${x.workbookId}-${x.elementId}`),
				)
				.flatMap((v) => v.columns);
			const uniqueColumns = Array.from(new Set(flattenedColumns));

			return uniqueColumns.sort();
		}, [visuals]);

		const statusOptions = useMemo(() => {
			return Array.from(new Set(visuals.map((v) => v.status)));
		}, [visuals]);

		const onSelectHandler = useCallback(
			(sigmaElement: SigmaElement) => {
				const visual = selectedVisuals.find(
					(v) => v.elementId === sigmaElement.elementId,
				);
				if (!visual) {
					setSelectedVisuals([...selectedVisuals, sigmaElement]);
				} else {
					setSelectedVisuals(
						selectedVisuals.filter(
							(v) => v.elementId !== sigmaElement.elementId,
						),
					);
				}
			},
			[selectedVisuals],
		);

		useEffect(() => {
			if (!open) {
				setSelectedVisuals([]);
			}
		}, [open]);

		return (
			<Dialog
				open={open}
				maxWidth="xl"
				sx={{
					'& .MuiDialog-paper': {
						width: 'calc(80% + 36px)',
						height: '80%',
					},
				}}
			>
				<Grid
					container
					sx={{ height: '100%', flexDirection: 'column', flexWrap: 'nowrap' }}
				>
					<Grid item xs="auto">
						<DialogTitle>
							<Typography variant="h3" component="div">
								Add Widgets
							</Typography>
						</DialogTitle>
					</Grid>
					<Grid container>
						<Grid item xs={4} sx={{ paddingX: 2, paddingBottom: 2 }}>
							<FormControl fullWidth>
								<InputLabel>Status</InputLabel>
								<Select
									value={statusFilter === '' ? 'Other' : statusFilter}
									label="Status"
									onChange={(v) => setStatusFilter(v.target.value)}
								>
									{statusOptions.map((stat) => (
										<MenuItem key={stat} value={stat}>
											{stat === '' ? 'Other' : stat}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={4} sx={{ paddingX: 2, paddingBottom: 2 }}>
							<FormControl fullWidth>
								<InputLabel>Type</InputLabel>
								<Select
									value={typeFilter}
									label="Type"
									onChange={(v) => setTypeFilter(v.target.value)}
								>
									<MenuItem value="">All Types</MenuItem>
									{allTypes.map((type) => (
										<MenuItem key={type} value={type}>
											{type}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={4} sx={{ paddingX: 2, paddingBottom: 2 }}>
							<FormControl fullWidth>
								<InputLabel>Fields</InputLabel>
								<Select
									value={columnFilter}
									label="Fields"
									onChange={(v) => setColumnFilter(v.target.value)}
								>
									<MenuItem value="">All Fields</MenuItem>
									{allColumns.map((field) => (
										<MenuItem key={field} value={field}>
											{field}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>

					<Grid item xs={true} sx={{ overflowY: 'auto' }}>
						<DialogContent sx={{ height: '100%' }}>
							<Grid container sx={{ height: '100%' }}>
								{loading ? (
									<Grid
										item
										xs="auto"
										sx={{
											width: '100%',
										}}
									>
										<CircularProgress />
									</Grid>
								) : (
									<Grid item xs={4} sx={{ paddingRight: 2 }}>
										<Box sx={{ minHeight: 320, height: '100%' }}>
											<SimpleTreeView>
												{reports.map((report, reportIndex) => {
													const reportTypes =
														typeFilter === ''
															? types.filter((type) =>
																	filteredVisuals.some(
																		(v) =>
																			v.workbookName === report &&
																			v.vizualizationType === type,
																	),
															  )
															: [];

													if (reportTypes.length === 0 && typeFilter === '')
														return null;

													return (
														<TreeItem
															key={`report-${reportIndex}`}
															itemId={`report-${reportIndex}`}
															label={report}
														>
															{typeFilter === ''
																? reportTypes.map((type, typeIndex) => {
																		const visuals = filteredVisuals.filter(
																			(visual) =>
																				visual.workbookName === report &&
																				visual.vizualizationType === type,
																		);

																		if (visuals.length === 0) return null;

																		return (
																			<TreeItem
																				key={`type-${typeIndex}-${report}`}
																				itemId={`type-${typeIndex}-${report}`}
																				label={type}
																			>
																				{visuals.map((visual) => (
																					<TreeItem
																						key={`visual-${visual.elementId} ${visual.name}`}
																						itemId={visual.elementId}
																						label={
																							<Box
																								sx={{
																									display: 'flex',
																									alignItems: 'center',
																								}}
																							>
																								<Checkbox
																									onClick={(event) => {
																										event.stopPropagation();
																										onSelectHandler(visual);
																									}}
																									checked={selectedVisuals.some(
																										(x) =>
																											x.elementId ===
																											visual.elementId,
																									)}
																									sx={{
																										padding: '0 8px 0 0',
																									}}
																								/>
																								<Typography>
																									{visual.name}
																								</Typography>
																							</Box>
																						}
																					/>
																				))}
																			</TreeItem>
																		);
																  })
																: filteredVisuals
																		.filter((v) => v.workbookName === report)
																		.map((visual) => (
																			<TreeItem
																				key={`visual-${visual.elementId} ${visual.name}`}
																				itemId={visual.elementId}
																				label={
																					<Box
																						sx={{
																							display: 'flex',
																							alignItems: 'center',
																						}}
																					>
																						<Checkbox
																							onClick={(event) => {
																								event.stopPropagation();
																								onSelectHandler(visual);
																							}}
																							checked={selectedVisuals.some(
																								(x) =>
																									x.elementId ===
																									visual.elementId,
																							)}
																							sx={{
																								padding: '0 8px 0 0',
																							}}
																						/>
																						<Typography>
																							{visual.name}
																						</Typography>
																					</Box>
																				}
																			/>
																		))}
														</TreeItem>
													);
												})}
											</SimpleTreeView>
										</Box>
									</Grid>
								)}
								{selectedVisuals.length !== 0 && (
									<Grid item xs={8}>
										<Typography variant="h4" component="div">
											Preview of Selected Visuals ({selectedVisuals.length})
										</Typography>
										<Typography variant="body1" component="div">
											Click to remove a visual from selection
										</Typography>
										<Grid container spacing={2}>
											{selectedVisuals.map((visual, index) => (
												<Grid
													key={`sigma-visual-${index}-element-${visual.elementId}`}
													item
													xs={5}
													margin={1}
													sx={{
														position: 'relative',
														height: '12rem',
													}}
												>
													<Cancel
														onClick={() => onSelectHandler(visual)}
														sx={(theme) => ({
															position: 'absolute',
															top: '1.5rem',
															right: '-.5rem',
															color: theme.palette.error.main,
															cursor: 'pointer',
															zIndex: 1,
														})}
													/>
													{visual.workbookId !== 'hardcoded' ? (
														<SigmaEmbed sigmaVisual={visual} />
													) : (
														<Card
															key={visual.elementId}
															elevation={2}
															square
															sx={{ height: '100%', width: '100%' }}
														>
															{
																hardcodedComponents[
																	visual.elementId as keyof typeof hardcodedComponents
																]
															}
														</Card>
													)}
												</Grid>
											))}
										</Grid>
									</Grid>
								)}
							</Grid>
						</DialogContent>
					</Grid>

					<Grid item xs="auto">
						<DialogActions>
							<Grid
								container
								item
								xs="auto"
								sx={{ gap: 2, flexWrap: 'nowrap' }}
							>
								<Grid item xs="auto">
									<Button color="secondary" onClick={() => onClose()}>
										Cancel
									</Button>
								</Grid>
								<Grid item xs="auto">
									<Button
										variant="contained"
										onClick={() => onSubmit(selectedVisuals)}
									>
										Add
									</Button>
								</Grid>
							</Grid>
						</DialogActions>
					</Grid>
				</Grid>
			</Dialog>
		);
	},
);
