import {
	Cancel,
	CheckCircle,
	GradingOutlined,
	Share,
} from '@mui/icons-material';
import { Divider, Drawer, Grid, Tooltip, Typography } from '@mui/material';
import { QueryObserverResult } from '@tanstack/react-query';
import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import {
	PaymentTemplate,
	PaymentTemplateStatusTypes,
} from 'modules/clients/apiGateway/payments4/paymentTemplates';
import { T4ProblemDetails } from 'modules/clients/types';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CopyToClipboardIconButton } from 'shared/components/copyToClipboardIconButton';
import { DrawerWidth, T4DrawerBase } from 'shared/components/drawer/drawerBase';
import { DrawerCancelButton } from 'shared/components/drawer/drawerButtons';
import { useClients } from 'shared/hooks/useClients';
import { useUser } from 'shared/hooks/useUser';
import { flattenProblemDetails } from 'utilities/errors/errorUtils';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { useGetPaymentTemplateDraft } from '../hooks/usePaymentTemplates';
import { PaymentPartyInformation } from '../paymentPartyInformationBox';

interface ReviewTemplateDrawerProps {
	onClose: () => void;
	templateId: string | null;
	refetch: () => Promise<QueryObserverResult<PaymentTemplate[], Error>>;
}

export const ReviewTemplateDrawer: FC<ReviewTemplateDrawerProps> = ({
	onClose,
	templateId,
	refetch,
}) => {
	const { user, payments4 } = useUser();
	const { applicationApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<string[]>([]);
	const errorsRef = useRef<HTMLDivElement>(null);

	const [isRejectionDialogueOpen, setIsRejectionDialogOpen] =
		useState<boolean>(false);
	const [rejectionReason, setRejectionReason] = useState<string | null>(null);

	const resetDrawer = useCallback(() => {
		setIsLoading(false);
		setErrors([]);
		setIsRejectionDialogOpen(false);
		setRejectionReason(null);
	}, []);

	const {
		isLoading: isTemplateDraftLoading,
		isFetching: isTemplateDraftFetching,
		data: templateDraft,
		error: templateDraftError,
	} = useGetPaymentTemplateDraft(templateId);
	useEffect(() => {
		if (!!templateDraftError) {
			onClose();
			enqueueSnackbar(templateDraftError.message, {
				variant: 'error',
			});
		}
	}, [templateDraftError, resetDrawer, onClose, enqueueSnackbar]);

	const userCreatedDraft = useMemo(
		() =>
			templateDraft?.statusHistory.some(
				(x) =>
					x.templateVersion === templateDraft.templateVersion &&
					(x.paymentTemplateStatusType ===
						PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Created] ||
						x.paymentTemplateStatusType ===
							PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Draft]) &&
					x.createdBy?.userId === user.sub,
			),
		[templateDraft, user],
	);

	const canApprove = useMemo(
		() =>
			payments4.isTemplateApprover &&
			!userCreatedDraft &&
			(templateDraft?.currentStatus ===
				PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Created] ||
				templateDraft?.currentStatus ===
					PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Draft]),
		[templateDraft, payments4, userCreatedDraft],
	);

	// #region Submit Functions

	const onApprove = useCallback(async () => {
		try {
			if (!templateDraft || !canApprove) return;
			setErrors([]);
			setIsLoading(true);

			const response =
				await applicationApiClient.payments4.paymentTemplates.approve(
					templateDraft.id,
				);

			if (response.status === 200) {
				onClose();
				resetDrawer();
				refetch();
				enqueueSnackbar('Successfully approved payment template.', {
					variant: 'success',
				});
			} else if (response.status === 400 && response.data) {
				setErrors(flattenProblemDetails(response.data as T4ProblemDetails));
				errorsRef?.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			} else throw new Error();
		} catch (error: any) {
			enqueueSnackbar(
				'An unexpected error occured and we were unable to approve the payment template. Please try again later.',
				{
					variant: 'error',
				},
			);
		} finally {
			setIsLoading(false);
		}
	}, [
		templateDraft,
		canApprove,
		applicationApiClient,
		errorsRef,
		onClose,
		resetDrawer,
		refetch,
		enqueueSnackbar,
	]);

	const onReject = useCallback(async () => {
		try {
			if (
				!templateDraft ||
				!payments4.isTemplateApprover ||
				isStringUndefinedOrNullOrWhitespace(rejectionReason)
			)
				return;
			setErrors([]);
			setIsLoading(true);

			const response =
				await applicationApiClient.payments4.paymentTemplates.reject({
					id: templateDraft.id,
					data: {
						reason: rejectionReason!,
					},
				});

			if (response.status === 200) {
				onClose();
				resetDrawer();
				refetch();
				enqueueSnackbar('Successfully rejected payment template.', {
					variant: 'success',
				});
			} else if (response.status === 400 && response.data) {
				setErrors(flattenProblemDetails(response.data as T4ProblemDetails));
				errorsRef?.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			} else throw new Error();
		} catch (error: any) {
			enqueueSnackbar(
				'An unexpected error occured and we were unable to reject the payment template. Please try again later.',
				{
					variant: 'error',
				},
			);
		} finally {
			setIsLoading(false);
		}
	}, [
		templateDraft,
		payments4,
		rejectionReason,
		applicationApiClient,
		errorsRef,
		onClose,
		resetDrawer,
		refetch,
		enqueueSnackbar,
	]);

	// #endregion

	// #region Memoized Values

	const submitActions = useMemo(() => {
		return [
			<DrawerCancelButton
				onCancel={() => {
					onClose();
					resetDrawer();
				}}
				label="Close"
			/>,
			<Tooltip
				title={
					!payments4.isTemplateApprover
						? 'You do not have the correct permission to reject payment template changes. Contact your administrator if you require access.'
						: ''
				}
			>
				<span>
					<T4Button
						color="error"
						variant="contained"
						onClick={() => setIsRejectionDialogOpen(true)}
						startIcon={<Cancel />}
						disabled={isLoading || !payments4.isPaymentApprover}
					>
						Reject
					</T4Button>
				</span>
			</Tooltip>,
			<Tooltip
				title={
					!payments4.isTemplateApprover
						? 'You do not have the correct permission to approve payment template changes. Contact your administrator if you require access.'
						: userCreatedDraft
						? 'You cannot approve a template draft you created.'
						: ''
				}
			>
				<span>
					<T4Button
						color="primary"
						variant="contained"
						onClick={onApprove}
						disabled={isLoading || !canApprove}
						startIcon={<CheckCircle />}
					>
						Approve
					</T4Button>
				</span>
			</Tooltip>,
		];
	}, [
		isLoading,
		userCreatedDraft,
		payments4,
		canApprove,
		onApprove,
		onClose,
		resetDrawer,
	]);

	const RejectionDrawer = useMemo(
		() => (
			<Drawer
				variant="temporary"
				anchor="right"
				open={isRejectionDialogueOpen}
				onClose={() => {
					setIsRejectionDialogOpen(false);
					setRejectionReason(null);
				}}
				sx={{
					'& .MuiPaper-root': {
						top: 'auto',
						bottom: 0,
						width: DrawerWidth,
						height: 'fit-content',
					},
				}}
			>
				<Grid
					container
					sx={{ gap: 2, paddingX: '1.5rem', paddingY: '0.75rem' }}
				>
					<Grid item xs={12}>
						<Typography variant="h4" fontWeight={500}>
							Reason For Rejection
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<T4TextFieldV2
							id="rejection-reason"
							label="What needs to be changed or fixed?"
							value={rejectionReason ?? ''}
							onChange={(value: string) => {
								if (isStringUndefinedOrNullOrWhitespace(value))
									setRejectionReason(null);
								else setRejectionReason(value);
							}}
							multiline
							minRows={4}
							maxRows={4}
							helperText={`${rejectionReason?.length ?? 0}/200`}
							inputProps={{ maxLength: 200 }}
							required
						/>
					</Grid>
					<Grid
						container
						item
						xs={12}
						sx={{ justifyContent: 'flex-end' }}
						columnSpacing={1}
					>
						<Grid item xs="auto">
							<DrawerCancelButton
								onCancel={() => {
									setIsRejectionDialogOpen(false);
									setRejectionReason(null);
								}}
								stonlyId="reject-payment-template-cancel-button"
							/>
						</Grid>
						<Grid item xs="auto">
							<T4Button
								color="error"
								variant="contained"
								onClick={() => {
									setIsRejectionDialogOpen(false);
									onReject();
								}}
								disabled={
									isLoading ||
									isStringUndefinedOrNullOrWhitespace(rejectionReason)
								}
							>
								Reject
							</T4Button>
						</Grid>
					</Grid>
				</Grid>
			</Drawer>
		),
		[isRejectionDialogueOpen, rejectionReason, isLoading, onReject],
	);

	// #endregion

	return (
		<T4DrawerBase
			title="Review Template"
			open={!!templateId}
			initializing={isTemplateDraftLoading || isTemplateDraftFetching}
			loading={isLoading}
			onClose={() => {
				onClose();
				resetDrawer();
			}}
			utilityActions={[
				<CopyToClipboardIconButton
					valueToCopy={window.location.href}
					initialTooltipText="Copy Link"
				>
					<Share />
				</CopyToClipboardIconButton>,
			]}
			actions={submitActions}
			disableNavigationCollapse
		>
			<Grid container sx={{ gap: 2 }}>
				<Grid item xs={12}>
					<T4Alert
						severity="warning"
						icon={<GradingOutlined />}
						sx={{
							'&.MuiPaper-root': {
								margin: 0,
								height: '100%',
							},
						}}
					>
						<Typography variant="body2">
							Edits have been made to this template, please review the
							information below.
						</Typography>
					</T4Alert>
				</Grid>

				<Grid container item xs={12} spacing={1}>
					<Grid item xs={6}>
						<Typography variant="body1" sx={{ fontWeight: 500 }}>
							Template Name
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography variant="body1" sx={{ textAlign: 'right' }}>
							{templateDraft?.name}
						</Typography>
					</Grid>

					<Grid item xs={6}>
						<Typography variant="body1" sx={{ fontWeight: 500 }}>
							Payment Type
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography variant="body1" sx={{ textAlign: 'right' }}>
							{templateDraft?.paymentType}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography variant="body1" sx={{ fontWeight: 500 }}>
							Payment Currency
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography variant="body1" sx={{ textAlign: 'right' }}>
							{templateDraft?.currencyCode}
						</Typography>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid container item xs={12} spacing={1}>
					<PaymentPartyInformation
						party={templateDraft?.initiator ?? null}
						partyType="Initiator"
					/>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid container item xs={12} spacing={1}>
					<PaymentPartyInformation
						party={templateDraft?.payee ?? null}
						partyType="Payee"
					/>
				</Grid>

				<Grid item xs={12}>
					<T4TextFieldV2
						label="Payment Information"
						value={templateDraft?.referenceData ?? ''}
						minRows={4}
						maxRows={4}
						multiline
						InputProps={{ readOnly: true }}
					/>
				</Grid>

				{Object.values(errors).length > 0 && (
					<Grid item xs={12} ref={errorsRef}>
						<T4AlertStack errors={errors} />
					</Grid>
				)}
			</Grid>

			{RejectionDrawer}
		</T4DrawerBase>
	);
};
