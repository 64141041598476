import {
	AutoScale,
	Diagram,
	DiagramEventHandler,
	DiagramEventName,
	DiagramInitOptions,
	GraphLinksModel,
	Node,
} from 'gojs';
import {
	clearAllHighlighted,
	getNodeUnderMouse,
	highlightDirectConnections,
	isControlPressed,
	recurseHighlightAllAncestors,
	recurseHighlightAllDescendants,
} from '../functions';

export function getDiagram(
	options?: DiagramInitOptions,
	diagramListeners?: [name: DiagramEventName, listener: DiagramEventHandler][],
) {
	const diagram = new Diagram({
		initialAutoScale: AutoScale.Uniform,
		scrollMargin: 1024,
		...options,
		model: new GraphLinksModel({
			nodeKeyProperty: 'key',
			linkKeyProperty: 'key',
		}),
	});

	if (diagramListeners) {
		diagramListeners.forEach(([name, listener]) => {
			diagram.addDiagramListener(name, listener);
		});
	}

	return diagram;
}

export function setupDiagramEvents(diagram: Diagram) {
	diagram.defaultTool.doKeyDown = function onKeyDown() {
		const event = diagram.lastInput.event;
		if (event instanceof KeyboardEvent) {
			const selectedNode = getNodeUnderMouse(diagram);
			if (!(selectedNode instanceof Node)) {
				return;
			}
			if (!isControlPressed(event)) {
				if (event.shiftKey && event.altKey) {
					clearAllHighlighted(diagram);
					recurseHighlightAllDescendants(selectedNode);
				} else if (event.shiftKey) {
					clearAllHighlighted(diagram);
					recurseHighlightAllAncestors(selectedNode);
				} else if (event.altKey) {
					clearAllHighlighted(diagram);
					highlightDirectConnections(selectedNode);
				}
			}
		}
	};

	diagram.defaultTool.doKeyUp = function onKeyUp() {
		const event = diagram.lastInput.event;
		if (event instanceof KeyboardEvent) {
			const selectedNode = getNodeUnderMouse(diagram);
			if (!(selectedNode instanceof Node)) {
				return;
			}
			if (!isControlPressed(event)) {
				if (event.shiftKey && event.altKey) {
					clearAllHighlighted(diagram);
					recurseHighlightAllDescendants(selectedNode);
				} else if (event.shiftKey) {
					clearAllHighlighted(diagram);
					recurseHighlightAllAncestors(selectedNode);
				} else if (event.altKey) {
					clearAllHighlighted(diagram);
					highlightDirectConnections(selectedNode);
				} else {
					clearAllHighlighted(diagram);
				}
			}
		}
	};
}
