import { groupContainer } from 'features/entity4/visualizations/_shared/_templates/groupContainer';
import { Placeholder, Point } from 'gojs';

export type StandaloneAccountsNodeData = {
	key: string;
	isOrphanGroupVisible: boolean;
	loc?: Point;
};

export function standaloneAccounts() {
	return groupContainer(
		'Standalone Accounts',
		new Placeholder({ padding: 8 }).bind(
			'location',
			'',
			(data: StandaloneAccountsNodeData) => data.loc ?? undefined,
		),
		{
			wrappingCloumn: 3,
			addSeparator: true,
		},
	).bind(
		'visible',
		'',
		(data: StandaloneAccountsNodeData) => data.isOrphanGroupVisible ?? true,
	);
}
