import { Box, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { FC, useState } from 'react';
import { T4AddButton } from 'shared/components/buttons';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { useClients } from 'shared/hooks/useClients';
import { stonlyData } from 'stonly/functions';
import DataImportsModal from './components/DataImportsModal';
import T4TemplatesButton from './components/downloadTemplatesPopover';
import { columns } from './utils/utils';

export const DataImportsPage: FC = observer(() => {
	const [open, setOpen] = useState<boolean>(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const { customerApiClient } = useClients();

	const { data, isLoading } = useQuery(['imports'], () =>
		customerApiClient.api.v1.frontend.cash4.dataImports.dataImports(),
	);

	return (
		<Grid container>
			<Grid item xs={12}>
				<PageHeader
					id={pageHeaderStonlyIds.dataImportsPage}
					title="Data Imports"
				/>
			</Grid>
			<Grid container item rowSpacing={2} xs={12} sx={{ padding: '1rem' }}>
				<Grid
					container
					item
					xs={12}
					sx={{
						justifyContent: 'space-between',
					}}
				>
					<Typography variant="h3" display="flex" alignItems="center">
						Import History
					</Typography>
					<Box sx={{ display: 'flex', gap: 2 }}>
						<T4TemplatesButton />
						<T4AddButton
							label="Import Data"
							onClick={handleOpen}
							{...stonlyData({ id: 'open-import-data-button' })}
							{...{ 'data-testid': 'open-import-data-button' }}
						/>
					</Box>
				</Grid>
				<Grid container item xs={12} sx={{ height: '70vh' }}>
					<UserPreferencesDataGrid
						tableKey="data-imports-history"
						columns={columns}
						stonlyId="data-imports-history"
						loading={isLoading}
						rows={data?.data?.uploadedFiles ?? []}
						initialState={{
							sorting: {
								sortModel: [{ field: 'uploadedDate', sort: 'desc' }],
							},
						}}
						showToolbar
						showCustomViewButton
						csvOptions={{
							fileName: `Cash4_Data-Imports-${moment().format('YYYY-MM-DD')}`,
						}}
					/>
				</Grid>
			</Grid>
			<DataImportsModal
				open={open}
				onClose={handleClose}
				counterparties={data?.data?.counterparties ?? []}
			/>
		</Grid>
	);
});
