import {
	Binding,
	Curve,
	GraphObject,
	Link,
	Point,
	Routing,
	Shape,
	Spot,
	TextBlock,
} from 'gojs';
import { Moment } from 'moment';

export type LinkData = {
	key: string;
	category?: string;
	from: string;
	to: string;

	dateFrom?: Moment;
	dateTo?: Moment;

	isVisible?: boolean;
	type?: string;
	movement?: string;
	isLeftSide?: boolean;
	linkLabelsEnabled?: boolean;
};

export function link(showTooltip: boolean = false) {
	const link = new Link({
		routing: Routing.AvoidsNodes,
		curve: Curve.JumpGap,
		corner: 5,
		copyable: false,
		deletable: false,
		selectionAdorned: false,
	})
		.add(
			new Shape()
				.bindObject('strokeWidth', 'isSelected', (isSelected) =>
					isSelected ? 5 : 2,
				)
				.bind('stroke', '', (data: LinkData) => {
					if (data.type === 'Subaccount') {
						return '#F6802C';
					}

					return 'black';
				})
				.bind('fill', '', (data: LinkData) => {
					if (data.type === 'Subaccount') {
						return '#F6802C';
					}

					return 'black';
				})
				.bind('strokeDashArray', '', (data: LinkData) => {
					if (data.type === 'Subaccount' || data.movement === 'Manual') {
						return [4, 2];
					}

					return null;
				}),

			new Shape({
				fromArrow: '',
				scale: 1.5,
				fill: 'black',
				stroke: 'black',
			})
				.bind('fromArrow', '', (data: LinkData) => {
					if (
						data.type === 'Two-Way' ||
						(data.type === 'One-Way' && data.isLeftSide === true)
					) {
						return 'BackwardTriangle';
					}

					return '';
				})
				.bind('stroke', '', (data: LinkData) => {
					if (data.type === 'Subaccount') {
						return '#F6802C';
					}

					return 'black';
				})
				.bind('fill', '', (data: LinkData) => {
					if (data.type === 'Subaccount') {
						return '#F6802C';
					}

					return 'black';
				}),

			new Shape({
				toArrow: '',
				scale: 1.5,
				fill: 'black',
				stroke: 'black',
			})
				.bind('toArrow', '', (data: LinkData) => {
					if (
						data.type === 'Two-Way' ||
						(data.type === 'One-Way' && data.isLeftSide !== true)
					) {
						return 'Triangle';
					}

					if (data.type === 'Entity') {
						return 'Block';
					}

					return '';
				})
				.bind('stroke', '', (data: LinkData) => {
					if (data.type === 'Subaccount') {
						return '#F6802C';
					}

					return 'black';
				})
				.bind('fill', '', (data: LinkData) => {
					if (data.type === 'Subaccount') {
						return '#F6802C';
					}

					return 'black';
				}),

			new TextBlock({
				margin: 5,
				segmentIndex: NaN,
				segmentFraction: 2 / 3,
				segmentOffset: new Point(NaN, NaN),
				alignment: Spot.Right,
			}).bind('text', '', (data: LinkData) => {
				let text = '';

				if (data.linkLabelsEnabled) {
					if (data.movement || data.type) {
						if (data.type) {
							text += `${data.type}`;
						}

						if (data.movement) {
							if (text) {
								text += '\n';
							}

							text += `${data.movement}`;
						}
					}
				}

				return text;
			}),
		)
		.bind('visible', '', (data: LinkData) => data.isVisible ?? true);

	if (showTooltip) {
		link.toolTip = GraphObject.make(
			'ToolTip',
			GraphObject.make(
				TextBlock,
				{ margin: 4 },
				new Binding('text', '', (data: LinkData) => {
					return !!data.movement
						? data.movement + ' Funding'
						: 'Funding Direction Unknown';
				}),
			),
		);
	}

	return link;
}
