import { Margin, Panel, Spot, TextBlock } from 'gojs';
import { textColor } from '../constants';

export function nodeRow(
	row: number,
	label: string,
	property: string,
	getText: (data: { [key: string]: any }) => string = (data) =>
		data[property] || '-',
) {
	return new Panel(Panel.TableRow, {
		row: row,
		name: `${property}p`,
		alignment: Spot.Left,
	}).add(
		new TextBlock(label, {
			column: 0,
			columnSpan: 1,
			margin: new Margin(0, 8, 0, 0),
			alignment: Spot.Left,
			font: 'bold 12px "Roboto","Helvetica","Arial",sans-serif',
			stroke: '#8d8c8d',
		}),
		new TextBlock('', {
			column: 2,
			font: '13px "Roboto","Helvetica","Arial",sans-serif',
			stroke: textColor,
			alignment: Spot.Left,
			width: 200,
		}).bind('text', '', (data) => getText(data) || ''),
	);
}
