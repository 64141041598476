import { groupContainer } from 'features/entity4/visualizations/_shared/_templates/groupContainer';
import {
	legendItemFont,
	legendItemHeaderFont,
	textColor,
} from 'features/entity4/visualizations/_shared/constants';
import { RegionColors } from 'features/entity4/visualizations/accountMap/models/accountMapTypes';
import { Margin, Panel, Point, Shape, Spot, Stretch, TextBlock } from 'gojs';
import { EntityStatusOptionColors } from '../../models/orgChartTypes';

export type OrgChartLegendData = {
	key: string;
	category: 'Legend';
	loc?: Point;
	isGroup: true;
	isLegendVisible: boolean;
	isSecondaryOwnershipVisible: boolean;
	isEntityStatusVisible: boolean;
	isEntityRegionVisible: boolean;
	entityStatuses: string[];
	entityRegions: string[];
};

export function orgChartLegendBody() {
	const headerMargin = new Margin(8, 0, 8, 0);
	const panelMargin = new Margin(8, 8, 8, 8);
	const spaceBetweenShapeAndText = new Margin(8, 0, 0, 8);

	return groupContainer(
		'Legend',
		new Panel(Panel.Vertical, {
			stretch: Stretch.Horizontal,
		}).add(
			// connectors
			new Panel(Panel.Vertical, {
				stretch: Stretch.Horizontal,
			}).add(
				new Shape('LineH', {
					stretch: Stretch.Horizontal,
					height: 0,
					stroke: '#e2e2e3',
					strokeWidth: 2,
				}),
				new Panel(Panel.Vertical, {
					alignment: Spot.TopLeft,
					padding: new Margin(8, 8, 8, 8),
				}).add(
					new TextBlock('Connectors', {
						font: legendItemHeaderFont,
						stroke: textColor,
						alignment: Spot.TopLeft,
						margin: headerMargin,
					}),

					new Panel(Panel.Horizontal, {
						alignment: Spot.TopLeft,
						margin: panelMargin,
					}).add(
						new Shape('LineH', {
							width: 35,
							height: 1,
							stroke: 'black',
							strokeWidth: 2,
						}),
						new TextBlock('Primary Ownership', {
							font: legendItemFont,
							stroke: textColor,
							margin: spaceBetweenShapeAndText,
						}),
					),

					new Panel(Panel.Horizontal, {
						alignment: Spot.TopLeft,
						margin: panelMargin,
					})
						.add(
							new Shape('LineH', {
								width: 35,
								height: 1,
								stroke: '#00aaff',
								strokeWidth: 2,
							}),
							new TextBlock('Secondary Ownership', {
								font: legendItemFont,
								stroke: textColor,
								margin: spaceBetweenShapeAndText,
							}),
						)
						.bind(
							'visible',
							'',
							(data: OrgChartLegendData) => !!data.isSecondaryOwnershipVisible,
						),
				),
			),

			// entity status
			new Panel(Panel.Vertical, {
				stretch: Stretch.Horizontal,
			})
				.add(
					new Shape('LineH', {
						stretch: Stretch.Horizontal,
						height: 0,
						stroke: '#e2e2e3',
						strokeWidth: 2,
					}),
					new Panel(Panel.Vertical, {
						alignment: Spot.TopLeft,
						padding: new Margin(8, 8, 8, 8),
					}).add(
						new TextBlock('Entity Status', {
							font: legendItemHeaderFont,
							stroke: textColor,
							alignment: Spot.TopLeft,
							margin: headerMargin,
						}),
						new Panel(Panel.Vertical, {
							alignment: Spot.TopLeft,
							itemTemplate: new Panel(Panel.Horizontal, {
								alignment: Spot.Left,
								margin: panelMargin,
							}).add(
								new Shape({
									margin: new Margin(0, 8, 0, 8),
									alignment: Spot.Left,
									strokeWidth: 0,
									width: 24,
									height: 24,
								}).bind(
									'fill',
									'',
									(data: string) => EntityStatusOptionColors.get(data) || null,
								),
								new TextBlock({
									font: legendItemFont,
									stroke: textColor,
									margin: spaceBetweenShapeAndText,
								}).bind('text', '', (data: string) => data),
							),
						}).bind(
							'itemArray',
							'',
							(data: OrgChartLegendData) => data.entityStatuses || [],
						),
					),
				)
				.bind(
					'visible',
					'',
					(data: OrgChartLegendData) =>
						!!data.isEntityStatusVisible && data.entityStatuses.length > 0,
				),

			// entity region
			new Panel(Panel.Vertical, {
				stretch: Stretch.Horizontal,
			})
				.add(
					new Shape('LineH', {
						stretch: Stretch.Horizontal,
						height: 0,
						stroke: '#e2e2e3',
						strokeWidth: 2,
					}),
					new Panel(Panel.Vertical, {
						alignment: Spot.TopLeft,
						padding: new Margin(8, 8, 8, 8),
					}).add(
						new TextBlock('Entity Regions', {
							font: legendItemHeaderFont,
							stroke: textColor,
							alignment: Spot.TopLeft,
							margin: headerMargin,
						}),
						new Panel(Panel.Vertical, {
							alignment: Spot.TopLeft,
							itemTemplate: new Panel(Panel.Horizontal, {
								alignment: Spot.Left,
								margin: panelMargin,
							}).add(
								new Shape({
									margin: new Margin(0, 8, 0, 8),
									alignment: Spot.Left,
									strokeWidth: 0,
									width: 24,
									height: 24,
								}).bind(
									'fill',
									'',
									(data: string) => RegionColors.get(data) || null,
								),
								new TextBlock({
									font: legendItemFont,
									stroke: textColor,
									margin: spaceBetweenShapeAndText,
								}).bind('text', '', (data: string) => data),
							),
						}).bind(
							'itemArray',
							'',
							(data: OrgChartLegendData) => data.entityRegions || [],
						),
					),
				)
				.bind(
					'visible',
					'',
					(data: OrgChartLegendData) =>
						!!data.isEntityRegionVisible && data.entityRegions.length > 0,
				),
		),
	)
		.bind('visible', '', (data: OrgChartLegendData) => !!data.isLegendVisible)
		.bind('location', '', (data: OrgChartLegendData) => data.loc ?? undefined);
}
