/* eslint-disable mobx/missing-observer */
import { DragHandle } from '@mui/icons-material';
import { GridColDef, GridRowOrderChangeParams } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { TransactionRuleListItem } from './models';
import { fetchRules, useUpdateRulePriority } from './services';
import { Cash4UserProps } from './utilities';

interface ListRulesProps {
	getColumns: () => GridColDef[];
	cash4User: Cash4UserProps;
	onRowClick: (row: TransactionRuleListItem) => void;
}

const ListRules: React.FC<ListRulesProps> = ({
	getColumns,
	cash4User,
	onRowClick,
}) => {
	const { updateRulePriority, isLoadingPriorities } = useUpdateRulePriority();

	const handleRowOrderChange = (params: GridRowOrderChangeParams) => {
		if (isLoadingPriorities) return;
		updateRulePriority(params);
	};

	const { data: rules, isLoading } = useQuery(['rules'], fetchRules);

	return (
		<UserPreferencesDataGrid
			tableKey="rulesPage"
			stonlyId="rules"
			columns={getColumns()}
			rows={rules ? rules : []}
			loading={isLoading}
			emptyMessage="No Rules"
			density="standard"
			columnVisibilityModel={{
				cfcName: false,
				cftName: false,
				cfstName: false,
			}}
			initialState={{
				pinnedColumns: { left: ['menu', '__reorder__', 'priority'] },
			}}
			slots={{
				rowReorderIcon: () => <DragHandle />,
			}}
			rowReordering={cash4User.isAuthor && !isLoadingPriorities}
			onRowOrderChange={handleRowOrderChange}
			onRowClick={(params) => onRowClick(params.row)}
			showToolbar
			showCustomViewButton
		/>
	);
};
export default ListRules;
