import { E4ObjectBase } from 'modules/clients/apiGateway/entity4';
import { Account } from 'modules/clients/apiGateway/entity4/accounts';
import { Counterparty } from 'modules/clients/apiGateway/entity4/counterparties';
import { LegalEntity } from 'modules/clients/apiGateway/entity4/legalentities';
import { Partner } from 'modules/clients/apiGateway/entity4/partners';
import { T4DataResponse2 } from 'modules/clients/types';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { PayeeTypes } from './utilities';

export const usePaymentTemplateFormDataFetchers = () => {
	const { applicationApiClient } = useClients();

	/**
	 * Returns approved objects based on object type parameter
	 */
	const getObjectsAsync = useCallback(
		async (
			objectType: PayeeTypes,
		): Promise<LegalEntity[] | Partner[] | Counterparty[]> => {
			let response = undefined;
			switch (objectType) {
				case 'Entity':
					response = await applicationApiClient.entity4.legalEntities.getAll();
					break;
				case 'Partner':
					response = await applicationApiClient.entity4.partners.getAll();
					break;
				case 'Counterparty':
					response = await applicationApiClient.entity4.counterparties.getAll();
					break;
				default:
					return [];
			}

			if (response.status === 200 && response.data)
				return (
					response.data as T4DataResponse2<
						LegalEntity[] | Partner[] | Counterparty[]
					>
				).data.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
			else throw new Error();
		},
		[applicationApiClient],
	);

	/**
	 * Returns Accounts owned by object parameter
	 * Filters out unapproved accounts and accounts with an invalid counterparty (null/not approved/no ultimate parent bank code)
	 * Throws Error object when request fails
	 */
	const getValidAccountsOwnedByObjectAsync = useCallback(
		async (object: E4ObjectBase) => {
			let response = undefined;
			switch (object.objectType) {
				case 'Entity':
					response =
						await applicationApiClient.entity4.legalEntities.getAccountsOwned(
							object.id,
						);
					break;
				case 'Partner':
					response =
						await applicationApiClient.entity4.partners.getAccountsOwned(
							object.id,
						);
					break;
				case 'Counterparty':
					response =
						await applicationApiClient.entity4.counterparties.getAccountsOwned(
							object.id,
						);
					break;
				default:
					return [];
			}

			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<Account[]>).data.sort(
					(a, b) => (a.displayName > b.displayName ? 1 : -1),
				);
			else throw new Error();
		},
		[applicationApiClient],
	);

	return {
		getObjectsAsync,
		getValidAccountsOwnedByObjectAsync,
	};
};
