/* eslint-disable mobx/missing-observer */
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Cash4Account } from 'models/apiModels';
import { stonlyIds } from '..';
import {
	getDateColumnDefinition,
	getFormattedDateTimeColumnDefinition,
} from 'shared/utilities/dataGrid/columnDefinitions';

const ignoredColumns = (
	handleButtonClick: (account: Cash4Account) => void,
): GridColDef[] => [
	{
		field: 'LinkAccount',
		headerName: '',
		flex: 1,
		renderCell: (params) => {
			return (
				<Button
					variant="text"
					size="small"
					onClick={() => handleButtonClick(params.row as Cash4Account)}
					data-stonlyid={stonlyIds.linkAccountInlineGridButton}
				>
					Link Account
				</Button>
			);
		},
		filterable: false,
	},
	{
		...getFormattedDateTimeColumnDefinition(),
		field: 'ignoredDate',
		headerName: 'Ignored Date/Time',
		flex: 1,
		valueGetter: (params) => params.row.ignoredDate,
	},
	{
		field: 'ignoredByEmail',
		headerName: 'Ignored By',
		type: 'string',
		flex: 1,
		valueGetter: (params) => params.row.ignoredByEmail,
	},
	{
		field: 'accountIdentifier',
		headerName: 'Account Identifier',
		type: 'string',
		flex: 1,
		valueGetter: (params) => params.row.accountIdentifier,
	},
	{
		field: 'bankName',
		headerName: 'Bank Name',
		type: 'string',
		flex: 1,
		valueGetter: (params) => params.row.bankName,
	},
	{
		...getDateColumnDefinition(),
		field: 'postDate',
		headerName: 'Last Statement Date',
		flex: 1,
		valueGetter: (params) => params.row.latestSyncEvent?.postDate,
	},
];

export { ignoredColumns };

export const ExpandIcon = () => (
	<ChevronRightIcon data-stonlyid={stonlyIds.expandButton} />
);
export const CollapseIcon = () => (
	<ExpandMoreIcon data-stonlyid={stonlyIds.collapseButton} />
);

export const columnsAccountEventsHistory: GridColDef[] = [
	{
		...getFormattedDateTimeColumnDefinition(),
		headerName: 'Imported Date',
		field: 'importedDateTime',
		width: 200,
	},
	{
		...getDateColumnDefinition(),
		headerName: 'Statement Date',
		field: 'statementDate',
		flex: 1,
	},
	{
		headerName: 'Bank Code',
		field: 'bankCode',
		flex: 1,
	},
	{
		headerName: 'Connector Name',
		field: 'connectorName',
		flex: 1,
	},
	{
		headerName: 'Display Name',
		field: 'displayName',
		flex: 1,
	},
	{
		headerName: 'Status',
		field: 'status',
		flex: 1,
	},
	{
		headerName: 'Error Message',
		field: 'errorMessage',
		flex: 1,
	},
	{
		headerName: 'Count of Balance Details',
		field: 'balanceDetailsCount',
		type: 'number',
		flex: 1,
	},
	{
		headerName: 'Count of Transaction Details',
		field: 'transactionDetailsCount',
		type: 'number',
		flex: 1,
	},
];
