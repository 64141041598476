import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { FormModal } from 'shared/components/formModal';
import { DeleteSubtypeViewModel } from './deleteSubtypeViewModel';

interface IDeleteSubtypeModalProps {
	viewModel: DeleteSubtypeViewModel;
}

export const DeleteSubtypeModal: FC<IDeleteSubtypeModalProps> = observer(
	({ viewModel }) => {
		if (!viewModel.canDelete() && viewModel.isOpen()) {
			return (
				<FormModal
					open={viewModel.isOpen()}
					onClose={() => viewModel.closeModal()}
					onSubmit={() => Promise.resolve(viewModel.closeModal())}
					submitButtonLabel="Close"
					title="Cannot delete subtype"
					description="This subtype cannot be deleted as there are associated rules or transactions."
					loading={false}
					maxWidth="sm"
					submitButtonOnly
				/>
			);
		}

		return (
			<FormModal
				open={viewModel.isOpen()}
				onClose={() => viewModel.closeModal()}
				onSubmit={() => viewModel.delete()}
				submitDisabled={viewModel.isLoading()}
				submitButtonLabel="Delete"
				submitButtonColor="error"
				title="Delete subtype?"
				description="This subtype will be permanently deleted from the system. This action cannot be undone."
				error={viewModel.getError() ?? ''}
				loading={viewModel.isLoading()}
				maxWidth="sm"
			>
				<T4AlertStack errors={viewModel.getErrors()} />
			</FormModal>
		);
	}
);
