import { ExpandMore, WarningAmber } from '@mui/icons-material';
import {
	Box,
	Button,
	Collapse,
	Divider,
	Grid,
	Typography,
} from '@mui/material';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import {
	Payment,
	PaymentStatusTypes,
} from 'modules/clients/apiGateway/payments4/payments';
import { FC, useState } from 'react';
import { convertDate, formatReadDate } from 'shared/utilities/dateUtilities';
import { formatCurrency } from 'utilities/currencyUtils';
import { PaymentPartyInformation } from '../paymentPartyInformationBox';
import {
	getPaymentApprovalStatusText,
	getPaymentStatusText,
} from '../utilities';

export const PaymentStatusAndDetailsTab: FC<{
	tabIndex: number;
	payment: Payment | undefined;
}> = ({ tabIndex, payment }) => {
	const [collapseOpen, setCollapseOpen] = useState<boolean>(false);

	return tabIndex === 0 ? (
		<div role="tabpanel">
			<Grid container sx={{ gap: 2 }}>
				<Grid container item xs={12} sx={{ gap: 1 }}>
					<Grid container item xs={12}>
						<Grid item xs={6}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Current Status
							</Typography>
						</Grid>
						<Grid item xs={6} sx={{ textAlign: 'right' }}>
							{payment?.currentStatus ===
							PaymentStatusTypes[PaymentStatusTypes.Created] ? (
								<Box
									sx={{
										display: 'flex',
										gap: 1,
										alignItems: 'center',
										justifyContent: 'flex-end',
									}}
								>
									<WarningAmber color="warning" />
									<Typography variant="body1" sx={{ marginTop: '2px' }}>
										{getPaymentStatusText(payment?.currentStatus)}
									</Typography>
								</Box>
							) : (
								<Typography variant="body1">
									{getPaymentStatusText(payment?.currentStatus)}
								</Typography>
							)}
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={6}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Current Approval Status
							</Typography>
						</Grid>
						<Grid item xs={6} sx={{ textAlign: 'right' }}>
							<Typography variant="body1">
								{getPaymentApprovalStatusText(payment)}
							</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={6}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								End-to-End ID
							</Typography>
						</Grid>
						<Grid item xs={6} sx={{ textAlign: 'right' }}>
							<Typography variant="body1">{payment?.endToEndId}</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid container item xs={12} sx={{ gap: 1 }}>
					<Grid container item xs={12}>
						<Grid item xs={6}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Template Name
							</Typography>
						</Grid>
						<Grid item xs={6} sx={{ textAlign: 'right' }}>
							<Typography variant="body1">
								{payment?.paymentTemplateName}
							</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={6}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Payment Type
							</Typography>
						</Grid>
						<Grid item xs={6} sx={{ textAlign: 'right' }}>
							<Typography variant="body1">{payment?.paymentType}</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={6}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Value Date
							</Typography>
						</Grid>
						<Grid item xs={6} sx={{ textAlign: 'right' }}>
							<Typography variant="body1">
								{formatReadDate(convertDate(payment?.valueDate))}
							</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={6}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Payment Amount
							</Typography>
						</Grid>
						<Grid item xs={6} sx={{ textAlign: 'right' }}>
							<Typography variant="body1">
								{formatCurrency(payment?.instructedAmount.value, {
									currency: payment?.instructedAmount.currencyCode ?? undefined,
								})}
							</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={6}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Payment Currency
							</Typography>
						</Grid>
						<Grid item xs={6} sx={{ textAlign: 'right' }}>
							<Typography variant="body1">
								{payment?.instructedAmount.currencyCode}
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						onClick={() => setCollapseOpen(!collapseOpen)}
						endIcon={
							<ExpandMore
								sx={{
									transition: 'rotate .25s',
									rotate: collapseOpen ? '180deg' : '0deg',
								}}
							/>
						}
						variant="text"
						color="secondary"
					>
						{collapseOpen ? 'Collapse Details' : 'Expand Details'}
					</Button>
				</Grid>

				<Collapse in={collapseOpen} unmountOnExit>
					<Grid container sx={{ gap: 2 }}>
						<Grid container item xs={12} spacing={1}>
							<PaymentPartyInformation
								party={payment?.initiator ?? null}
								partyType="Initiator"
							/>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid container item xs={12} spacing={1}>
							<PaymentPartyInformation
								party={payment?.payee ?? null}
								partyType="Payee"
							/>
						</Grid>
					</Grid>
				</Collapse>

				<Grid item xs={12}>
					<T4TextFieldV2
						label="Payment Information"
						value={payment?.referenceData ?? ''}
						minRows={4}
						maxRows={4}
						multiline
						InputProps={{ readOnly: true }}
					/>
				</Grid>
			</Grid>
		</div>
	) : null;
};
