import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
	Read,
	T4DataResponse2,
	T4Response,
	Write,
} from 'modules/clients/types';

//#region Read Models

export type VisualizationPreferences = {
	id: string;
	options?: VisualizationPreferenceOption[];
	nodes?: VisualizationPreferenceNodeData[];
	views?: VisualizationPreferenceView[];
};

export type VisualizationPreferenceView = {
	id: string;
	name: string;
	options?: VisualizationPreferenceOption[];
	nodes?: VisualizationPreferenceNodeData[];
};

export type VisualizationPreferenceOption = {
	id: string;
	optionId: string;
	hide: boolean;
	value?: string;
};

export type VisualizationPreferenceNodeData = {
	id: string;
	key: string;
	x: number;
	y: number;
	visible: boolean;
};

export type LegalEntity = {
	id: string;
	entityCode: string;
	anglicizedLegalName?: string;
	entityStatus?: string;
	entityRegion?: string;
	country?: string;
	functionalCurrencyCode?: string;
	erpCode?: string;
	erpPlatform?: string;
	formOfOrganization?: string;
	incorporationDate?: string;
	dissolutionDate?: string;
	acquiredCompany?: string;
	acquisitionDate?: string;
	leiIdentifier?: string;
	homeRegistration?: Registration;
	federalTaxId?: TaxId;
	accounts?: Relationship[];
	primaryOwnerOf?: Relationship[];
	secondaryOwnerOf?: Relationship[];
	primaryMemberOf?: Relationship[];
	secondaryMemberOf?: Relationship[];
};

export type Account = {
	id: string;
	counterpartyId?: string;
	code: string;
	shortName?: string;
	status?: string;
	type?: string;
	purpose?: string;
	number?: string;
	currencyCode?: string;
	generalLedgerAccountNumber?: string;
	openDate?: string;
	closeDate?: string;
	fundedAccounts?: AccountRelationship[];
	subaccounts?: Subaccount[];
};

export type Subaccount = {
	id: string;
	code: string;
	shortName?: string;
	status?: string;
	type?: string;
	purpose?: string;
	number?: string;
	currencyCode?: string;
	generalLedgerAccountNumber?: string;
	openDate?: string;
	closeDate?: string;
};

export type Counterparty = {
	id: string;
	code: string;
	anglicizedLegalName?: string;
	ultimateParent?: string;
	country?: string;
};

export type AccountRelationship = {
	objectId: string;
	from?: string;
	to?: string;
	fundingDirection: string;
	cashFlowMovement?: string;
	fundingFrequency?: string;
};

export type Relationship = {
	objectId: string;
	from?: string;
	to?: string;
};

export type Registration = {
	id: string;
	number?: string;
	stateProvince?: string;
};

export type TaxId = {
	id: string;
	number?: string;
	country?: string;
};

//#endregion

//#region Input Models

export type GetVisualizationPreferencesInput = {
	visualizationCode: string;
};

export type UpdateVisualizationPreferencesInput =
	GetVisualizationPreferencesInput & {
		req: VisualizationPreferencesReq;
	};

export type UpdateVisualizationPreferenceViewInput =
	GetVisualizationPreferencesInput & {
		id: string;
		req: VisualizationPreferencesViewReq;
	};

export type DeleteVisualizationPreferenceViewInput =
	GetVisualizationPreferencesInput & {
		id: string;
		viewId: string;
	};

//#endregion

//#region Reqeust Models

export type VisualizationPreferencesReq = {
	options?: VisualizationPreferenceOptionReq[];
	nodes?: VisualizationPreferenceNodeDataReq[];
};

export type VisualizationPreferencesViewReq = {
	name: string;
	options?: VisualizationPreferenceOptionReq[];
	nodes?: VisualizationPreferenceNodeDataReq[];
};

export type VisualizationPreferenceOptionReq = {
	optionId: string;
	hide: boolean;
	value?: string;
};

export type VisualizationPreferenceNodeDataReq = {
	key: string;
	x: number;
	y: number;
	visible: boolean;
};

//#endregion

//#region Response Models

export type VisualizationDataResponse = {
	legalEntities: LegalEntity[];
	accounts?: Account[];
	counterparties?: Counterparty[];
};

export type OrgChartResponse = T4Response<LegalEntity[]>;

export type VisualizationPreferencesResponse =
	T4Response<VisualizationPreferences>;

export type UpdateVisualizationPreferencesResponse = T4Response<
	VisualizationPreferences,
	VisualizationPreferencesReq
>;

export type DeleteVisualizationPreferencesResponse = T4Response<
	VisualizationPreferences,
	DeleteVisualizationPreferenceViewInput
>;

//#endregion

export type VisualizationEndpoints = {
	orgChart: Read<OrgChartResponse>;
	visualizationPreferences: Read<
		VisualizationPreferencesResponse,
		GetVisualizationPreferencesInput
	>;
	updateVisualizationPreferences: Write<
		UpdateVisualizationPreferencesResponse,
		UpdateVisualizationPreferencesInput,
		VisualizationPreferencesReq
	>;
	updateVisualizationPreferenceView: Write<
		UpdateVisualizationPreferencesResponse,
		UpdateVisualizationPreferenceViewInput,
		VisualizationPreferencesViewReq
	>;
	deleteVisualizationPreferenceView: Write<
		DeleteVisualizationPreferencesResponse,
		DeleteVisualizationPreferenceViewInput,
		undefined
	>;
	data: (
		config?: AxiosRequestConfig,
	) => Promise<AxiosResponse<T4DataResponse2<VisualizationDataResponse>>>;
};

export function visualizations(
	axiosInstance: AxiosInstance,
): VisualizationEndpoints {
	return {
		visualizationPreferences: async (data, config) => {
			return axiosInstance.get(
				`/api/visualizations/${data.visualizationCode}/preferences`,
				{
					...config,
				},
			);
		},
		updateVisualizationPreferences: async (data, config) => {
			return axiosInstance.post(
				`/api/visualizations/${data.visualizationCode}/preferences`,
				data.req,
				{
					...config,
				},
			);
		},
		updateVisualizationPreferenceView: async (data, config) => {
			return axiosInstance.post(
				`/api/visualizations/${data.visualizationCode}/preferences/${data.id}/views`,
				data.req,
				{
					...config,
				},
			);
		},
		deleteVisualizationPreferenceView: async (data, config) => {
			return axiosInstance.delete(
				`/api/visualizations/${data.visualizationCode}/preferences/${data.id}/views/${data.viewId}`,
				{
					...config,
				},
			);
		},
		orgChart: async (_, config) =>
			await axiosInstance.get('/api/visualizations/orgchart', {
				...config,
			}),
		data: async (config) =>
			await axiosInstance.get('/api/visualizations/data', {
				...config,
			}),
	};
}
