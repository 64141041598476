import {
	T4Diagram,
	T4DiagramProps,
} from 'features/entity4/visualizations/_shared/_components/t4Diagram';
import { ArrangingLayout } from 'features/entity4/visualizations/_shared/_extensions/arrangingLayout';
import { link } from 'features/entity4/visualizations/_shared/_templates/link';
import * as go from 'gojs';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import {
	getDiagram,
	setupDiagramEvents,
} from '../../../_shared/_templates/diagram';
import { legalEntityNode } from '../_templates/legalEntityNode';
import { orgChartLegendBody } from '../_templates/orgChartLegend';
import { standaloneLegalEntities } from '../_templates/standaloneLegalEntities';

export const OrgChartRenderer: FC<
	Pick<T4DiagramProps, 'diagramListeners' | 'nodeDataArray' | 'linkDataArray'>
> = observer(({ diagramListeners, nodeDataArray, linkDataArray, ...rest }) => {
	const initDiagram = useCallback(() => {
		const diagram = getDiagram(
			{
				initialAutoScale: go.AutoScale.Uniform,
				initialContentAlignment: go.Spot.Center,
				layout: new ArrangingLayout({
					spacing: new go.Size(400, 50),
					side: go.Spot.RightSide,
					primaryLayout: new go.TreeLayout({
						angle: 90,
						arrangement: go.TreeArrangement.Horizontal,
						arrangementSpacing: new go.Size(50, 50),
						layerSpacing: 35,
						nodeSpacing: 40,
					}),
					arrangingLayout: new go.TreeLayout({
						angle: 90,
						arrangement: go.TreeArrangement.Horizontal,
						arrangementSpacing: new go.Size(50, 50),
					}),
					sideLayout: new go.TreeLayout({
						arrangement: go.TreeArrangement.Horizontal,
						arrangementSpacing: new go.Size(50, 50),
					}),
				}),
				linkTemplateMap: new go.Map([
					{ key: '', value: link() },
					{
						key: 'slink',
						value: go.GraphObject.make(
							go.Link,
							{
								isLayoutPositioned: false,
								isTreeLink: false,
								routing: go.Routing.AvoidsNodes,
								isHighlighted: false,
								copyable: false,
								deletable: false,
							},
							go.GraphObject.make(
								go.Shape,
								{ stroke: '#00aaff' },
								new go.Binding('strokeWidth', 'isSelected', (h) =>
									h ? 5 : 2,
								).ofObject(),
							),
							go.GraphObject.make(go.Shape, {
								toArrow: 'OpenTriangle',
								stroke: '#00aaff',
								strokeWidth: 8,
							}),
						),
					},
				]),
				nodeTemplate: legalEntityNode(),
				groupTemplateMap: new go.Map([
					{ key: '', value: standaloneLegalEntities() },
					{ key: 'Legend', value: orgChartLegendBody() },
				]),
			},
			diagramListeners,
		);
		setupDiagramEvents(diagram);

		diagram.toolManager.mouseWheelBehavior = go.WheelMode.Zoom;
		// diagram.routers.add(new AvoidsLinksRouter());

		return diagram;
	}, [diagramListeners]);

	return (
		<T4Diagram
			divClassName="orgchart-gojs-diagram"
			style={{
				background: 'white',
			}}
			initDiagram={initDiagram}
			nodeDataArray={nodeDataArray}
			linkDataArray={linkDataArray}
		/>
	);
});
