import {
	Binding,
	GraphObject,
	Margin,
	Panel,
	Picture,
	Spot,
	Stretch,
	TextBlock,
	Wrap,
} from 'gojs';
import { nodeBody } from '../../_shared/_templates/node';
import { nodeRow } from '../../_shared/_templates/nodeRow';
import { textColor } from '../../_shared/constants';
import { getSvgIconForAccountType } from '../_utilities/getSvgIcon';

export function accountNode(treeExpanderBuilder?: string) {
	const tableTopMargin = new Margin(8, 8, 8, 8);
	const tableSeparatorPadding = new Margin(4, 0, 0, 0);

	const createSubHeader = () => {
		return new Panel(Panel.Table, {
			margin: tableTopMargin,
			defaultSeparatorPadding: tableSeparatorPadding,
			stretch: Stretch.Horizontal,
		}).add(
			new TextBlock('', {
				name: 'accountCodeURL',
				alignment: Spot.Left,
				row: 0,
				column: 0,
				columnSpan: 2,
				width: 300,
				wrap: Wrap.Fit,
				isUnderline: true,
				cursor: 'pointer',
				stroke: textColor,
				click: (_: any, obj: any) => {
					const id = obj.part.data.id || obj.part.data.entityId;
					const parentId =
						obj.part.data.parentId || obj.part.data.parentEntityId;

					if (obj.part.data.isSubaccount) {
						window.open('accounts/' + parentId + '/subaccounts/' + id);
					} else {
						window.open('accounts/' + id + '/information');
					}
				},
			}).bind('text', 'accountCode'),
			new TextBlock('', {
				alignment: Spot.Left,
				stretch: Stretch.Horizontal,
				row: 1,
				column: 0,
				stroke: textColor,
				width: 300,
				wrap: Wrap.Fit,
			}).bind('text', '', (data) => (data.purpose ? data.purpose : '-')),
			new Picture(undefined, {
				alignment: Spot.TopRight,
				width: 16,
				height: 16,
				column: 2,
				row: 1,
				toolTip: GraphObject.make(
					'ToolTip',
					GraphObject.make(
						TextBlock,
						{ margin: 4 },
						new Binding('text', 'accountType'),
					),
				),
			})
				.bind('source', 'accountType', getSvgIconForAccountType)
				.bind('visible', '', (data) => {
					return data.isAccountTypeVisible ?? false;
				}),

			new Picture(getSvgIconForAccountType('public'), {
				alignment: Spot.TopRight,
				column: 3,
				row: 1,
				width: 16,
				height: 16,
				toolTip: GraphObject.make(
					'ToolTip',
					GraphObject.make(TextBlock, { margin: 4 }, 'Foreign Account'),
				),
			}).bind('visible', '', (data) => {
				return data.isForeignAccount && data.isAccountTypeVisible;
			}),
		);
	};

	const createSubHeader2 = () => {
		const subHeader2TitleFont = '14px Roboto, Medium';
		const subHeader2TitleColor = '#6F7172';
		const subHeader2ItemFont = '14px Roboto, Regular';
		const subHeader2ItemColor = '#404142';

		return new Panel('Table', {
			margin: tableTopMargin,
			defaultSeparatorPadding: tableSeparatorPadding,
			stretch: Stretch.Horizontal,
			background: 'white',
		}).add(
			new TextBlock({
				text: 'Counterparty',
				column: 0,
				font: subHeader2TitleFont,
				stroke: subHeader2TitleColor,
			}),
			new TextBlock({
				row: 1,
				column: 0,
				font: subHeader2ItemFont,
				stroke: subHeader2ItemColor,
			}).bind('text', '', function (data) {
				return data.counterparty || '-';
			}),
			new TextBlock('Acct Currency', {
				column: 1,
				font: subHeader2TitleFont,
				stroke: subHeader2TitleColor,
			}),
			new TextBlock({
				row: 1,
				column: 1,
				font: subHeader2ItemFont,
				stroke: subHeader2ItemColor,
			}).bind('text', '', function (data) {
				return data.accountCurrencyCode || '-';
			}),
			new TextBlock('Acct Number', {
				column: 2,
				font: subHeader2TitleFont,
				stroke: subHeader2TitleColor,
			}),
			new TextBlock({
				row: 1,
				column: 2,
				font: subHeader2ItemFont,
				stroke: subHeader2ItemColor,
			}).bind('text', '', function (data) {
				return data.naturalAccountNumber || '-';
			}),
		);
	};

	const createMainTable = () => {
		let row = 0;

		return [
			new Panel(Panel.Table, {
				margin: tableTopMargin,
				defaultSeparatorPadding: tableSeparatorPadding,
				alignment: Spot.Left,
			}).add(
				nodeRow(row++, 'Account Status', 'accountStatus').bind(
					'visible',
					'',
					(data) => data.visibleFields.includes('accountStatus'),
				),
				nodeRow(row++, 'Open Date', 'openDate').bind('visible', '', (data) =>
					data.visibleFields.includes('openDate'),
				),
				nodeRow(row++, 'Close Date', 'closeDate').bind('visible', '', (data) =>
					data.visibleFields.includes('closeDate'),
				),
				nodeRow(row++, 'Account Type', 'accountType').bind(
					'visible',
					'',
					(data) => data.visibleFields.includes('accountType'),
				),
				nodeRow(row++, 'Account Purpose', 'purpose').bind(
					'visible',
					'',
					(data) => data.visibleFields.includes('purpose'),
				),
				nodeRow(row++, 'Counterparty Branch', 'counterpartyBranch').bind(
					'visible',
					'',
					(data) => data.visibleFields.includes('counterpartyBranch'),
				),
				nodeRow(row++, 'Entity Region', 'entityRegion').bind(
					'visible',
					'',
					(data) => data.visibleFields.includes('entityRegion'),
				),
				nodeRow(row++, 'ERP Code', 'entityErpCode').bind(
					'visible',
					'',
					(data) => data.visibleFields.includes('entityErpCode'),
				),
				nodeRow(row++, 'GL Number', 'generalLedgerAccountNumber').bind(
					'visible',
					'',
					(data) => data.visibleFields.includes('generalLedgerAccountNumber'),
				),
			),
		];
	};

	return nodeBody(
		'entityName',
		createSubHeader,
		createSubHeader2,
		createMainTable,
		treeExpanderBuilder ?? null,
		'entities',
	);
}
