import { Account } from 'modules/clients/apiGateway/entity4/accounts';
import { Counterparty } from 'modules/clients/apiGateway/entity4/counterparties';
import { LegalEntity } from 'modules/clients/apiGateway/entity4/legalentities';
import { Partner } from 'modules/clients/apiGateway/entity4/partners';
import { FinancialInstitution } from 'modules/clients/apiGateway/financialInstitutions';
import {
	PaymentBank,
	PaymentParty,
} from 'modules/clients/apiGateway/payments4/payments';
import { PaymentTemplateStatusTypes } from 'modules/clients/apiGateway/payments4/paymentTemplates';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';

export type CounterpartyWithAccounts = Counterparty & {
	accounts: Account[];
};

export type PayeeTypes = 'Entity' | 'Partner' | 'Counterparty';

export const getPaymentTemplateStatusText = (code: string | null) => {
	switch (code) {
		case PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Created]:
			return 'Pending Approval';
		case PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Draft]:
			return 'Updates Pending';
		case PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Archived]:
			return 'Previous Version';
		default:
			return code;
	}
};

/**
 * Returns list of accounts grouped by their heldWithCounterparty
 */
export const groupAccountsByCounterparties = (accounts: Account[]) => {
	return Object.values(
		accounts.reduce(
			(
				counterparties: { [key: string]: CounterpartyWithAccounts },
				account,
			) => {
				if (account.heldWithCounterparty !== null) {
					(
						counterparties[account.heldWithCounterparty!.id] ||
						(counterparties[account.heldWithCounterparty!.id] = {
							...account.heldWithCounterparty!,
							accounts: [],
						})
					).accounts.push(account);
				}

				return counterparties;
			},
			{},
		),
	).sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
};

/**
 * Checks if counterparty is enabled for wire payments
 */
export const isCounterpartyWirePaymentEnabled = (
	counterparty: CounterpartyWithAccounts,
	financialInstitutions: FinancialInstitution[],
) => {
	if (!isStringUndefinedOrNullOrWhitespace(counterparty.bankCode))
		return (financialInstitutions ?? []).some(
			(fi) => fi.bankCode === counterparty.bankCode && fi.isWirePaymentsEnabled,
		);

	return false;
};

export type PartyValueChange = {
	field: string;
	prev: string | null;
	current: string | null;
};
export const diffEntity = (
	original: PaymentParty,
	legalEntity: LegalEntity,
): PartyValueChange[] => {
	let changes: PartyValueChange[] = [];
	if (original.entityId !== legalEntity.id)
		changes.push({
			field: 'Id',
			prev: original.entityId,
			current: legalEntity.id,
		});

	if (original.name !== legalEntity.anglicizedLegalName)
		changes.push({
			field: 'Anglicized Legal Name',
			prev: original.name,
			current: legalEntity.anglicizedLegalName,
		});

	if (original.displayName !== legalEntity.displayName)
		changes.push({
			field: 'Display Name',
			prev: original.displayName,
			current: legalEntity.displayName,
		});

	if (original.countryCode !== legalEntity.operatingAddress?.countryCode)
		changes.push({
			field: 'Operating Address Country Code',
			prev: original.countryCode,
			current: legalEntity.operatingAddress?.countryCode ?? null,
		});

	return changes;
};

export const diffPartner = (
	original: PaymentParty,
	partner: Partner,
): PartyValueChange[] => {
	let changes: PartyValueChange[] = [];
	if (original.entityId !== partner.id)
		changes.push({
			field: 'Id',
			prev: original.entityId,
			current: partner.id,
		});

	if (original.name !== partner.anglicizedLegalName)
		changes.push({
			field: 'Anglicized Legal Name',
			prev: original.name,
			current: partner.anglicizedLegalName,
		});

	if (original.displayName !== partner.displayName)
		changes.push({
			field: 'Display Name',
			prev: original.displayName,
			current: partner.displayName,
		});

	if (original.countryCode !== partner.operatingAddress?.countryCode)
		changes.push({
			field: 'Operating Address Country Code',
			prev: original.countryCode,
			current: partner.operatingAddress?.countryCode ?? null,
		});

	return changes;
};

export const diffCounterparty = (
	original: PaymentBank,
	counterparty: Counterparty,
): PartyValueChange[] => {
	let changes: PartyValueChange[] = [];
	if (original.entityId !== counterparty.id)
		changes.push({
			field: 'Id',
			prev: original.entityId,
			current: counterparty.id,
		});

	if (original.name !== counterparty.anglicizedLegalName)
		changes.push({
			field: 'Anglicized Legal Name',
			prev: original.name,
			current: counterparty.anglicizedLegalName,
		});

	if (original.displayName !== counterparty.displayName)
		changes.push({
			field: 'Display Name',
			prev: original.displayName,
			current: counterparty.displayName,
		});

	if (original.bankCode !== counterparty.bankCode)
		changes.push({
			field: 'Bank Code',
			prev: original.bankCode,
			current: counterparty.bankCode,
		});

	if (original.aba !== counterparty.routingCodes.highValueClearing)
		changes.push({
			field: 'High Value Clearing Code',
			prev: original.aba,
			current: counterparty.routingCodes.highValueClearing,
		});

	if (original.countryCode !== counterparty.operatingAddress?.countryCode)
		changes.push({
			field: 'Operating Address Country Code',
			prev: original.countryCode,
			current: counterparty.operatingAddress?.countryCode ?? null,
		});

	return changes;
};

export const diffAccount = (
	type: 'Initiator' | 'Payee',
	original: PaymentParty,
	account: Account,
): PartyValueChange[] => {
	let changes: PartyValueChange[] = [];
	if (original.accountId !== account.id)
		changes.push({
			field: 'Id',
			prev: original.accountId,
			current: account.id,
		});

	if (original.accountName !== account.shortName)
		changes.push({
			field: 'Short Name',
			prev: original.accountName,
			current: account.shortName,
		});

	if (original.accountDisplayName !== account.displayName)
		changes.push({
			field: 'Display Name',
			prev: original.accountNumber,
			current: account.accountNumbers.pain001,
		});

	if (
		type === 'Initiator' &&
		original.accountNumber !== account.accountNumbers.pain001
	)
		changes.push({
			field: 'PAIN 001 Number',
			prev: original.accountNumber,
			current: account.accountNumbers.pain001,
		});

	if (
		type === 'Payee' &&
		original.accountNumber !== account.accountNumbers.naturalAccountNumber
	)
		changes.push({
			field: 'Natural Account Number',
			prev: original.accountNumber,
			current: account.accountNumbers.naturalAccountNumber,
		});

	return changes;
};
