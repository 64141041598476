import { Card, Grid, RadioGroup, Typography } from '@mui/material';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { VisualizationRadio } from 'features/entity4/visualizations/_shared/_components/visualizationRadio';
import { observer } from 'mobx-react-lite';
import { WidgetControl } from 'modules/clients/customer-api/src/sigmaEmbed';
import { Moment } from 'moment';
import { FC, useState } from 'react';
import T4DateRangePicker from 'shared/components/dateRangePicker/t4DateRangePicker';

export type FilterPaneProps = {
	open: boolean;
	setOpen: (open: boolean) => void;
	layout: ReactGridLayout.Layout[];
	controls: WidgetControl[];
};

export const FilterPane: FC<FilterPaneProps> = observer(
	({ open, setOpen, layout, controls }) => {
		const [startDate, setStartDate] = useState<Moment | null>(null);
		const [endDate, setEndDate] = useState<Moment | null>(null);
		const [colorControl, setColorControl] = useState<string>('');
		const [groupControl, setGroupControl] = useState<string>('');

		const applyDateFilter = () => {
			layout.forEach((element) => {
				const sigma_iframe = document.getElementById(
					element.i,
				) as HTMLIFrameElement | null;

				const date =
					'min:' +
					startDate?.format('YYYY-MM-DD') +
					'T00:00:00Z,max:' +
					endDate?.format('YYYY-MM-DD') +
					'T23:59:59Z';

				try {
					sigma_iframe?.contentWindow?.postMessage(
						{
							type: 'workbook:variables:update',
							variables: {
								Date_Control: date,
							},
						},
						'https://app.sigmacomputing.com',
					);
				} catch (e) {
					console.error(e);
				}
			});
		};

		const applyColorFilter = () => {
			layout.forEach((element) => {
				const sigma_iframe = document.getElementById(
					element.i,
				) as HTMLIFrameElement | null;

				try {
					sigma_iframe?.contentWindow?.postMessage(
						{
							type: 'workbook:variables:update',
							variables: {
								Color_Control: colorControl,
							},
						},
						'https://app.sigmacomputing.com',
					);
				} catch (e) {
					console.error(e);
				}
			});
		};

		const applyGroupFilter = () => {
			layout.forEach((element) => {
				const sigma_iframe = document.getElementById(
					element.i,
				) as HTMLIFrameElement | null;
				try {
					sigma_iframe?.contentWindow?.postMessage(
						{
							type: 'workbook:variables:update',
							variables: {
								Group_Control: groupControl,
							},
						},
						'https://app.sigmacomputing.com',
					);
				} catch (e) {
					console.error(e);
				}
			});
		};

		return (
			<Card
				sx={(theme) => ({
					position: 'absolute',
					elevation: 600,
					zIndex: 1,
					top: 112,
					right: 24,
					width: 'auto',
					height: 'auto',
					display: open ? 'block' : 'none',
				})}
			>
				<Grid container spacing={2} sx={{ padding: 2 }}>
					{controls.some((control) => control.name === 'Date_Control') && (
						<Grid item xs={12}>
							<T4DateRangePicker
								id="dashbaord-date-filter"
								defaultValue={[startDate, endDate]}
								onAccept={([startDate, endDate]) => {
									if (
										startDate &&
										startDate.isValid() &&
										endDate &&
										endDate.isValid() &&
										startDate.isSameOrBefore(endDate, 'day')
									) {
										if (setStartDate) {
											setStartDate(startDate);
										}
										if (setEndDate) {
											setEndDate(endDate);
										}
									}
								}}
								disableFuture
								showShortcuts
							/>
						</Grid>
					)}
					{controls.some((control) => control.name === 'Color_Control') && (
						<Grid item xs={6}>
							<Typography
								variant="h6"
								sx={{
									fontWeight: 'bold',
								}}
							>
								Color by
							</Typography>
							<RadioGroup
								value={colorControl}
								onChange={(event) => {
									if (setColorControl) {
										setColorControl(event.target.value);
									}
								}}
							>
								<VisualizationRadio value="Bank" label="Bank" />
								<VisualizationRadio value="Currency" label="Currency" />
								<VisualizationRadio value="Region" label="Region" />
								<VisualizationRadio value="Account Type" label="Account Type" />
								<VisualizationRadio
									value="Account Status"
									label="Account Status"
								/>
								<VisualizationRadio value="Entity" label="Entity" />
							</RadioGroup>
						</Grid>
					)}
					{controls.some((control) => control.name === 'Group_Control') && (
						<Grid item xs={6}>
							<Typography
								variant="h6"
								sx={{
									fontWeight: 'bold',
								}}
							>
								Group by
							</Typography>
							<RadioGroup
								value={groupControl}
								onChange={(event) => {
									if (setGroupControl) {
										setGroupControl(event.target.value);
									}
								}}
							>
								<VisualizationRadio value="Bank" label="Bank" />
								<VisualizationRadio value="Currency" label="Currency" />
								<VisualizationRadio value="Region" label="Region" />
								<VisualizationRadio value="Account Type" label="Account Type" />
								<VisualizationRadio
									value="Account Status"
									label="Account Status"
								/>
								<VisualizationRadio value="Entity" label="Entity" />
							</RadioGroup>
						</Grid>
					)}
					<Grid item xs="auto">
						<T4Button
							onClick={() => {
								setOpen(false);
								if (startDate && endDate) {
									applyDateFilter();
								}
								if (colorControl) {
									applyColorFilter();
								}
								if (groupControl) {
									applyGroupFilter();
								}
							}}
						>
							APPLY FILTERS
						</T4Button>
					</Grid>
				</Grid>
			</Card>
		);
	},
);
