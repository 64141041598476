import {
	GridColDef,
	GridFilterOperator,
	GridRenderCellParams,
	GridSingleSelectColDef,
	GridValidRowModel,
	GridValueFormatterParams,
	GridValueGetterParams,
	getGridSingleSelectOperators,
} from '@mui/x-data-grid-pro';
import {
	MultiSelectFilterInput,
	SingleSelectFilterInput,
} from 'shared/components/dataGrid/filterInputs';
import { Option, ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { convertDate, formatDateTime, formatReadDate } from '../dateUtilities';

const defaultDecimalDigits = 8;

export const getDecimalColumnDefinition = (
	minFractionDigits?: number,
	maxFractionDigits?: number,
): Partial<GridColDef> => {
	return {
		type: 'number',
		align: 'right',
		headerAlign: 'right',
		valueFormatter: (params: GridValueFormatterParams) => params.value,
		renderCell: (params: GridRenderCellParams) => {
			const value = Number(params.value);
			const minDigits = minFractionDigits ?? defaultDecimalDigits;
			const maxDigits = maxFractionDigits ?? defaultDecimalDigits;
			const formattedValue = new Intl.NumberFormat('dafault', {
				style: 'decimal',
				minimumFractionDigits: minDigits,
				maximumFractionDigits: maxDigits,
			}).format(value);
			return isNaN(value) ? '' : formattedValue;
		},
	};
};

export const getPercentColumnDefinition = (
	minFractionDigits?: number,
	maxFractionDigits?: number,
): Partial<GridColDef> => {
	return {
		type: 'number',
		align: 'right',
		headerAlign: 'right',
		valueFormatter: (params) => {
			const value = Number(params.value);
			return isNaN(value) ? '' : params.value * 100;
		},
		renderCell: (params) => {
			const value = Number(params.value);
			const minDigits = minFractionDigits ?? 2;
			const maxDigits = maxFractionDigits ?? 2;
			const formattedValue = new Intl.NumberFormat('default', {
				style: 'percent',
				minimumFractionDigits: minDigits,
				maximumFractionDigits: maxDigits,
			}).format(value);
			return isNaN(value) ? '' : formattedValue;
		},
	};
};

export function getCurrencyColumnDefinition<
	TRow extends GridValidRowModel,
>(): Partial<GridColDef<TRow>> {
	return {
		headerAlign: 'right',
		align: 'right',
		type: 'number',
		valueFormatter: (params) => params.value,
	};
}

export const getDateColumnDefinition = (
	readDateFormat?: string,
): Partial<GridColDef> => {
	return {
		minWidth: 110,
		type: 'date',
		valueGetter: (params: GridValueGetterParams) => {
			return convertDate(params.value)?.toDate();
		},
		valueFormatter: (params: GridValueFormatterParams) => {
			// returning undefined or null will cause the value getter to provide the string
			var value = convertDate(params.value);
			if (value === null || value === undefined) return undefined;

			if (value.year() >= 9999) return '';

			return formatReadDate(value, readDateFormat);
		},
		getApplyQuickFilterFn: (value) => {
			if (!value) return null;

			return (params) => {
				const dateValue = convertDate(params?.value) ?? undefined;

				return (
					formatReadDate(dateValue, readDateFormat)
						?.toLowerCase()
						?.includes(value?.toLowerCase()) ?? false
				);
			};
		},
		sortComparator: (v1: Date, v2: Date) => {
			if (v1 < v2) return -1;
			if (v1 > v2) return 1;
			return 0;
		},
	};
};

export const getFormattedDateTimeColumnDefinition = (
	readDateFormat?: string,
): Partial<GridColDef> => {
	return {
		minWidth: 110,
		type: 'dateTime',
		valueGetter: (params: GridValueGetterParams) => {
			return convertDate(params.value);
		},
		valueFormatter: (params: GridValueFormatterParams) => {
			return formatDateTime(params.value);
		},
		getApplyQuickFilterFn: (value) => {
			if (!value) return null;

			return (params) => {
				const dateValue = convertDate(params?.value) ?? undefined;

				return (
					formatReadDate(dateValue, readDateFormat)
						?.toLowerCase()
						?.includes(value?.toLowerCase()) ?? false
				);
			};
		},
	};
};

export const getOptionColumnDefinition = (): Partial<GridColDef> => {
	return {
		type: 'singleSelect',
		valueGetter: (params: GridValueGetterParams) => {
			return params.value as Option;
		},
		valueFormatter: (params: GridValueFormatterParams<Option>) => {
			if (params.value === null || params.value === undefined) {
				return undefined;
			}

			return params.value.displayName;
		},
	};
};

export const getReferenceColumnDefinition = (): Partial<GridColDef> => {
	return {
		type: 'singleSelect',
		valueGetter: (params: GridValueGetterParams) => {
			return params.value as ReferenceDataValue;
		},
		valueFormatter: (params: GridValueFormatterParams<ReferenceDataValue>) => {
			if (params.value === null || params.value === undefined) {
				return undefined;
			}

			return params.value.displayName;
		},
	};
};

export const getBooleanColumnDefinition = (
	valueFormat: 'default' | 'string' = 'default',
): Partial<GridColDef> => {
	if (valueFormat === 'string') {
		return {
			type: 'string',
			valueFormatter: (params: GridValueFormatterParams<boolean>) =>
				params.value === true ? 'Yes' : 'No',
		};
	} else
		return {
			type: 'boolean',
		};
};

export function getSingleSelectGridColDef<
	TRow extends GridValidRowModel = any,
	TValue = any,
	TFormattedValue = TValue,
>(
	options?: string[],
): Partial<GridSingleSelectColDef<TRow, TValue, TFormattedValue>> {
	const SINGLE_SELECT_COL_BASE: Partial<
		GridSingleSelectColDef<TRow, TValue, TFormattedValue>
	> = {
		type: 'singleSelect',
	};

	if (options) {
		const wrapOperator = (operator: GridFilterOperator) => {
			const InputComponent =
				operator.value === 'isAnyOf'
					? MultiSelectFilterInput
					: SingleSelectFilterInput;
			return {
				...operator,
				InputComponent,
			};
		};

		return {
			...SINGLE_SELECT_COL_BASE,
			valueOptions: options,
			filterOperators: getGridSingleSelectOperators().map((x) =>
				wrapOperator(x),
			) as GridFilterOperator<TRow, TValue, TFormattedValue>[],
		};
	}

	return SINGLE_SELECT_COL_BASE;
}

export function getMultiSelectGridColDef<
	TRow extends GridValidRowModel = any,
	TValue = any,
	TFormattedValue = TValue,
>(
	options?: string[],
	strict: boolean = false,
): Partial<GridSingleSelectColDef<TRow, TValue, TFormattedValue>> {
	return {
		...getSingleSelectGridColDef(options),
		filterOperators: [
			...getGridSingleSelectOperators().map((x) => {
				const shouldBail = (value: any): boolean =>
					!value || (typeof value === 'string' && value.trim() === '');

				if (x.value === 'is') {
					x.getApplyFilterFn = (filterItem) => {
						if (shouldBail(filterItem.value)) return null;

						return ({ value }) => {
							if (strict) {
								if (value && typeof value === 'string') {
									const values = value.split(', ');

									return values.some((x) => x === filterItem.value);
								}
							}

							return value.includes(filterItem.value);
						};
					};
					x.InputComponent = SingleSelectFilterInput;
				} else if (x.value === 'not') {
					x.getApplyFilterFn = (filterItem) => {
						if (shouldBail(filterItem.value)) return null;

						return ({ value }) => {
							if (strict) {
								if (value && typeof value === 'string') {
									const values = value.split(', ');

									return !values.some((x) => x === filterItem.value);
								}
							}

							return !value.includes(filterItem.value);
						};
					};
					x.InputComponent = SingleSelectFilterInput;
				} else if (x.value === 'isAnyOf') {
					x.getApplyFilterFn = (filterItem) => {
						if (shouldBail(filterItem.value)) return null;

						return ({ value }) => {
							if (strict) {
								if (value && typeof value === 'string') {
									const values = value.split(', ');

									return values.some((x) => filterItem.value.includes(x));
								}
							}

							return filterItem.value.some((x: any) => {
								return value.includes(filterItem.value);
							});
						};
					};
					x.InputComponent = MultiSelectFilterInput;
				}

				return x;
			}),
		],
	};
}
