import {
	nodeBody,
	NodeBodyData,
} from 'features/entity4/visualizations/_shared/_templates/node';
import { nodeRow } from 'features/entity4/visualizations/_shared/_templates/nodeRow';
import { textColor } from 'features/entity4/visualizations/_shared/constants';
import {
	ImageStretch,
	Margin,
	Panel,
	Picture,
	Size,
	Spot,
	Stretch,
	TextBlock,
	Wrap,
} from 'gojs';

export type LegalEntityNodeData = NodeBodyData & {
	key: string;

	entityId: string;
	entityCode: string | undefined;
	anglicizedLegalName: string | undefined;
	country: string | undefined;
	entityStatus: string | undefined;
	entityRegion: string | undefined;
	functionalCurrencyCode: string | undefined;
	incorporatedDate: string | undefined;
	erpCode: string | undefined;
	erpPlatform: string | undefined;
	acquiredCompany: string | undefined;
	acquisitionDate: string | undefined;
	dissolutionDate: string | undefined;
	leiIdentifier: string | undefined;
	stateProvince: string | undefined;
	registrationNumber: string | undefined;
	taxIdNumber: string | undefined;
	taxIdCountry: string | undefined;
	formOfOrganization: string | undefined;

	isFlagVisible: boolean;
	visibleFields: string[];
};

export function legalEntityNode() {
	const nodeWidth = 400;
	const flagHeight = 35;
	const flagWidth = 50;
	const entityNameWidth = nodeWidth - flagWidth - 16 - 8;

	function subHeader() {
		return new Panel(Panel.Horizontal, {
			margin: new Margin(8, 8, 8, 8),
			defaultAlignment: Spot.Left,
			width: nodeWidth,
		}).add(
			new Picture({
				name: 'flagv',
				imageStretch: ImageStretch.Fill,
				imageAlignment: Spot.Left,
				margin: new Margin(0, 8, 0, 8),
				height: flagHeight,
				width: flagWidth,
			})
				.bind(
					'visible',
					'',
					(data: LegalEntityNodeData) => !!data.isFlagVisible,
				)
				.bind(
					'source',
					'',
					(data: LegalEntityNodeData) =>
						window.location.origin +
						'/svg/' +
						(data.country || 'not_found') +
						'.svg',
				),
			new TextBlock({
				alignment: Spot.Left,
				alignmentFocus: Spot.Left,
				wrap: Wrap.DesiredSize,
				maxSize: new Size(entityNameWidth, NaN),
				cursor: 'pointer',
				font: '16pt "Roboto","Helvetica","Arial",sans-serif',
				stroke: textColor,
				isUnderline: true,
				click: (_, obj) => {
					window.open(
						'entities/' +
							(obj.part?.data as LegalEntityNodeData).entityId +
							'/information',
					);
				},
			}).bind(
				'text',
				'',
				(data: LegalEntityNodeData) => data.anglicizedLegalName ?? '',
			),
		);
	}

	function mainTable() {
		let row = 0;

		return [
			new Panel(Panel.Table, {
				stretch: Stretch.Horizontal,
				margin: new Margin(8, 8, 8, 8),
				defaultSeparatorPadding: new Margin(2, 0, 2, 0),
			}).add(
				nodeRow(row++, 'Entity Status', 'entityStatus').bind(
					'visible',
					'',
					(data: LegalEntityNodeData) =>
						data.visibleFields.includes('entityStatus'),
				),
				nodeRow(row++, 'Entity Region', 'entityRegion').bind(
					'visible',
					'',
					(data: LegalEntityNodeData) =>
						data.visibleFields.includes('entityRegion'),
				),
				nodeRow(row++, 'Functional Currency', 'functionalCurrencyCode').bind(
					'visible',
					'',
					(data: LegalEntityNodeData) =>
						data.visibleFields.includes('functionalCurrencyCode'),
				),
				nodeRow(row++, 'Incorporated Date', 'incorporatedDate').bind(
					'visible',
					'',
					(data: LegalEntityNodeData) =>
						data.visibleFields.includes('incorporatedDate'),
				),
				nodeRow(row++, 'ERP Code', 'erpCode').bind(
					'visible',
					'',
					(data: LegalEntityNodeData) => data.visibleFields.includes('erpCode'),
				),
				nodeRow(row++, 'ERP Platform', 'erpPlatform').bind(
					'visible',
					'',
					(data: LegalEntityNodeData) =>
						data.visibleFields.includes('erpPlatform'),
				),
				nodeRow(row++, 'Acquired Company', 'acquiredCompany', (data) => {
					if (!data.acquiredCompany) {
						return '-';
					} else if (data.acquiredCompany === 'True') {
						return 'Yes';
					} else if (data.acquiredCompany === 'False') {
						return 'No';
					} else return 'Unknown';
				}).bind('visible', '', (data: LegalEntityNodeData) =>
					data.visibleFields.includes('acquiredCompany'),
				),
				nodeRow(row++, 'Acquisition Date', 'acquisitionDate').bind(
					'visible',
					'',
					(data: LegalEntityNodeData) =>
						data.visibleFields.includes('acquisitionDate'),
				),
				nodeRow(row++, 'Dissolution Date', 'dissolutionDate').bind(
					'visible',
					'',
					(data: LegalEntityNodeData) =>
						data.visibleFields.includes('dissolutionDate'),
				),
				nodeRow(row++, 'LEI Identifier', 'leiIdentifier').bind(
					'visible',
					'',
					(data: LegalEntityNodeData) =>
						data.visibleFields.includes('leiIdentifier'),
				),
				nodeRow(row++, 'Home Registration', 'stateProvince').bind(
					'visible',
					'',
					(data: LegalEntityNodeData) =>
						data.visibleFields.includes('stateProvince'),
				),
				nodeRow(row++, 'Registration Number', 'registrationNumber').bind(
					'visible',
					'',
					(data: LegalEntityNodeData) =>
						data.visibleFields.includes('registrationNumber'),
				),
				nodeRow(row++, 'Tax ID Number', 'taxIdNumber').bind(
					'visible',
					'',
					(data: LegalEntityNodeData) =>
						data.visibleFields.includes('taxIdNumber'),
				),
				nodeRow(row++, 'Tax Id Country', 'taxIdCountry').bind(
					'visible',
					'',
					(data: LegalEntityNodeData) =>
						data.visibleFields.includes('taxIdCountry'),
				),
				nodeRow(row++, 'Form of Organization', 'formOfOrganization').bind(
					'visible',
					'',
					(data: LegalEntityNodeData) =>
						data.visibleFields.includes('formOfOrganization'),
				),
			),
		];
	}

	return nodeBody(
		'entityCode',
		subHeader,
		null,
		mainTable,
		'TreeExpanderButton',
		'entities',
		{
			nodeWidth: nodeWidth,
		},
	);
}
