import { ColorResult, TwitterPicker } from '@hello-pangea/color-picker';
import { Add, Delete, Save, Tune, Visibility } from '@mui/icons-material';
import { TabContext, TabList } from '@mui/lab';
import {
	Button,
	FormControl,
	FormGroup,
	FormLabel,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	RadioGroup,
	Tab,
} from '@mui/material';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { AsOfDatePicker } from 'features/entity4/visualizations/_shared/_components/asOfDatePicker';
import { ExportButton } from 'features/entity4/visualizations/_shared/_components/exportButton';
import { SearchTextField } from 'features/entity4/visualizations/_shared/_components/searchTextField';
import { VisualizationCheckbox } from 'features/entity4/visualizations/_shared/_components/visualizationCheckbox';
import { VisualizationHelpModal } from 'features/entity4/visualizations/_shared/_components/visualizationHelpModal';
import { VisualizationOptionLabel } from 'features/entity4/visualizations/_shared/_components/visualizationOptionLabel';
import { VisualizationRadio } from 'features/entity4/visualizations/_shared/_components/visualizationRadio';
import { VisualizationTabPanel } from 'features/entity4/visualizations/_shared/_components/visualizationTabPannel';
import { VisualizationView } from 'features/entity4/visualizations/_shared/_components/visualizationView';
import go from 'gojs';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { stonlyData } from 'stonly/functions';
import { orgChartPrefix } from 'stonly/pagePrefixes';
import {
	AllChartOptions,
	AsOfDateKey,
	CardOptionsDisplayKey,
	CardOptionUserDefinedColorKey,
	ChartOptionsMap,
	DisplayFlagKey,
	InformationOptionsDisplayColumnOne,
	InformationOptionsDisplayColumnTwo,
	InformationOptionsMap,
} from '../../models/orgChartTypes';
import { useOrgChart } from '../_providers/orgChartProvider';

export const DisplayAuxiliaryNodesKey = 'displayAuxiliaryNodes';

type TabValue = 'chart-options' | 'card-options' | 'information-options';

export type OrgChartHeaderComponentProps = {
	diagram: go.Diagram | undefined;
};

export const OrgChartHeaderComponent: FC<OrgChartHeaderComponentProps> =
	observer(({ diagram }) => {
		const {
			asOfDate,
			optionPreferences,
			updatePreferences,
			views,
			createView,
			selectView,
			overwriteView,
			deleteView,
		} = useOrgChart();

		const [searchValue, setSearchValue] = useState<string>('');
		const [date, setDate] = useState<Moment | undefined>();

		const [viewsMenuOpen, setViewsMenuOpen] = useState<boolean>(false);
		const [viewAchorEl, setViewAnchorEl] = useState<null | HTMLElement>(null);
		const [newViewName, setNewViewName] = useState<string>();
		const [anchorOptionsEl, setAnchorOptionsEl] = useState<null | HTMLElement>(
			null,
		);
		const [tab, setTab] = useState<TabValue>('chart-options');
		const [displayColorPicker, setDisplayColorPicker] =
			useState<boolean>(false);

		const openOptions = useMemo(
			() => Boolean(anchorOptionsEl),
			[anchorOptionsEl],
		);

		const handleOptionsClose = useCallback(() => {
			setAnchorOptionsEl(null);
		}, []);

		const isOptionChecked = useCallback(
			(optionKey: string) => {
				return !(
					optionPreferences.find((x) => x.optionId === optionKey)?.hide ?? false
				);
			},
			[optionPreferences],
		);

		//#region Handle user inpurt

		const handleOptionPreferencesChange = useCallback(
			async (optionChanged: string, isEnabled: boolean) => {
				if (optionChanged === DisplayAuxiliaryNodesKey) {
					updatePreferences({
						options: [
							{
								id: optionChanged,
								optionId: optionChanged,
								hide: !isEnabled,
							},
						],
					});
				} else {
					await updatePreferences({
						options: [
							{
								id: optionChanged,
								optionId: optionChanged,
								hide: !isEnabled,
							},
						],
					});
				}
			},
			[updatePreferences],
		);

		const handleColorByPreferenceChange = useCallback(
			async (newValue: string) => {
				updatePreferences({
					options: [
						{
							id: CardOptionsDisplayKey,
							optionId: CardOptionsDisplayKey,
							hide: false,
							value: newValue,
						},
					],
				});
			},
			[updatePreferences],
		);

		const handleUserChosenColorChange = useCallback(
			async (color: ColorResult) => {
				updatePreferences({
					options: [
						{
							id: CardOptionsDisplayKey,
							optionId: CardOptionsDisplayKey,
							hide: false,
							value: 'singleColor',
						},
						{
							id: CardOptionUserDefinedColorKey,
							optionId: CardOptionUserDefinedColorKey,
							hide: false,
							value: color.hex,
						},
					],
				});
			},
			[updatePreferences],
		);

		const onSearchType = useCallback(
			(searchValue: string) => {
				diagram?.commit((d) => {
					if (searchValue) {
						const input = searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
						const regex = new RegExp(input, 'i');
						const nodes = d.findNodesByExample(
							{ entityType: regex },
							{ entityCode: regex },
							{ status: regex },
							{ displayNameShort: regex },
							{ displayName: regex },
							{ entityStatus: regex },
							{ anglicizedLegalName: regex },
							{ shortName: regex },
							{ entityRegion: regex },
							{ functionalCurrencyCode: regex },
							{ erpCode: regex },
							{ erpPlatform: regex },
							{ website: regex },
							{ incorporationDate: regex },
							{ sicCategory: regex },
							{ sicCode: regex },
							{ acquiredCompany: regex },
							{ acquisitionDate: regex },
							{ dunsNumber: regex },
							{ refinitivPermanentIdentifierPermID: regex },
							{ tpinNumber: regex },
							{ leiIdentifier: regex },
							{ leiIssuer: regex },
							{ country: regex },
							{ stateProvince: regex },
							{ location: regex },
							{ dissolutionDate: regex },
							{ taxIdNumber: regex },
							{ taxIdCountry: regex },
							{ formOfOrganization: regex },
						);

						d.highlightCollection(nodes);
					} else {
						d.clearHighlighteds();
					}
				});
			},
			[diagram],
		);

		const handleOnDateAccept = useCallback(
			async (incomingDate: Moment | undefined = date) => {
				const today = moment();

				let dateActual = date ?? today;
				if (
					incomingDate &&
					!incomingDate.isSame(asOfDate, 'day') &&
					today.isSameOrAfter(incomingDate)
				) {
					dateActual = incomingDate;
				} else if (dateActual.isSameOrAfter(today)) {
					dateActual = today;
				}

				setDate(dateActual);
				updatePreferences({
					options: [
						{
							id: AsOfDateKey,
							optionId: AsOfDateKey,
							hide: false,
							value: dateActual.toDate().toISOString(),
						},
					],
					nodes: [],
				});
			},
			[asOfDate, date, updatePreferences],
		);

		const handleOnViewChange = useCallback(
			(viewId: string) => {
				selectView(viewId);
				setViewsMenuOpen(false);
				setViewAnchorEl(null);
			},
			[selectView],
		);

		const handleViewCreate = useCallback(
			(name: string) => {
				createView(name);
				setNewViewName('');
			},
			[createView],
		);

		const handleViewSave = useCallback(
			(viewId: string) => {
				overwriteView(viewId);
			},
			[overwriteView],
		);

		const handleViewDelete = useCallback(
			(viewId: string) => {
				deleteView(viewId);
			},
			[deleteView],
		);

		//#endregion

		useEffect(() => {
			onSearchType(searchValue);
		}, [diagram, onSearchType, searchValue]);

		useEffect(() => {
			setDate(asOfDate);
		}, [asOfDate]);

		return (
			<VisualizationView
				stonlyId={orgChartPrefix}
				title={'Entity Org Chart'}
				headerElements={[
					<Grid container item xs="auto" sx={{ gap: 2, alignItems: 'center' }}>
						<Grid container item xs="auto" sx={{ alignContent: 'center' }}>
							<SearchTextField
								value={searchValue}
								onChange={(event) => {
									const newSearchValue = event.target.value ?? '';

									setSearchValue(newSearchValue);
									onSearchType(newSearchValue);
								}}
							/>
						</Grid>
						<Grid container item xs="auto">
							<AsOfDatePicker
								{...stonlyData({
									id: 'orgchart-asofdate',
								})}
								value={date ?? null}
								onChange={(date) => {
									setDate(date ?? undefined);
								}}
								onAccept={(date) => handleOnDateAccept(date ?? undefined)}
								slotProps={{
									textField: {
										onBlur: () => handleOnDateAccept(),
										inputProps: {
											...stonlyData({
												id: `${orgChartPrefix}-as-of-text-input`,
											}),
										},
										sx: {
											height: '40px',
										},
									},
									shortcuts: {
										items: [
											{
												label: 'End of Previous Month',
												getValue: () => {
													return moment().subtract(1, 'month').endOf('month');
												},
											},
											{
												label: 'End of Previous Quarter',
												getValue: () => {
													return moment()
														.subtract(1, 'quarter')
														.endOf('quarter');
												},
											},
											{
												label: 'End of Previous Year',
												getValue: () => {
													return moment().subtract(1, 'year').endOf('year');
												},
											},
											{
												label: 'Reset',
												getValue: () => {
													return moment();
												},
											},
										],
									},
									openPickerButton: {
										...(stonlyData({
											id: `${orgChartPrefix}-as-of-text-date-picker-button`,
										}) as any),
									},
								}}
							/>
						</Grid>
						<Grid item xs="auto">
							<T4Button
								id="displayOptionsBtn"
								variant="outlined"
								sx={{ background: 'white', height: '40px' }}
								startIcon={<Tune />}
								onClick={(event) => setAnchorOptionsEl(event.currentTarget)}
								{...stonlyData({
									id: `${orgChartPrefix}-display-options-button`,
								})}
							>
								Display Options
							</T4Button>
							<Menu
								id="optionsMenu"
								anchorEl={anchorOptionsEl}
								open={openOptions}
								onClose={handleOptionsClose}
								sx={{
									'& .MuiMenu-list': {
										padding: 0,
									},
								}}
							>
								<Grid
									container
									flexWrap="nowrap"
									sx={{
										padding: '1rem',
										paddingY: '1.5rem',
										width: '800px',
									}}
									{...stonlyData({
										id: `${orgChartPrefix}-display-modal-container`,
									})}
								>
									<TabContext value={tab}>
										<Grid item xs="auto">
											<TabList
												orientation="vertical"
												variant="scrollable"
												onChange={(_, newTab) => setTab(newTab)}
												sx={{ borderRight: 1, borderColor: 'divider' }}
											>
												<Tab
													sx={{ alignItems: 'start' }}
													label="Chart Options"
													value="chart-options"
													{...stonlyData({
														id: `${orgChartPrefix}-display-modal-chart-options`,
													})}
												/>
												<Tab
													sx={{ alignItems: 'start' }}
													label="Card Options"
													value="card-options"
													{...stonlyData({
														id: `${orgChartPrefix}-display-modal-card-options`,
													})}
												/>
												<Tab
													sx={{ alignItems: 'start' }}
													label="Information Options"
													value="information-options"
													{...stonlyData({
														id: `${orgChartPrefix}-display-modal-information-options`,
													})}
												/>
											</TabList>
										</Grid>
										<Grid item xs={true}>
											<VisualizationTabPanel value={'chart-options'}>
												<FormControl>
													<FormGroup>
														{AllChartOptions.map((option) => (
															<VisualizationCheckbox
																key={option}
																label={ChartOptionsMap.get(option)}
																checked={isOptionChecked(option)}
																onChange={(_, checked) =>
																	handleOptionPreferencesChange(option, checked)
																}
															/>
														))}
													</FormGroup>
												</FormControl>
											</VisualizationTabPanel>
											<VisualizationTabPanel value={'card-options'}>
												<FormControl>
													<VisualizationOptionLabel>
														Color
													</VisualizationOptionLabel>
													<RadioGroup
														name="card-options-group"
														value={
															optionPreferences.find(
																(op) => op.optionId === CardOptionsDisplayKey,
															)?.value ?? 'singleColor'
														}
														onChange={(_, newValue) =>
															handleColorByPreferenceChange(newValue)
														}
													>
														<Grid container sx={{ gap: 1 }}>
															<Grid item xs="auto">
																<VisualizationRadio
																	label={'Single Color'}
																	value={'singleColor'}
																/>
															</Grid>
															<Grid
																container
																item
																xs="auto"
																sx={{ alignItems: 'center' }}
															>
																<div
																	style={{
																		background:
																			optionPreferences.find(
																				(op) =>
																					op.optionId ===
																					CardOptionUserDefinedColorKey,
																			)?.value ?? '#E0DFDF',
																		height: 20,
																		width: 20,
																		marginLeft: 6,
																		borderRadius: 6,
																		border: '1.5px #414042 solid',
																	}}
																	onClick={() => setDisplayColorPicker(true)}
																/>
															</Grid>
														</Grid>
														{displayColorPicker ? (
															<div
																style={{
																	position: 'absolute',
																	zIndex: '2',
																	marginTop: 33,
																	marginLeft: 33,
																}}
															>
																<div
																	style={{
																		position: 'fixed',
																		top: '0px',
																		right: '0px',
																		bottom: '0px',
																		left: '0px',
																	}}
																	onClick={() => setDisplayColorPicker(false)}
																/>
																<TwitterPicker
																	color={
																		optionPreferences.find(
																			(op) =>
																				op.optionId ===
																				CardOptionUserDefinedColorKey,
																		)?.value ?? '#E0DFDF'
																	}
																	styles={{
																		card: {
																			boxShadow:
																				'0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
																		},
																		hash: { display: 'none' },
																		input: { display: 'none' },
																		swatch: { border: '1px solid black' },
																	}}
																	triangle="hide"
																	width={205}
																	onChangeComplete={handleUserChosenColorChange}
																	colors={[
																		'#F6A3A2',
																		'#FBC59C',
																		'#E3D69D',
																		'#FBFBB8',
																		'#E5FAC6',
																		'#A6C09A',
																		'#C9F7F4',
																		'#D1EBFD',
																		'#8AA4B8',
																		'#C5A8Ce',
																		'#D7ADD4',
																		'#E6B2BD',
																		'#FBCEE5',
																		'#E0DFDF',
																		'#FFFFFF',
																	]}
																/>
															</div>
														) : null}
														<VisualizationRadio
															label={'Color by Status'}
															value={'entityStatus'}
														/>
														<VisualizationRadio
															label={'Color by Region'}
															value={'entityRegion'}
														/>
													</RadioGroup>
													<FormLabel
														className="options-menu-label"
														sx={{
															marginTop: '20px',
															fontWeight: 'bold',
															fontSize: '0.875rem',
														}}
													>
														Image
													</FormLabel>
													<VisualizationCheckbox
														label={'Country Flag'}
														checked={isOptionChecked(DisplayFlagKey)}
														onChange={(_, checked) =>
															handleOptionPreferencesChange(
																DisplayFlagKey,
																checked,
															)
														}
													/>
												</FormControl>
											</VisualizationTabPanel>
											<VisualizationTabPanel value={'information-options'}>
												<Grid container>
													<Grid container item xs={6}>
														{InformationOptionsDisplayColumnOne.map(
															(optionDisplay, index) => {
																return (
																	<Grid container key={index}>
																		<Grid item xs={12}>
																			<VisualizationOptionLabel>
																				{optionDisplay.title}
																			</VisualizationOptionLabel>
																		</Grid>
																		{optionDisplay.items.map((option) => (
																			<Grid item xs={12} key={option}>
																				<VisualizationCheckbox
																					label={InformationOptionsMap.get(
																						option,
																					)}
																					checked={isOptionChecked(option)}
																					onChange={(_, checked) =>
																						handleOptionPreferencesChange(
																							option,
																							checked,
																						)
																					}
																				/>
																			</Grid>
																		))}
																	</Grid>
																);
															},
														)}
													</Grid>
													<Grid container item xs={6}>
														{InformationOptionsDisplayColumnTwo.map(
															(optionDisplay, index) => (
																<Grid container item xs={12} key={index}>
																	<Grid item xs={12}>
																		<VisualizationOptionLabel>
																			{optionDisplay.title}
																		</VisualizationOptionLabel>
																	</Grid>
																	{optionDisplay.items.map((option) => (
																		<Grid item xs={12} key={option}>
																			<VisualizationCheckbox
																				label={InformationOptionsMap.get(
																					option,
																				)}
																				checked={isOptionChecked(option)}
																				onChange={(_, checked) =>
																					handleOptionPreferencesChange(
																						option,
																						checked,
																					)
																				}
																			/>
																		</Grid>
																	))}
																</Grid>
															),
														)}
													</Grid>
												</Grid>
											</VisualizationTabPanel>
										</Grid>
									</TabContext>
								</Grid>
							</Menu>
						</Grid>
						<Grid item xs="auto">
							<Button
								variant="outlined"
								startIcon={<Visibility />}
								onClick={(event) => {
									setViewsMenuOpen(true);
									setViewAnchorEl(event.currentTarget);
								}}
							>
								Views
							</Button>
							<Menu
								open={viewsMenuOpen}
								anchorEl={viewAchorEl}
								onClose={() => {
									setViewsMenuOpen(false);
									setViewAnchorEl(null);
								}}
							>
								<MenuItem
									disableRipple
									disableTouchRipple
									onKeyDown={(event) => event.stopPropagation()}
								>
									<Grid
										container
										sx={{
											gap: 1,
											justifyContent: 'space-between',
											alignItems: 'center',
											flexWrap: 'nowrap',
										}}
									>
										<Grid item xs="auto">
											<T4TextFieldV2
												id="new-view-name"
												label="Name"
												variant="standard"
												value={newViewName ?? ''}
												onChangeActual={(event) => {
													event.preventDefault();
													setNewViewName(event.target.value);
												}}
												sx={{ width: '200px' }}
											/>
										</Grid>
										<Grid item xs="auto" sx={{ alignSelf: 'end' }}>
											<Button
												color="success"
												variant="contained"
												startIcon={<Add />}
												disabled={
													(newViewName?.length ?? 0) === 0 ||
													views.map((x) => x.name).includes(newViewName ?? '')
												}
												onClick={() => handleViewCreate(newViewName ?? '')}
											>
												Create
											</Button>
										</Grid>
									</Grid>
								</MenuItem>
								{views.map((view) => (
									<MenuItem
										key={view.id}
										onClick={() => handleOnViewChange(view.id)}
									>
										<Grid
											container
											sx={{
												gap: 1,
												justifyContent: 'space-between',
												alignItems: 'center',
											}}
										>
											<Grid item xs="auto">
												{view.name}
											</Grid>
											<Grid container item xs="auto">
												<Grid item xs="auto">
													<IconButton
														color="success"
														disabled={view.id === 'previous-state'}
														onClick={(event) => {
															event.stopPropagation();
															handleViewSave(view.id);
														}}
													>
														<Save />
													</IconButton>
												</Grid>
												<Grid item xs="auto">
													<IconButton
														color="error"
														disabled={view.id === 'previous-state'}
														onClick={(event) => {
															event.stopPropagation();
															handleViewDelete(view.id);
														}}
													>
														<Delete />
													</IconButton>
												</Grid>
											</Grid>
										</Grid>
									</MenuItem>
								))}
							</Menu>
						</Grid>
						<Grid item xs="auto">
							<ExportButton
								stonlyId={orgChartPrefix}
								defaultFileName={`Entity4_OrgChart_asOf_${asOfDate.format(
									'YYYY_MM_DD',
								)}`}
								diagram={diagram}
							/>
						</Grid>
						<Grid item xs="auto">
							<VisualizationHelpModal />
						</Grid>
					</Grid>,
				]}
			/>
		);
	});
