import { debounce, Grid } from '@mui/material';
import go from 'gojs';
import { ReactOverview } from 'gojs-react';
import { observer } from 'mobx-react-lite';
import { VisualizationPreferenceNodeData } from 'modules/clients/customer-api/src/api/visualizations';
import { VisualizationOptionPreference } from 'modules/clients/customer-api/src/userPreference';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { T4View } from 'shared/components/t4View';
import { accountMapPrefix } from 'stonly/pagePrefixes';
import { VisualizationsFooter } from '../_shared/_components/visualizationFooter';
import { useVisualizationDispatch } from '../_shared/_providers/visualizationProvider';
import { freezeLayout, loadHiddenNodes } from '../_shared/functions';
import { useAccountMap } from '../accountMap/_providers/accountMapProvider';
import { AccountMapEntityViewRenderer } from '../accountMap/components/accountMapEntityViewRenderer';
import { AccountMapHeaderComponent } from '../accountMap/components/accountMapHeaderComponent';

export const EntityAccountViewPage: FC = observer(() => {
	const {
		isLoading,
		updatePreferences,
		optionPreferences,
		data: { entityAccountView },
	} = useAccountMap();
	const setZoom = useVisualizationDispatch();

	const [diagram, setDiagram] = useState<go.Diagram>();
	const [showOverviewMap, setShowOverviewMap] = useState<boolean>(true);

	const applyDisplayOverviewMapPreference = useCallback(
		(currentOptionPreferences: VisualizationOptionPreference[]) => {
			if (currentOptionPreferences) {
				setShowOverviewMap(
					!(
						currentOptionPreferences?.find(
							(x) => x.optionId === 'displayOverviewMap',
						)?.hide ?? false
					),
				);
			}
		},
		[],
	);

	const updateNodeData = useCallback(
		(currentDiagram: go.Diagram) => {
			const nextNodePreferences: VisualizationPreferenceNodeData[] = [];
			currentDiagram.nodes
				.filter(
					(node) =>
						node.visible &&
						node.position.x !== null &&
						!Number.isNaN(node.position.x) &&
						node.position.y !== null &&
						!Number.isNaN(node.position.y),
				)
				.each((node) => {
					nextNodePreferences.push({
						id: node.data.key,
						key: node.data.key,
						x: node.location.x,
						y: node.location.y,
						visible: true,
					});
				});

			updatePreferences({
				nodes: nextNodePreferences,
			});
		},
		[updatePreferences],
	);

	const loadPreferences = useCallback(
		(
			currentOptionPreferences:
				| VisualizationOptionPreference[]
				| undefined = optionPreferences,
		) => {
			if (currentOptionPreferences) {
				applyDisplayOverviewMapPreference(currentOptionPreferences);
			}
		},
		[applyDisplayOverviewMapPreference, optionPreferences],
	);

	const selectionMoved = useCallback(
		(event: go.DiagramEvent) => {
			updateNodeData(event.diagram);
		},
		[updateNodeData],
	);

	const onViewportBoundsChangedHandler = useMemo(
		() =>
			debounce((event: go.DiagramEvent) => {
				setZoom(event.diagram.scale);
			}, 200),
		[setZoom],
	);

	useEffect(() => {
		loadPreferences(optionPreferences);
		loadHiddenNodes(diagram);
	}, [diagram, loadPreferences, optionPreferences]);

	return (
		<T4View loading={isLoading} disablePadding>
			<Grid
				container
				flexDirection="column"
				sx={{ height: '100%', width: '100%', overflow: 'hidden' }}
			>
				<Grid item xs="auto">
					<AccountMapHeaderComponent diagram={diagram} accountView="entity" />
				</Grid>
				<Grid
					item
					xs={true}
					sx={{
						position: 'relative',
						boxSizing: 'unset',

						canvas: {
							outline: 'none',
						},
						'.accountview-gojs-diagram, .entityview-gojs-diagram': {
							width: '100%',
							height: '100%',

							'& .canvas': {
								outline: 'none',
							},
						},
					}}
				>
					{showOverviewMap && (
						<ReactOverview
							initOverview={() =>
								new go.Overview(undefined, {
									contentAlignment: go.Spot.Center,
								})
							}
							divClassName=""
							style={{
								backgroundColor: '#eee',
								height: '150px',
								width: '250px',
								position: 'absolute',
								top: '2rem',
								left: '2rem',
								border: 'solid',
								borderWidth: '1px',
								borderColor: 'black',
								zIndex: 10,
							}}
							observedDiagram={diagram ?? null}
						/>
					)}
					<AccountMapEntityViewRenderer
						nodeDataArray={entityAccountView.nodes}
						linkDataArray={entityAccountView.links}
						diagramListeners={[
							['InitialLayoutCompleted', (event) => setDiagram(event.diagram)],
							['ViewportBoundsChanged', onViewportBoundsChangedHandler],
							['SelectionMoved', selectionMoved],
							['AnimationFinished', (event) => freezeLayout(event.diagram)],
						]}
					/>
				</Grid>
				<Grid
					item
					xs="auto"
					sx={{
						paddingBottom: '1rem',
						justifyContent: 'center',
						position: 'absolute',
						bottom: '1rem',
						zIndex: 5,
					}}
				>
					<VisualizationsFooter
						stonlyId={accountMapPrefix}
						diagram={diagram}
						onResetView={() => {
							if (diagram) {
								updatePreferences({
									nodes: [],
								});
							}
						}}
					/>
				</Grid>
			</Grid>
		</T4View>
	);
});
