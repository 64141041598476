import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { TransactionRule } from 'features/cash4/rules/models';
import { Read, T4DataResponse, T4Response } from 'modules/clients/types';
import { T4CustomResponse } from '../client';
import { ReconciliationSummary } from '../api/cash4';

export type Transaction = {
	id: string;
	date: string;
	transactionImportedDate: string;
	c4AccountNumber: string;
	e4AccountName: string;
	e4AccountNumber: string;
	e4AccountId: string;
	transactionCode: string;
	customerReference: string;
	detail: string;
	cfc: string;
	cfcName: string;
	cft: string;
	cftName: string;
	cfst: string;
	cfstName: string;
	number: number;
	number_ReportingCurrency: number;
	reportingCurrency_Rate: number;
	reportingCurrency_EffectiveDate: string;
	noteContent: string;
	currency: string;
	bankName: string;
	bankCode: string;
	bankReference: string;
	checkNumber: string;
	glNumber: string;
	transactionRuleId: string;
	transactionRuleName: string;
	fI_TransactionId: string;
	reconciliationRecordId?: string;
	reconciliationStatus: string;
	isForecastModelExcluded: boolean;
	reconciliationSummary?: ReconciliationSummary;
};

export type TransactionInput = {
	transactionId: string;
};

export type TransactionsResult = T4CustomResponse<Transaction[]>;

export type TransactionRuleResponse = T4Response<
	T4CustomResponse<TransactionRule>,
	string
>;

export type TransactionNote = {
	noteId?: string;
	content: string;
};

export type UpdateTransactionNoteInput = {
	note: TransactionNote;
};

export type UpdateNoteInput = {
	transactionId: string;
	note: TransactionNote;
};

export type TransactionNoteResult = T4CustomResponse<
	TransactionNote,
	TransactionNote,
	{
		dateRange: any;
	}
>;

export type UpdateNoteResponse = T4Response<
	T4DataResponse<TransactionNote>,
	UpdateNoteInput
>;

export type TransactionsResponse = T4Response<
	TransactionsResult,
	URLSearchParams
>;

export type TransactionResponse = T4Response<
	T4CustomResponse<Transaction>,
	TransactionInput
>;

export type TransactionsNoteResponse = T4Response<
	TransactionNoteResult,
	TransactionNote
>;

export type DeleteTransactionRuleResponse = T4Response<
	T4CustomResponse<string>
>;

export type DeleteTransactionResponse = T4CustomResponse<string>;

export type TransactionAttributes = {
	transactionId: string;
	isForecastModelExcluded: boolean;
};

export type TransactionAttributesResponse = T4Response<
	T4CustomResponse<TransactionAttributes>,
	TransactionAttributes
>;

export type Write<TResult, TInput = {}> = (
	data: TInput,
	config?: AxiosRequestConfig<TInput>,
) => Promise<T4Response<TResult, TInput>>;

export type TransactionEndpoints = {
	all: Read<TransactionsResult, URLSearchParams>;
	getTransaction: Read<T4CustomResponse<Transaction>, TransactionInput>;
	updateNote: Write<UpdateNoteResponse, UpdateNoteInput>;
	deleteRule: Write<DeleteTransactionRuleResponse, string>;
	getRule: Read<T4CustomResponse<TransactionRule>, string>;
	deleteTransaction: Write<DeleteTransactionResponse, string>;
	updateAttributes: Write<TransactionAttributesResponse, TransactionAttributes>;
};

export function transactions(
	axiosInstance: AxiosInstance,
): TransactionEndpoints {
	return {
		all: async (input, config): Promise<TransactionsResponse> =>
			axiosInstance.get('/transactions', {
				...config,
				params: input,
			}),
		updateNote: async (
			{ transactionId, note }: UpdateNoteInput,
			config,
		): Promise<AxiosResponse<UpdateNoteResponse, any>> => {
			return await axiosInstance.put<UpdateNoteResponse>(
				`/transactions/${transactionId}/note`,
				note,
				{ ...config },
			);
		},
		getTransaction: async (data, config = {}): Promise<TransactionResponse> => {
			if (!data?.transactionId) {
				throw new Error("Transaction id can't be null.");
			}
			return await axiosInstance.get(`/transactions/${data.transactionId}`, {
				...config,
			});
		},
		getRule: async (ruleId, config = {}): Promise<TransactionRuleResponse> => {
			if (!ruleId) {
				throw new Error("Rule id can't be null.");
			}
			return await axiosInstance.get(`/rules/${ruleId}`, {
				...config,
			});
		},
		deleteRule: async (
			ruleId: string,
			config = {},
		): Promise<AxiosResponse<DeleteTransactionRuleResponse, string>> => {
			return axiosInstance.delete(`/rules/${ruleId}`, {
				...config,
			});
		},
		deleteTransaction: async (
			transactionId: string,
			config = {},
		): Promise<AxiosResponse<DeleteTransactionResponse, string>> => {
			return axiosInstance.delete(`/transactions/${transactionId}`, {
				...config,
			});
		},
		updateAttributes: async (
			input: TransactionAttributes,
			config,
		): Promise<AxiosResponse<TransactionAttributesResponse, any>> => {
			return await axiosInstance.put<TransactionAttributesResponse>(
				`/transactions/${input.transactionId}/attributes`,
				{ isForecastModelExcluded: input.isForecastModelExcluded },
				{ ...config },
			);
		},
	};
}
