import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu } from '@mui/material';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React, { FC, useState } from 'react';
import { Holdings } from '../models';
import { deleteInvestmentHolding } from '../services';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { observer } from 'mobx-react-lite';
import { stonlyIds } from '../stonlyIds';
import {
	ConfirmationDialog,
	IConfirmationDialogProps,
} from 'shared/components/confirmationDialog';

interface ActionButtonProps {
	row: Holdings;
}

const RowActionButton: FC<ActionButtonProps> = observer(({ row }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [selectedDialog, setSelectedDialog] =
		useState<IConfirmationDialogProps>({} as IConfirmationDialogProps);
	const [openDialog, setOpenDialog] = useState(false);
	const queryClient = useQueryClient();
	const { enqueueSnackbar } = useSnackbar();
	const [confirmationModalLoading, setConfirmationModalLoading] =
		useState(false);

	const handleClickSelf = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleRefetch = (queryKey: QueryKey) => {
		queryClient.refetchQueries(queryKey);
	};

	const handleCloseSelf = () => {
		setAnchorEl(null);
	};

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleCloseAll = () => {
		handleCloseSelf();
		handleCloseDialog();
	};

	const handleClickDelete = () => {
		setSelectedDialog({
			title: 'Delete investment holding?',
			text: 'This investment holding will be permanently deleted from the system. This action cannot be undone.',
			primaryButtonText: 'DELETE',
			onPrimaryButtonClick: handleDeleteInvestmentHolding,
			secondaryButtonText: 'CANCEL',
			onSecondaryButtonClick: handleCloseAll,
		} as IConfirmationDialogProps);
		handleOpenDialog();
	};

	const handleDeleteInvestmentHolding = () => {
		setConfirmationModalLoading(true);
		try {
			if (row.investmentHoldingId) {
				mutation.mutate({
					InvestmentHoldingId: row.investmentHoldingId,
				});
			}
			handleCloseDialog();
			handleCloseSelf();
		} catch {
		} finally {
			setConfirmationModalLoading(false);
		}
	};

	const deleteHolding = (data: { InvestmentHoldingId: string }) => {
		const { InvestmentHoldingId } = data;

		return deleteInvestmentHolding(InvestmentHoldingId);
	};

	const mutation = useMutation(deleteHolding, {
		onSuccess: (data, variables) => {
			if (data) {
				handleRefetch(['holdings']);
				enqueueSnackbar('Successfully deleted holding', {
					variant: 'success',
				});
			} else {
				enqueueSnackbar('Failed to delete holding', {
					variant: 'error',
				});
			}
		},
	});

	return (
		<div>
			<IconButton
				aria-label="more"
				onClick={handleClickSelf}
				data-stonlyid={stonlyIds.investmentsHoldingsRowContextMenu}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleCloseSelf}
			>
				{row && <DeleteMenuItem onClick={handleClickDelete} />}
			</Menu>
			<ConfirmationDialog
				open={openDialog}
				onClose={handleCloseDialog}
				title={selectedDialog.title || ''}
				text={selectedDialog.text}
				primaryButtonText={selectedDialog.primaryButtonText}
				secondaryButtonText={selectedDialog.secondaryButtonText}
				onPrimaryButtonClick={selectedDialog.onPrimaryButtonClick}
				onSecondaryButtonClick={selectedDialog.onSecondaryButtonClick}
				stonlyIds={{
					confirmationModal:
						stonlyIds.investmentsHoldingsDeleteConfirmationModal,
					secondaryButton:
						stonlyIds.investmentsHoldingsDeleteConfirmationCancelButton,
					primaryButton:
						stonlyIds.investmentsHoldingsDeleteConfirmationDeleteButton,
				}}
				loading={confirmationModalLoading}
			/>
		</div>
	);
});

export default RowActionButton;
