import { Box, useTheme } from '@mui/material';
import go, { Node } from 'gojs';
import { ReactDiagram } from 'gojs-react';
import { observer } from 'mobx-react-lite';
import {
	Account,
	LegalEntity,
	Relationship,
} from 'modules/clients/customer-api/src/api/visualizations';
import { FC, useMemo } from 'react';
import { T4View } from 'shared/components/t4View';
import { useVisualizationDataQuery } from '../_shared/_hooks/useVisualizationDataQuery';

//#region Helper Functions

//#endregion

export type LevelOneNodeData = {
	key: string;
	type: 'Entity' | 'Account';
	parent?: string;
	everExpanded: boolean;
};

export type LevelOneProps = {};

export const LevelOne: FC<LevelOneProps> = observer(() => {
	const theme = useTheme();
	const { loading, data } = useVisualizationDataQuery();

	const diagram = useMemo(() => {
		const startingLegalEntity =
			data?.legalEntities
				?.filter((legalEntity) => (legalEntity.primaryOwnerOf?.length ?? 0) > 0)
				.find(
					(x) =>
						data?.legalEntities?.every(
							(y) =>
								!y.primaryOwnerOf?.some((z) => z.objectId === x.id) ?? true,
						),
				) ?? data?.legalEntities?.[0];
		const startingNode: LevelOneNodeData = {
			key: startingLegalEntity?.entityCode ?? '',
			type: 'Entity',
			everExpanded: false,
		};

		const diagram = new go.Diagram({
			layout: new go.ForceDirectedLayout(),
			model: new go.TreeModel([startingNode]),
			'draggingTool.dragsTree': true,
		});

		function createSubTree(diagram: go.Diagram, parentData: LevelOneNodeData) {
			//#region Helper Functions

			function getLegalEntities(
				relationships: Relationship[] | undefined,
			): LegalEntity[] {
				if (data?.legalEntities) {
					return (
						relationships
							?.map(
								(relationship) =>
									data?.legalEntities?.find(
										(legalEntity) => legalEntity.id === relationship.objectId,
									)!,
							)
							.filter((x) => x !== undefined) ?? []
					);
				} else {
					return [];
				}
			}

			function getAccounts(
				relationships?: Relationship[] | undefined,
			): Account[] {
				if (data?.accounts) {
					return (
						relationships
							?.map(
								(relationship) =>
									data.accounts?.find(
										(account) => account.id === relationship.objectId,
									)!,
							)
							.filter((x) => x !== undefined) ?? []
					);
				} else {
					return [];
				}
			}

			function getAccountNodeData(account: Account): LevelOneNodeData {
				return {
					key: account.code,
					parent: parentData.key,
					type: 'Account',
					everExpanded: false,
				};
			}

			function getLegalEntityNodeData(
				legalEntity: LegalEntity,
			): LevelOneNodeData {
				return {
					key: legalEntity.entityCode,
					parent: parentData.key,
					type: 'Entity',
					everExpanded: false,
				};
			}

			//#endregion

			const children: LevelOneNodeData[] = [];
			switch (parentData.type) {
				case 'Entity': {
					const legalEntity = data?.legalEntities?.find(
						(x) => x.entityCode === parentData.key,
					);
					children.push(
						...getLegalEntities(legalEntity?.primaryOwnerOf).map(
							getLegalEntityNodeData,
						),
					);
					children.push(
						...getAccounts(legalEntity?.accounts).map(getAccountNodeData),
					);
					break;
				}
				case 'Account': {
				}
			}

			diagram.commit((d) => {
				d.model.commit((m) => {
					const parent = d.findNodeForData(parentData);
					children.forEach((childData) => {
						const foundData = m.findNodeDataForKey(childData.key);
						if (!foundData) {
							m.addNodeData(childData);
						} else {
							m.setDataProperty(foundData, 'parent', parentData.key);
						}

						const childNode = diagram.findNodeForData(foundData ?? childData);
						if (childNode && parent?.location) {
							childNode.location = parent.location;
						}
					});
				});
			});

			return children.length;
		}

		function expandNode(diagram: go.Diagram, node: Node) {
			diagram?.commit((d) => {
				d.model.commit((m) => {
					const parentNodeData = node.data;
					if (!parentNodeData.everExpanded) {
						m.setDataProperty(parentNodeData, 'everExpanded', true);
						const numchildren = createSubTree(d, parentNodeData);
						if (numchildren === 0) {
							const object = node?.findObject('TREEBUTTON');
							if (object) {
								object.visible = false;
							}
						}
					}
				});

				if (node.isTreeExpanded) {
					d.commandHandler.collapseTree(node);
				} else {
					d.commandHandler.expandTree(node);
				}
				d.zoomToFit();
			});
		}

		diagram.nodeTemplate = new go.Node('Spot', {
			selectionObjectName: 'PANEL',
			isTreeExpanded: false,
			isTreeLeaf: false,
		}).add(
			new go.Panel('Auto', { name: 'PANEL' }).add(
				new go.Shape('Circle', {
					fill: 'whitesmoke',
					stroke: 'black',
				}).bind('fill', 'type', (type) => {
					switch (type) {
						case 'Entity':
							return theme.palette.primary.main;
						case 'Account':
							return theme.palette.secondary.main;
						default:
							return theme.palette.error.main;
					}
				}),
				new go.TextBlock({
					font: '12pt sans-serif',
					margin: 5,
				}).bind('text', 'key'),
			),
			go.GraphObject.build('TreeExpanderButton', {
				name: 'TREEBUTTON',
				width: 20,
				height: 20,
				alignment: go.Spot.Bottom,
				alignmentFocus: go.Spot.Center,
				click: (event, obj) => {
					const node = obj.part as Node;
					if (node === null) {
						return;
					}

					event.handled = true;
					expandNode(event.diagram, node);
				},
			}),
		);

		expandNode(diagram, diagram.findNodeForData(startingNode)!);

		return diagram;
	}, [
		data,
		theme.palette.error.main,
		theme.palette.primary.main,
		theme.palette.secondary.main,
	]);

	return (
		<T4View loading={loading} disablePadding>
			<Box
				sx={{
					height: '100%',
					width: '100%',

					'.level-one': {
						height: '100%',
						width: '100%',
					},
				}}
			>
				<ReactDiagram
					divClassName="level-one"
					initDiagram={() => diagram}
					nodeDataArray={diagram.model.nodeDataArray}
				/>
			</Box>
		</T4View>
	);
});
