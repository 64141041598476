import { observer } from 'mobx-react-lite';
import { createContext, FC, useContext, useReducer } from 'react';

//#region Contexts

type Context = {
	zoom: number;
};
type Dispatch = (zoom: number) => void;

const VisualizationContext = createContext({} as Context);
const VisualizationDispatchContext = createContext({} as Dispatch);

//#endregion

//#region Providers

function reducer(state: Context, zoom: number): Context {
	if (state.zoom !== zoom) {
		return {
			zoom: zoom,
		};
	}

	return state;
}

export const VisualizationProvider: FC = observer(({ children }) => {
	const [context, dispatch] = useReducer(reducer, { zoom: 1 });

	return (
		<VisualizationContext.Provider value={context}>
			<VisualizationDispatchContext.Provider value={dispatch}>
				{children}
			</VisualizationDispatchContext.Provider>
		</VisualizationContext.Provider>
	);
});

//#endregion

//#region Hooks

export type UseVisualizationProps = Context;

export function useVisualization(): UseVisualizationProps {
	return useContext(VisualizationContext);
}

export type UseVisualizationDispatchContextProps = Dispatch;

export function useVisualizationDispatch(): UseVisualizationDispatchContextProps {
	return useContext(VisualizationDispatchContext);
}

//#endregion
