import { Grid } from '@mui/material';
import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';
import { BreadcrumbList } from 'features/entity4/shared/components/breadcrumbs/breadcrumbList';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { CannotDisplay } from '../../../../shared/components/cannotDisplay';
import { ACCESS_DENIED_MESSAGING } from '../../../../shared/constants/cannotDisplayMessaging';
import { FrontendGroupRepository } from '../groupRepository';
import { UngroupedEntitiesListViewModel } from './ungroupedEntitiesListViewModel';

export const UngroupedEntitiesListRoute: FC = observer(() => {
	const frontendGroupsRepository = new FrontendGroupRepository();
	const history = useHistory();

	const [viewModel] = useState(
		new UngroupedEntitiesListViewModel(frontendGroupsRepository, history),
	);

	const { isAdmin } = useUser();
	if (!isAdmin) {
		return (
			<CannotDisplay
				headingText={ACCESS_DENIED_MESSAGING.HEADING}
				bodyText={ACCESS_DENIED_MESSAGING.BODY}
				imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
			/>
		);
	}

	return <UngroupedEntitiesList viewModel={viewModel} />;
});

interface IUngroupedEntitiesListProps {
	viewModel: UngroupedEntitiesListViewModel;
}

const stonlyIds = {
	ungroupedEntitiesList: 'ungrouped-entities-list',
};

export const UngroupedEntitiesList: FC<IUngroupedEntitiesListProps> = observer(
	({ viewModel }) => {
		useEffect(() => {
			viewModel.loadUngroupedEntities();
		}, [viewModel]);

		return (
			<T4View
				header={
					<PageHeader
						id={pageHeaderStonlyIds.ungroupedEntitiesList}
						title="Ungrouped Entities"
						breadcrumbs={
							<BreadcrumbList
								breadcrumbs={[
									{
										label: 'Administration',
										href: paths.administration.href,
									},
									{
										label: 'Security Groups',
										href: paths.administration.groups.href,
									},
								]}
							/>
						}
					/>
				}
			>
				<Grid
					container
					sx={{ rowGap: 1.5, flexDirection: 'column', height: '100%' }}
				>
					<Grid item xs="auto">
						<T4Alert severity="info">
							All members have access to ungrouped entities.
						</T4Alert>
					</Grid>
					<Grid item xs={true}>
						<ActuallyPrettyGoodDataGridWrapper>
							<UserPreferencesDataGrid
								stonlyId={stonlyIds.ungroupedEntitiesList}
								tableKey="ungroupedEntitiesList"
								columns={viewModel.getColumns()}
								rows={viewModel.getRows()}
								loading={viewModel.getLoading()}
								errorMessage={viewModel.getError()}
								onCellClick={viewModel.onCellClick}
								showToolbar
								hideFooter={false}
							/>
						</ActuallyPrettyGoodDataGridWrapper>
					</Grid>
				</Grid>
			</T4View>
		);
	},
);
