import go from 'gojs';
import jsPDF from 'jspdf';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';

export type DownloadType = 'SVG' | 'PNG' | 'PDF';

export type UseDiagramDownloadProps = {
	loading: boolean;
	download: (
		diagram: go.Diagram,
		type: DownloadType,
		fileName?: string,
	) => void;
};

export function useDiagramDownload(
	defaultFileName: string,
): UseDiagramDownloadProps {
	const { visualizationPdfExports: visualizationPdfExport } =
		useT4FeatureFlags();
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);

	const generateError = useCallback<(message: string) => void>(
		(message) => {
			enqueueSnackbar({
				message: message,
				variant: 'error',
			});
		},
		[enqueueSnackbar],
	);

	const generatePng = useCallback(
		async (diagram: go.Diagram): Promise<Blob | undefined> => {
			let blob: Blob | undefined;
			for (const maxWidth of [NaN, 32000, 16000, 8000, 4000, 2000]) {
				try {
					blob = await new Promise<Blob | undefined>((resolve) => {
						if (Number.isNaN(maxWidth)) {
							diagram.makeImageData({
								background: 'white',
								scale: NaN,
								size: diagram.documentBounds.size,
								position: diagram.documentBounds.position,
								maxSize: diagram.documentBounds.size,
								returnType: 'blob',
								callbackTimeout: 0,
								callback: (result: Blob) => {
									if (result) {
										resolve(result);
									} else {
										resolve(undefined);
									}
								},
							});
						} else {
							const maxHeight = (297 / 420) * maxWidth;

							const maxSize = new go.Size(
								diagram.documentBounds.width,
								diagram.documentBounds.height,
							);
							if (maxSize.width > maxWidth || maxSize.height > maxHeight) {
								const scale = Math.min(
									maxWidth / diagram.documentBounds.width,
									maxHeight / diagram.documentBounds.height,
								);
								maxSize.width *= scale;
								maxSize.height *= scale;
							}

							diagram.makeImageData({
								background: 'white',
								scale: NaN,
								maxSize: maxSize,
								returnType: 'blob',
								callbackTimeout: 0,
								callback(result: Blob) {
									if (result) {
										resolve(result);
									} else {
										resolve(undefined);
									}
								},
							});
						}
					});
				} catch {}

				if (blob) {
					break;
				}
			}

			return blob;
		},
		[],
	);

	const downloadFile = useCallback<(blob: Blob, fileName?: string) => void>(
		(blob, fileName = defaultFileName) => {
			try {
				if (blob) {
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.setAttribute('download', fileName);

					document.body.appendChild(link);
					link.click();
					globalThis.document.body.removeChild(link);
					window.URL.revokeObjectURL(link.href);
				} else {
					generateError('File not provided for download.');
				}
			} catch {
				generateError('Unable to download file.');
			}
		},
		[defaultFileName, generateError],
	);

	const downloadSvg = useCallback<
		(diagram: go.Diagram, fileName?: string) => void
	>(
		(diagram, fileName = defaultFileName) => {
			try {
				if (diagram) {
					const svg = diagram.makeSvg({
						size: new go.Size(
							diagram.documentBounds.width,
							diagram.documentBounds.height,
						),
					});
					if (svg) {
						const svgBlob = new Blob(
							[new XMLSerializer().serializeToString(svg)],
							{
								type: 'image/svg+xml',
							},
						);
						downloadFile(svgBlob, fileName);
					} else {
						generateError('Unable to generate svg.');
					}
				}
			} catch {
				generateError('Unable to generate svg.');
			} finally {
				setLoading(false);
			}
		},
		[defaultFileName, downloadFile, generateError],
	);

	const downloadPng = useCallback<
		(diagram: go.Diagram, fileName?: string) => Promise<void>
	>(
		async (diagram, fileName = defaultFileName) => {
			try {
				if (diagram) {
					const blob = await generatePng(diagram);
					if (blob) {
						downloadFile(blob, fileName);
					} else {
						generateError('Unable to generate png.');
					}
				} else {
					generateError('Diagram not provided to generate png.');
				}
			} catch {
				generateError('Unable to generate png.');
			} finally {
				setLoading(false);
			}
		},
		[defaultFileName, downloadFile, generateError, generatePng],
	);

	const downloadPdf = useCallback<
		(diagram: go.Diagram, fileName?: string) => void
	>(
		(diagram, fileName = defaultFileName) => {
			try {
				const pdfMaxSize = 8000;
				if (diagram) {
					const width =
						diagram.documentBounds.width <= pdfMaxSize
							? diagram.documentBounds.width
							: pdfMaxSize;
					const height =
						diagram.documentBounds.height <= pdfMaxSize
							? diagram.documentBounds.height
							: pdfMaxSize;
					const image = diagram.makeImage({
						scale: NaN,
						maxSize: new go.Size(width, height),
					});

					if (image) {
						const pdf = new jsPDF({
							orientation: 'landscape',
							unit: 'in',
							format: [width / 96, height / 96],
						});

						pdf.addImage(image, 'image/png', 0, 0, image.width, image.height);
						const blob = pdf.output('blob');
						downloadFile(blob, fileName);
					} else {
						generateError('Image not provided to add to pdf.');
					}
				} else {
					generateError('Diagram not provided to generate pdf.');
				}
			} catch {
				generateError('Unable to generate pdf.');
			} finally {
				setLoading(false);
			}
		},
		[defaultFileName, downloadFile, generateError],
	);

	const downloadPdfV2 = useCallback(
		async (diagram: go.Diagram, fileName: string) => {
			try {
				if (diagram) {
					const blob = await generatePng(diagram);
					if (blob) {
						const response = await customerApiClient.downloadPdf(
							blob,
							fileName,
						);

						if (response.status === 200) {
							const file = new Blob([response.data], {
								type: 'application/pdf',
							});
							downloadFile(file, fileName);
						} else {
							throw new Error(await response.data.text());
						}
					} else {
						generateError('Unable to generate pdf.');
					}
				} else {
					generateError('Diagram not provided to generate pdf.');
				}
			} catch {
				generateError('Unable to generate pdf.');
			} finally {
				setLoading(false);
			}
		},
		[customerApiClient, downloadFile, generateError, generatePng],
	);

	const download = useCallback<UseDiagramDownloadProps['download']>(
		async (diagram, type, fileName = defaultFileName) => {
			try {
				if (diagram) {
					setLoading(true);
					switch (type) {
						case 'SVG':
							downloadSvg(diagram, fileName);
							break;
						case 'PNG':
							await downloadPng(diagram, fileName);
							break;
						case 'PDF':
							if (visualizationPdfExport) {
								downloadPdfV2(diagram, fileName);
							} else {
								downloadPdf(diagram, fileName);
							}
							break;

						default: {
							generateError('Invalid file type selected for download.');
						}
					}
				} else {
					generateError('Diagram not provided to download.');
				}
			} catch {
				setLoading(false);
				generateError('Unable to download file.');
			}
		},
		[
			defaultFileName,
			downloadPdf,
			downloadPdfV2,
			downloadPng,
			downloadSvg,
			generateError,
			visualizationPdfExport,
		],
	);

	return {
		loading: loading,
		download: download,
	};
}
