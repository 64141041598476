import { Tooltip, Typography } from '@mui/material';
import {
	GRID_CHECKBOX_SELECTION_FIELD,
	GridColDef,
	GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import T4Drawer from 'features/cash4/shared/components/T4SideDrawer/T4DrawerShell';
import { ProjectionDrawer } from 'features/cash4/transactions/components/ProjectionDrawer/ProjectionDrawer';
import { observer } from 'mobx-react-lite';
import { ProjectedItem } from 'modules/clients/customer-api/src/api/cash4';
import { FC, useCallback, useMemo, useState } from 'react';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import {
	getCurrencyColumnDefinition,
	getDateColumnDefinition,
} from 'shared/utilities/dataGrid/columnDefinitions';
import {
	DataGridColumnWidths,
	getOptionsMenuColDef,
	USER_PREFERENCES_FIELD_OPTIONS,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { formatCurrency } from 'utilities/currencyUtils';
import { useReconciliationsContext } from '../_providers/reconciliationsProvider';
import {
	Actions,
	ActionsEnum,
} from 'features/cash4/shared/components/T4ActionMenu';
import { useUser } from 'shared/hooks/useUser';
import { normalizeReconciliationStatus } from '../_hooks/useReconciliationCalculations';
import {
	getProjectedTransactionsColumnDefs,
	projectedTransactionsColumnVisibility,
} from 'features/cash4/transactions/utilities';

export type ProjectedItemsGridProps = {
	stonlyId: string;
	tableKey: string;
	loading?: boolean;
	projectedItems: ProjectedItem[];
	selectedProjectedIds?: string[];
	setSelectedProjectedIds?: (selectedProjectedIds: string[]) => void;
};

export const ProjectedItemsGrid: FC<ProjectedItemsGridProps> = observer(
	({
		stonlyId,
		tableKey,
		loading = false,
		selectedProjectedIds,
		setSelectedProjectedIds,
		projectedItems,
	}) => {
		const { setSelectedProjectedItem, setProjectedItemDrawerOpen } =
			useReconciliationsContext();

		const useCheckboxSelection = useMemo(
			() => selectedProjectedIds !== undefined,
			[selectedProjectedIds],
		);
		const [projection, setProjection] = useState<ProjectedItem>();
		const [openProjectionDrawer, setOpenProjectionDrawer] = useState(false);

		const onRowSelectionModelChangeHandler = useMemo(() => {
			if (useCheckboxSelection && setSelectedProjectedIds) {
				return (projectedIds: GridRowSelectionModel) => {
					setSelectedProjectedIds(projectedIds.map((x) => x.toString()));
				};
			}
		}, [setSelectedProjectedIds, useCheckboxSelection]);

		const handleOpenProjectionDrawer = useCallback(() => {
			setOpenProjectionDrawer(true);
		}, []);

		const handleCloseProjectionDrawer = useCallback(() => {
			setOpenProjectionDrawer(false);
		}, []);

		const handleProjectedViewClick = useCallback(
			(projection: ProjectedItem) => {
				setProjection(projection);
				handleOpenProjectionDrawer();
			},
			[handleOpenProjectionDrawer],
		);

		const handleProjectedEditClick = useCallback(
			(projection: ProjectedItem) => {
				setSelectedProjectedItem(projection);
				setProjectedItemDrawerOpen(true);
			},
			[setProjectedItemDrawerOpen, setSelectedProjectedItem],
		);

		const user = useUser();

		const columns = useMemo<GridColDef[]>(() => {
			return getProjectedTransactionsColumnDefs(
				user.cash4.isAuthor,
				useCheckboxSelection
					? undefined
					: {
							...getOptionsMenuColDef((params) => {
								return (
									<Actions
										stonlyId="c4-create-reconciliation-projected-item"
										actions={[
											{
												action: ActionsEnum.view,
												callback: () => handleProjectedViewClick(params.row),
											},
											...(user.cash4.isAuthor
												? [
														{
															action: ActionsEnum.edit,
															callback: () =>
																handleProjectedEditClick(params.row),
														},
												  ]
												: []),
										]}
										id="projectedTransactionId-more-menu"
									/>
								);
							}),
					  },
				undefined,
			);
		}, [
			handleProjectedViewClick,
			handleProjectedEditClick,
			useCheckboxSelection,
			user.cash4.isAuthor,
		]);

		return (
			<>
				<UserPreferencesDataGrid
					stonlyId={stonlyId}
					tableKey={tableKey}
					loading={loading}
					rows={projectedItems}
					hideFooter={false}
					showToolbar={true}
					showCustomViewButton
					pagination
					autoPageSize
					checkboxSelection={useCheckboxSelection}
					disableRowSelectionOnClick={useCheckboxSelection}
					rowSelectionModel={selectedProjectedIds}
					onRowSelectionModelChange={onRowSelectionModelChangeHandler}
					columns={columns}
					columnVisibilityModel={projectedTransactionsColumnVisibility}
					initialState={{
						sorting: {
							sortModel: [{ field: 'date', sort: 'asc' }],
						},
						pinnedColumns: {
							left: [
								GRID_CHECKBOX_SELECTION_FIELD,
								USER_PREFERENCES_FIELD_OPTIONS,
							],
						},
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							hideExport: true,
							quickFilterProps: {
								variant: 'outlined',
								size: 'small',
							},
						},
					}}
				/>
				<T4Drawer
					open={openProjectionDrawer}
					onClose={handleCloseProjectionDrawer}
					stonlyPrefix="projected-transaction-details"
				>
					{projection && (
						<ProjectionDrawer projectionId={projection.id} disableLink />
					)}
				</T4Drawer>
			</>
		);
	},
);
