import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { SigmaEmbed } from '../_components/sigmaEmbed';
import { SigmaProvider } from '../_providers/sigmaProvider';

export type SigmaPageProps = {
	solution: string;
};
export const SigmaPage: FC<SigmaPageProps> = observer(({ solution }) => (
	<SigmaProvider solution={solution}>
		<SigmaEmbed />
	</SigmaProvider>
));
