import { Share } from '@mui/icons-material';
import { Tab, Tabs } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CopyToClipboardIconButton } from 'shared/components/copyToClipboardIconButton';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import { DrawerCancelButton } from 'shared/components/drawer/drawerButtons';
import { useGetPaymentTemplate } from '../../hooks/usePaymentTemplates';
import { TemplateHistoryTab } from './templateHistoryTab';
import { TemplateStatusAndDetailsTab } from './templateStatusAndDetailsTab';

interface TemplateDetailsDrawerProps {
	templateId: string | null;
	version: number | null;
	onClose: () => void;
}

export const TemplateDetailsDrawer: FC<TemplateDetailsDrawerProps> = ({
	templateId,
	version,
	onClose,
}) => {
	const { enqueueSnackbar } = useSnackbar();
	const [tabIndex, setTabIndex] = useState<number>(0);

	const closeDrawer = useCallback(() => {
		setTabIndex(0);
		onClose();
	}, [onClose]);

	const {
		isLoading,
		isFetching,
		data: template,
		error,
	} = useGetPaymentTemplate(templateId, version);
	useEffect(() => {
		if (!isLoading && error?.message) {
			if (!template) closeDrawer();
			enqueueSnackbar(error.message, {
				variant: 'error',
			});
		}
	}, [isLoading, template, error, closeDrawer, enqueueSnackbar]);

	const DetailsTab = useMemo(
		() =>
			tabIndex === 0 ? (
				<TemplateStatusAndDetailsTab template={template} />
			) : null,
		[template, tabIndex],
	);

	const HistoryTab = useMemo(
		() => (tabIndex === 1 ? <TemplateHistoryTab template={template} /> : null),
		[template, tabIndex],
	);

	return (
		<T4DrawerBase
			title="Template Details"
			open={!!templateId}
			initializing={isLoading || isFetching}
			onClose={closeDrawer}
			utilityActions={[
				<CopyToClipboardIconButton
					valueToCopy={window.location.href}
					initialTooltipText="Copy Link"
				>
					<Share />
				</CopyToClipboardIconButton>,
			]}
			tabs={
				<Tabs
					indicatorColor="primary"
					value={tabIndex}
					onChange={(_, index) => setTabIndex(index)}
				>
					<Tab label="Status and Details" tabIndex={0} />
					<Tab label="History" tabIndex={1} />
				</Tabs>
			}
			actions={[<DrawerCancelButton label="Close" onCancel={closeDrawer} />]}
			disableNavigationCollapse
		>
			{DetailsTab}
			{HistoryTab}
		</T4DrawerBase>
	);
};
