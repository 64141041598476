import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import { useClients } from 'shared/hooks/useClients';
import { AccountsList } from './components/AccountsList';
import { IgnoredList } from './components/IgnoredList';

export const stonlyIds = {
	accountsGrid: 'account-integrations-grid',
	ignoredGrid: 'account-integrations-ignored-grid',
	expandButton: 'account-integrations-expand-button',
	collapseButton: 'account-integrations-collapse-button',
	ignoreAccountButton: 'account-integrations-ignore-button',
	openLinkAccountModal: 'account-integrations-open-link-modal',
	openIgnoreAccountModal: 'account-integrations-open-ignore-modal',
	linkAccountButton: 'account-integrations-link-button',
	linkAccountInlineGridButton: 'account-integrations-inline-grid-link-button',
	moreOptions: 'account-integrations-menu-button',
	ignoredAccountsLink: 'account-integrations-ignored-link',
	accountLinkModal: 'account-integrations-link-modal',
	entity4AccountSelector: 'account-integrations-e4-selector',
	cancelButtonLinkDialog: 'account-integrations-cancel-button-link-dialog',
	cancelButtonUnlinkDialog: 'account-integrations-cancel-button-unlink-dialog',
	cancelButtonIgnoreDialog: 'account-integrations-cancel-button-ignore-dialog',
	ignoreAccountModal: 'account-integrations-ignore-modal',
	backToAccountsButton: 'account-integrations-ignored-back-button',
};

export type AccountsListRouteProps = {};

export const AccountsListRoute: FC<AccountsListRouteProps> = observer(() => {
	const [isIgnored, setIsIgnored] = React.useState(false);

	const handleIgnoreToggle = () => {
		setIsIgnored(!isIgnored);
	};

	const { customerApiClient } = useClients();

	const query = useQuery(['accounts'], () =>
		customerApiClient.cash4.accounts.getAll(),
	);

	const accountsList =
		query.data?.data?.filter((account) => account.isIgnored === false) ?? [];
	const ignoredAccountsList =
		query.data?.data?.filter((account) => account.isIgnored) ?? [];

	return (
		<Grid container sx={{ height: '100%' }}>
			<Grid
				container
				sx={{
					flexDirection: 'column',
				}}
			>
				{isIgnored ? (
					<>
						<Grid item xs="auto">
							<Button
								onClick={handleIgnoreToggle}
								variant="outlined"
								size="small"
								startIcon={<ArrowBackIcon />}
								sx={{ mb: 2 }}
								data-stonlyid={stonlyIds.backToAccountsButton}
							>
								Back to accounts
							</Button>
						</Grid>
						<Grid item xs="auto">
							<Typography variant="h4" sx={{ mb: 2 }}>
								Ignored Accounts
							</Typography>
						</Grid>
						<Grid item xs={true}>
							<IgnoredList
								accounts={ignoredAccountsList}
								stonlyIds={stonlyIds}
							/>
						</Grid>
					</>
				) : (
					<>
						{ignoredAccountsList.length !== 0 && (
							<Grid item xs="auto">
								<Button
									onClick={handleIgnoreToggle}
									variant="text"
									size="small"
									sx={{ mb: 2, alignSelf: 'flex-end' }}
									data-stonlyid={stonlyIds.ignoredAccountsLink}
								>
									{ignoredAccountsList.length} Ignored Accounts
								</Button>
							</Grid>
						)}
						<Grid item xs={true}>
							<ActuallyPrettyGoodDataGridWrapper>
								<AccountsList
									accounts={accountsList.sort(
										(a, b) =>
											(Number(a.isLinked) - Number(b.isLinked) ||
												a.linkedE4Account?.displayName?.localeCompare(
													b.linkedE4Account?.displayName ?? '',
												)) ??
											0,
									)}
									stonlyIds={stonlyIds}
								/>
							</ActuallyPrettyGoodDataGridWrapper>
						</Grid>
					</>
				)}
			</Grid>
		</Grid>
	);
});
