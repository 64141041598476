import { debounce } from '@mui/material';
import { Diagram, InputEvent, Link, Node, Part, Point, Set } from 'gojs';

export function isControlPressed(event: KeyboardEvent | InputEvent) {
	const isMac = navigator.userAgent.includes('Mac');

	if (event instanceof KeyboardEvent) {
		return isMac ? event.metaKey : event.ctrlKey;
	} else {
		return isMac ? event.meta : event.control;
	}
}

export function highlightDirectConnections(node: Node) {
	node.diagram?.model.commit((m) => {
		m.set(node, 'isSelected', true);

		node.findNodesInto().each((n) => {
			m.set(n, 'isSelected', true);
		});

		node.findLinksInto().each((l) => {
			m.set(l, 'isSelected', true);
		});
		node.findLinksOutOf().each((l: go.Link) => {
			if (l.toNode && l.data.category?.includes('Two')) {
				m.set(l.toNode, 'isSelected', true);
				m.set(l, 'isSelected', true);
			}
		});
	});
}

export function recurseHighlightAllAncestors(node: Node) {
	node.diagram?.model.commit((m) => {
		m.set(node, 'isSelected', true);
		const ancestors = getAllAncestors(node);
		ancestors.forEach((part) => {
			if (part instanceof Node || part instanceof Link) {
				m.set(part, 'isSelected', true);
			}
		});
	});
}

export function recurseHighlightAllDescendants(node: Node) {
	node.diagram?.model.commit((m) => {
		m.set(node, 'isSelected', true);
		const descendants = getAllDescendants(node);
		descendants.forEach((part) => {
			if (part instanceof Node || part instanceof Link) {
				m.set(part, 'isSelected', true);
			}
		});
	});
}

export function getAllAncestors(node: Node): Part[] {
	const ancestors: Part[] = [];
	const visitedNodes: Set<go.Node> = new Set<Node>();
	const visitedLinks: Set<go.Link> = new Set<Link>();

	let findAncestors = (node: Node) => {
		if (visitedNodes.contains(node)) {
			return;
		}
		visitedNodes.add(node);

		ancestors.push(node);

		let parentNodes: go.Set<Node> = new Set<Node>(node.findNodesInto());
		let parentLinks: go.Set<Link> = new Set<Link>(node.findLinksInto());

		node.findLinksOutOf().each((link) => {
			if (link.data.category?.includes('Two')) {
				parentLinks.add(link);
				parentNodes.add(link.toNode!);
			}
		});

		parentLinks.each((link) => {
			if (!visitedLinks.contains(link)) {
				ancestors.push(link);
				visitedLinks.add(link);
			}
		});

		parentNodes.each((parent) => {
			findAncestors(parent);
		});
	};

	findAncestors(node);
	return ancestors;
}

export function getAllDescendants(node: Node): Part[] {
	const descendants: go.Part[] = [];
	const visitedNodes: go.Set<Node> = new Set<Node>();
	const visitedLinks: go.Set<Link> = new Set<Link>();

	let findDescendants = (node: Node) => {
		if (visitedNodes.contains(node)) {
			return;
		}
		visitedNodes.add(node);

		descendants.push(node);

		let childNodes: Set<Node> = new Set<Node>(node.findNodesOutOf());
		let childLinks: Set<Link> = new Set<Link>(node.findLinksOutOf());

		node.findLinksOutOf().each((link) => {
			if (link.data.category?.includes('Two')) {
				childLinks.add(link);
				childNodes.add(link.toNode!);
			}
		});

		childLinks.each((link) => {
			if (!visitedLinks.contains(link)) {
				descendants.push(link);
				visitedLinks.add(link);
			}
		});

		childNodes.each((parent) => {
			findDescendants(parent);
		});
	};

	findDescendants(node);
	return descendants;
}

export function clearAllHighlighted(diagram: Diagram) {
	diagram.model.commit((m) => {
		diagram.nodes.each((node) => {
			m.set(node, 'isSelected', false);
		});
		diagram.links.each((link) => {
			m.set(link, 'isSelected', false);
		});
	});
}

export function getNodeUnderMouse(diagram: Diagram) {
	return diagram.findPartAt(diagram.lastInput.documentPoint, true);
}

export const freezeLayout = debounce((diagram: go.Diagram) => {
	diagram.commit((d) => {
		d.layout.isInitial = false;
		d.layout.isOngoing = false;
	});
}, 500);

export function loadHiddenNodes(diagram: Diagram | undefined) {
	if (diagram && !diagram.layout.isInitial && !diagram.layout.isOngoing) {
		diagram.commit((d) => {
			d.model.commit((m) => {
				let auxNodes = 0;
				let nodes = 0;
				let nodeLocation = new Point(0, d.documentBounds.height + 16);
				for (let node of diagram.nodes) {
					if (Number.isNaN(node.location.y) && Number.isNaN(node.location.x)) {
						if (node.key === 'Legend' || node.key === 'Standalone') {
							const location = new Point(
								d.documentBounds.width + 200 + auxNodes * 800,
								0,
							);

							m.set(node.location, 'location', location);
							m.setDataProperty(node.data, 'loc', location);
							auxNodes++;
						} else {
							m.set(node.location, 'location', nodeLocation);
							m.setDataProperty(node.data, 'loc', nodeLocation);
							nodes++;

							if (nodes % 10 === 0) {
								nodeLocation = new Point(0, nodeLocation.y + 600);
							} else {
								nodeLocation = new Point(nodeLocation.x + 500, nodeLocation.y);
							}
						}
					}
				}
			});
		});
	}
}
