import { AxiosInstance } from 'axios';
import { Read, T4DataResponse, T4Response } from 'modules/clients/types';
import { Write } from '../../transactions';
import { ConnectionCheck } from '../connections';

//#region Model(s)

export type CreateAccountIntegrationsView = {
	createdAccountIntegrations: string[];
	errors: string[];
};

export type AccountIntegration = {
	id: string;
	accountDisplayName: string;
	accountIdentifier: string;
	cronExpression: string;
	schedule: {
		isScheduleEnabled: boolean;
		triggerTime: string;
		triggerTimezone: string;
	};
	entity4Account: Entity4Account;
	startDate: string;
	endDate: string;
	isConnected: boolean;
	connectionChecks: ConnectionCheck[];
	connectionParameters: AccountIntegrationConnectionParameter[];
	syncEventCount: number;
};

export type AccountIntegrationConnectionParameter = {
	id: string;
	key: string;
	value: string;
};

export type Entity4Account = {
	id: string;
	displayName: string | null;
	naturalAccountNumber: string | null;
	priorDayStatementsAccountNumber: string | null;
	status: string | null;
};

//#endregion

//#region Request(s)

export type CreateAccountIntegrationsInput = {
	accountIntegrations: CreateAccountIntegration[];
};

export type CreateAccountIntegration = {
	connectionId: string;
	accountIdentifier?: string;
};

export type CreateAccountIntegrationsResponse = T4Response<
	T4DataResponse<CreateAccountIntegrationsView>,
	CreateAccountIntegrationsInput
>;

export type UpdateAccountIntegrationInput = {
	connectionId: string;
	accountIntegrationId: string;
	accountIdentifier: string | null;
};

export type UpdateAccountIntegrationsResponse = T4Response<
	T4DataResponse<AccountIntegration>,
	UpdateAccountIntegrationInput
>;

export type SetAccountIntegrationScheduleInput = {
	connectionId: string;
	accountIntegrationId: string;
	isScheduleEnabled: boolean;
	triggerTime: string;
	triggerTimezone: string;
};

export type SetAccountIntegrationScheduleResponse = T4Response<
	T4DataResponse<AccountIntegration>,
	SetAccountIntegrationScheduleInput
>;

export type AssignAccountInput = {
	connectionId: string;
	accountIntegrationId: string;
	entity4AccountId: string;
};

export type AssignAccountResponse = T4Response<
	T4DataResponse<AccountIntegration>,
	AssignAccountInput
>;

export type TestAccountIntegrationInput = {
	connectionId: string;
	accountIntegrationId: string;
};

export type TestAccountIntegrationResponse = T4Response<
	T4DataResponse<AccountIntegration>,
	TestAccountIntegrationInput
>;

//#endregion

export type AccountIntegrationsEndpoints = {
	createAccountIntegrations: Write<
		T4DataResponse<CreateAccountIntegrationsView>,
		CreateAccountIntegrationsInput
	>;
	update: Write<
		T4DataResponse<AccountIntegration>,
		UpdateAccountIntegrationInput
	>;
	setSchedule: Write<
		T4DataResponse<AccountIntegration>,
		SetAccountIntegrationScheduleInput
	>;
	assignAccount: Write<T4DataResponse<AccountIntegration>, AssignAccountInput>;
	connect: Read<
		T4DataResponse<AccountIntegration>,
		TestAccountIntegrationInput
	>;
};

export function accountIntegrations(
	axiosInstance: AxiosInstance,
): AccountIntegrationsEndpoints {
	return {
		createAccountIntegrations: async (
			data,
			config = {},
		): Promise<CreateAccountIntegrationsResponse> => {
			return await axiosInstance.post(
				'api/v1.0/cash4/accountIntegrations',
				data,
				{
					...config,
				},
			);
		},
		update: async (
			data,
			config = {},
		): Promise<UpdateAccountIntegrationsResponse> => {
			return await axiosInstance.put(
				'api/v1.0/cash4/accountIntegrations',
				data,
				{
					...config,
				},
			);
		},
		setSchedule: async (
			data,
			config = {},
		): Promise<SetAccountIntegrationScheduleResponse> => {
			return await axiosInstance.put(
				'api/v1.0/cash4/accountIntegrations/setschedule',
				data,
				{
					...config,
				},
			);
		},
		assignAccount: async (
			data,
			config = {},
		): Promise<AssignAccountResponse> => {
			return await axiosInstance.put(
				'api/v1.0/cash4/accountIntegrations/assignAccount',
				data,
				{
					...config,
				},
			);
		},
		connect: async (
			params,
			config = {},
		): Promise<TestAccountIntegrationResponse> => {
			return await axiosInstance.get(
				'api/v1.0/cash4/accountIntegrations/connections/connect',
				{
					...config,
					params: params,
				},
			);
		},
	};
}
