import { Loop, ZoomIn, ZoomOut } from '@mui/icons-material';
import { debounce, Grid, Typography } from '@mui/material';
import { VisualizationButton } from 'features/entity4/visualizations/_shared/_components/visualizationButton';
import { AutoScale } from 'gojs';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import { stonlyData } from 'stonly/functions';
import { useVisualization } from '../_providers/visualizationProvider';

export type VisualizationsFooterProps = {
	stonlyId: string;
	diagram: go.Diagram | undefined;
	onResetView?: (diagram: go.Diagram) => void;
};

export const VisualizationsFooter: FC<VisualizationsFooterProps> = observer(
	({ stonlyId, diagram, onResetView }) => {
		const { zoom } = useVisualization();

		const onZoomInHandler = useCallback(() => {
			if (diagram) {
				diagram.commandHandler.increaseZoom();
			}
		}, [diagram]);

		const onZoomOutHandler = useCallback(() => {
			if (diagram) {
				diagram.commandHandler.decreaseZoom();
			}
		}, [diagram]);

		const onResetViewHandler = useCallback(async () => {
			if (diagram) {
				onResetView?.(diagram);
				diagram.commit((d) => {
					d.model.commit((m) => {
						d.nodes.each((node) => {
							if (node.data.loc) {
								m.setDataProperty(node.data, 'loc', undefined);
							}
						});
					});
					d.clearSelection();
					d.layout.doLayout(d);
				});

				debounce(
					() =>
						diagram.commit((d) => {
							const rect = d.documentBounds.copy().grow(50, 50, 50, 50);
							d.zoomToRect(rect, AutoScale.Uniform);
							d.centerRect(rect);
						}),
					200,
				)();
			}
		}, [diagram, onResetView]);

		const onZoomToFitHandler = useCallback(() => {
			if (diagram) {
				diagram.commandHandler.zoomToFit();
			}
		}, [diagram]);

		return (
			<Grid
				aria-label="Platform"
				container
				item
				flexWrap="nowrap"
				sx={{
					gap: 2,
					justifyContent: 'center',
					paddingLeft: '2rem',
				}}
			>
				<Grid item xs="auto">
					<VisualizationButton
						id="zoomInBtn"
						title="Zoom In"
						onClick={onZoomInHandler}
						startIcon={<ZoomIn />}
						{...stonlyData({
							id: `${stonlyId}-zoom-in-button`,
						})}
					>
						Zoom In
					</VisualizationButton>
				</Grid>
				<Grid item xs="auto">
					<VisualizationButton
						id="zoomOutBtn"
						title="Zoom Out"
						onClick={onZoomOutHandler}
						startIcon={<ZoomOut />}
						{...stonlyData({
							id: `${stonlyId}-zoom-out-button`,
						})}
					>
						Zoom Out
					</VisualizationButton>
				</Grid>
				<Grid item xs="auto">
					<VisualizationButton
						id="zoomResetBtn"
						title="Reset View"
						onClick={onResetViewHandler}
						startIcon={<Loop />}
						{...stonlyData({
							id: `${stonlyId}-reset-view-button`,
						})}
					>
						Reset View
					</VisualizationButton>
				</Grid>
				<Grid item xs="auto">
					<VisualizationButton
						id="zoomResetBtn"
						title="Zoom To Fit"
						onClick={onZoomToFitHandler}
						startIcon={<Loop />}
						{...stonlyData({
							id: `${stonlyId}-zoom-fit-button`,
						})}
					>
						Zoom To Fit
					</VisualizationButton>
				</Grid>
				<Grid item xs="auto">
					<Typography variant="caption" sx={{ backgroundColor: 'white' }}>
						Current Zoom: {zoom ? (zoom * 100).toFixed(2) : 0}%
					</Typography>{' '}
				</Grid>
			</Grid>
		);
	},
);
