import { Delete } from '@mui/icons-material';
import { Box, Card, Grid, IconButton } from '@mui/material';
import { T4Modal } from 'features/entity4/shared/components/atoms/t4Modal';
import { observer } from 'mobx-react-lite';
import { SigmaElement } from 'modules/clients/customer-api/src/sigmaEmbed';
import { FC, useEffect, useRef, useState } from 'react';
import { useSigmaDashboard } from '../_providers/dashboardProvider';
import { SigmaEmbed } from './sigmaEmbed';

export type DashboardCardProps = {
	isEditMode: boolean;
	sigmaElement: SigmaElement;
	onDelete: (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		item: SigmaElement,
	) => void;
};

export const DashboardCard: FC<DashboardCardProps> = observer(
	({ isEditMode, sigmaElement, onDelete }) => {
		const { hardcodedComponents } = useSigmaDashboard();
		const [isHighlighted, setIsHighlighted] = useState(false);

		const widget = () => {
			return (
				<>
					{sigmaElement.workbookId !== 'hardcoded' ? (
						<SigmaEmbed sigmaVisual={sigmaElement} />
					) : (
						<Card
							key={sigmaElement.elementId}
							elevation={0}
							sx={{
								height: '90%',
								justifyContent: 'center',
								alignItems: 'center',
								margin: 2,
								objectFit: 'contain',
							}}
						>
							{
								hardcodedComponents[
									sigmaElement.elementId as keyof typeof hardcodedComponents
								]
							}
						</Card>
					)}
				</>
			);
		};

		return (
			<>
				{isHighlighted && (
					<T4Modal
						open={isHighlighted}
						onClose={() => setIsHighlighted(false)}
						title={sigmaElement.name}
						fullWidth
					>
						<Grid container>
							<Grid item xs={12} sx={{ height: '65vh' }}>
								{widget()}
							</Grid>
						</Grid>
					</T4Modal>
				)}
				<>
					<>
						{(isEditMode || sigmaElement.vizualizationType === 'Kpi') && (
							<Box
								sx={{
									position: 'absolute',
									zIndex: 4,
									height: '100%',
									width: '100%',
								}}
								onMouseDown={(e) => {
									if (!isEditMode) {
										setIsHighlighted(true);
									}
								}}
							>
								{isEditMode && (
									<Box
										sx={(theme) => ({
											backgroundColor: theme.palette.equityGreen[500],
											cursor: 'move',
											width: '100%',
											height: '8px',
										})}
									/>
								)}
							</Box>
						)}
						{isEditMode && (
							<IconButton
								size="small"
								onMouseDown={(event) => {
									onDelete(event, sigmaElement);
								}}
								sx={(theme) => ({
									zIndex: 5,
									position: 'absolute',
									right: 2,
									top: 2,
									color: 'white',
									backgroundColor: theme.palette.error.main,
									'&:hover': {
										backgroundColor: theme.palette.error.light,
									},
								})}
							>
								<Delete />
							</IconButton>
						)}
						{widget()}
					</>
				</>
			</>
		);
	},
);
