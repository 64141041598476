import { Alert, AlertProps, AlertTitle } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export interface T4AlertProps extends AlertProps {
	title?: string;
	fullWidth?: boolean;
}
export const T4Alert: FC<T4AlertProps> = observer(
	({ id, title, fullWidth, children, ...props }) => (
		<Alert
			id={`t4-alert${id ? `-${id}` : ''}`}
			data-testid="t4-alert"
			{...props}
			sx={{
				...props.sx,
				'&.MuiPaper-root ': {
					marginTop: '0px',
					width: fullWidth ? '100%' : 'auto',
				},
			}}
		>
			{title && (
				<AlertTitle
					id={`t4-alert-title${id ? `-${id}` : ''}`}
					data-testid="t4-alert-title"
				>
					{title}
				</AlertTitle>
			)}
			{children}
		</Alert>
	),
);
