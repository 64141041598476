import {
	VisualizationPreferenceNodeData,
	VisualizationPreferenceOption,
	VisualizationPreferencesReq,
	VisualizationPreferenceView,
} from 'modules/clients/customer-api/src/api/visualizations';
import {} from 'modules/clients/customer-api/src/userPreference';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { AsOfDateKey } from '../../orgChart/models/orgChartTypes';

export type UseUserVisualizationPreferenceProps = {
	isLoading: boolean;
	error: string | undefined;
	optionPreferences: VisualizationPreferenceOption[];
	nodePreferences: VisualizationPreferenceNodeData[];
	views: VisualizationPreferenceView[];
	updatePreferences: (state: {
		options?: VisualizationPreferenceOption[];
		nodes?: VisualizationPreferenceNodeData[];
	}) => Promise<void>;
	createView: (name: string) => Promise<void>;
	selectView: (viewId: string) => Promise<void>;
	overwriteView: (viewId: string) => Promise<void>;
	deleteView: (viewId: string) => Promise<void>;
};

export function useUserVisualizationPreference(
	visualizationCode: string,
	initialState: VisualizationPreferencesReq,
): UseUserVisualizationPreferenceProps {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();

	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string>();
	const [visualizationPreferencesId, setVisualizationPreferencesId] =
		useState<string>();
	const [optionPreferences, setOptionPreferences] = useState<
		VisualizationPreferenceOption[]
	>([]);
	const [nodePreferences, setNodePreferences] = useState<
		VisualizationPreferenceNodeData[]
	>([]);
	const [views, setViews] = useState<VisualizationPreferenceView[]>([]);

	const setPreferences = useCallback<
		(state: {
			options?: VisualizationPreferenceOption[];
			nodes?: VisualizationPreferenceNodeData[];
		}) => Promise<void>
	>(
		async ({ options, nodes }) => {
			setOptionPreferences(options ?? []);
			setNodePreferences(nodes ?? []);
			try {
				await customerApiClient.api.visualizations.updateVisualizationPreferences(
					{
						visualizationCode: visualizationCode,
						req: {
							options: options ?? [],
							nodes: nodes ?? [],
						},
					},
				);
			} catch {
				enqueueSnackbar('Failed to set visualization preferences.', {
					variant: 'error',
				});
			}
		},
		[customerApiClient.api.visualizations, enqueueSnackbar, visualizationCode],
	);

	const updatePreferences = useCallback<
		UseUserVisualizationPreferenceProps['updatePreferences']
	>(
		async ({ options, nodes }) => {
			let nextOptions = [...optionPreferences];
			if (options !== undefined && options !== null) {
				nextOptions =
					nextOptions.filter(
						(x) => !options.some((y) => y.optionId === x.optionId),
					) ?? [];
				options.forEach((x) => nextOptions.push(x));
				setOptionPreferences(nextOptions);
			}

			let nextNodes = [...nodePreferences];
			if (nodes !== undefined && nodes !== null) {
				nextNodes = nodes;
				setNodePreferences(nextNodes);
			}

			try {
				await customerApiClient.api.visualizations.updateVisualizationPreferences(
					{
						visualizationCode: visualizationCode,
						req: {
							options: nextOptions,
							nodes: nextNodes,
						},
					},
				);
			} catch {
				enqueueSnackbar('Failed to update visualization preferences.', {
					variant: 'error',
				});
			}
		},
		[
			customerApiClient.api.visualizations,
			enqueueSnackbar,
			nodePreferences,
			optionPreferences,
			visualizationCode,
		],
	);

	const createView = useCallback(
		async (name: string) => {
			if (visualizationPreferencesId) {
				try {
					const response =
						await customerApiClient.api.visualizations.updateVisualizationPreferenceView(
							{
								visualizationCode: visualizationCode,
								id: visualizationPreferencesId,
								req: {
									name: name,
									options: optionPreferences,
									nodes: nodePreferences,
								},
							},
						);

					if (response && response.data && response.data.data) {
						setViews([
							views.find((x) => x.id === 'previous-state')!,
							...(response.data.data.views ?? []),
						]);
					}
				} catch {
					const errorMessage = 'Failed to create view.';
					setError(errorMessage);
					enqueueSnackbar(errorMessage, {
						variant: 'error',
					});
				}
			}
		},
		[
			customerApiClient.api.visualizations,
			enqueueSnackbar,
			nodePreferences,
			optionPreferences,
			views,
			visualizationCode,
			visualizationPreferencesId,
		],
	);

	const selectView = useCallback(
		async (viewId: string) => {
			const selectedView = views.find((x) => x.id === viewId);
			if (selectedView) {
				setPreferences({
					options: selectedView.options,
					nodes: selectedView.nodes,
				});
			}
		},
		[setPreferences, views],
	);

	const overwriteView = useCallback(
		async (viewId: string) => {
			if (visualizationPreferencesId) {
				try {
					const selectedView = views.find((x) => x.id === viewId);
					if (selectedView) {
						const response =
							await customerApiClient.api.visualizations.updateVisualizationPreferenceView(
								{
									visualizationCode: visualizationCode,
									id: visualizationPreferencesId,
									req: {
										name: selectedView.name ?? '',
										options: optionPreferences,
										nodes: nodePreferences,
									},
								},
							);

						if (response?.data?.data) {
							setViews([
								views.find((x) => x.id === 'previous-state')!,
								...(response.data.data.views ?? []),
							]);
						}
					}
				} catch {
					const errorMessage = 'Failed to create view.';
					setError(errorMessage);
					enqueueSnackbar(errorMessage, {
						variant: 'error',
					});
				}
			}
		},
		[
			customerApiClient.api.visualizations,
			enqueueSnackbar,
			nodePreferences,
			optionPreferences,
			views,
			visualizationCode,
			visualizationPreferencesId,
		],
	);

	const deleteView = useCallback(
		async (viewId: string) => {
			if (visualizationPreferencesId) {
				try {
					const selectedView = views.find((x) => x.id === viewId);
					if (selectedView) {
						const response =
							await customerApiClient.api.visualizations.deleteVisualizationPreferenceView(
								{
									visualizationCode: visualizationCode,
									id: visualizationPreferencesId,
									viewId: viewId,
								},
							);

						if (response?.data?.data) {
							setViews([
								views.find((x) => x.id === 'previous-state')!,
								...(response.data.data.views ?? []),
							]);
						}
					}
				} catch {
					const errorMessage = 'Failed to delete view.';
					setError(errorMessage);
					enqueueSnackbar(errorMessage, {
						variant: 'error',
					});
				}
			}
		},
		[
			customerApiClient.api.visualizations,
			enqueueSnackbar,
			views,
			visualizationCode,
			visualizationPreferencesId,
		],
	);

	const initialize = useCallback(async () => {
		try {
			const getResponse =
				await customerApiClient.api.visualizations.visualizationPreferences({
					visualizationCode: visualizationCode,
				});

			let nextVisualizationPreferences = getResponse?.data?.data;
			if (getResponse?.status === 200 && !nextVisualizationPreferences) {
				const updateResponse =
					await customerApiClient.api.visualizations.updateVisualizationPreferences(
						{
							visualizationCode: visualizationCode,
							req: {
								nodes: [],
								options: initialState.options,
							},
						},
					);

				if (updateResponse.status === 200 && updateResponse?.data?.data) {
					nextVisualizationPreferences = updateResponse.data.data;
				} else {
					throw new Error('Unable to create visualization preferences.');
				}
			} else if (getResponse?.status !== 200 && !nextVisualizationPreferences) {
				throw new Error('Unable to load visualization preferences.');
			}

			let nextVisualizationPreferenceOptions =
				nextVisualizationPreferences?.options ?? [];
			if (
				!nextVisualizationPreferenceOptions.some(
					(x) => x.optionId === AsOfDateKey,
				)
			) {
				nextVisualizationPreferenceOptions.push({
					id: AsOfDateKey,
					optionId: AsOfDateKey,
					hide: false,
					value: new Date().toISOString(),
				});
			}
			initialState.options?.forEach((option, index) => {
				if (
					!nextVisualizationPreferenceOptions.find(
						(x) => x.optionId === option.optionId,
					)
				) {
					nextVisualizationPreferenceOptions.push({
						...option,
						id: index.toString(),
					});
				}
			});

			setVisualizationPreferencesId(nextVisualizationPreferences.id);
			setNodePreferences([]);
			setOptionPreferences(
				nextVisualizationPreferenceOptions.map((x) => {
					if (x.optionId === AsOfDateKey) {
						return {
							...x,
							value: new Date().toISOString(),
						};
					}
					return x;
				}),
			);
			setViews([
				{
					id: 'previous-state',
					name: 'Previous View',
					nodes: nextVisualizationPreferences.nodes ?? [],
					options: nextVisualizationPreferenceOptions,
				},
				...(nextVisualizationPreferences.views ?? []),
			]);
		} catch {
			const errorMessage = 'Failed to load user visualization preferences.';
			setError(errorMessage);
			enqueueSnackbar(errorMessage, {
				variant: 'error',
			});
		} finally {
			setIsLoading(false);
		}
	}, [
		customerApiClient.api.visualizations,
		enqueueSnackbar,
		initialState.options,
		visualizationCode,
	]);

	useEffect(() => {
		initialize();
	}, [initialize]);

	return {
		isLoading: isLoading,
		error: error,
		optionPreferences: optionPreferences,
		nodePreferences: nodePreferences,
		views: views,
		updatePreferences: updatePreferences,
		createView: createView,
		selectView: selectView,
		overwriteView: overwriteView,
		deleteView: deleteView,
	};
}
