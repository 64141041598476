import {
	GraphObject,
	GridLayout,
	Group,
	Margin,
	Panel,
	Shape,
	Size,
	Spot,
	Stretch,
	TextBlock,
} from 'gojs';
import { textColor } from '../constants';

export function groupContainer(
	text: string,
	node?: GraphObject,
	options?: {
		addSeparator?: boolean;
		wrappingCloumn?: number;
	},
): Group {
	const innerNode = new Panel(Panel.Vertical, {
		stretch: Stretch.Horizontal,
	}).add(
		// header
		new Panel(Panel.Horizontal, {
			alignment: Spot.Center,
			height: 64,
		}).add(
			new TextBlock(text, {
				font: 'Bold 34pt Roboto,Helvetica,Arial,sans-serif',
				stroke: textColor,
				margin: new Margin(0, 8, 0, 8),
				alignment: Spot.Center,
			}),
			GraphObject.make('SubGraphExpanderButton', {
				width: 24,
				margin: new Margin(0, 8, 0, 8),
			}),
		),
	);

	if (options?.addSeparator) {
		innerNode.add(
			new Shape('LineH', {
				stretch: Stretch.Horizontal,
				height: 0,
				stroke: '#e2e2e3',
				strokeWidth: 2,
			}).bindObject('visible', 'isSubGraphExpanded', (data) => !!data),
		);
	}

	if (node) {
		innerNode.add(
			node.bindObject('visible', 'isSubGraphExpanded', (data) => !!data),
		);
	}

	return new Group(Group.Auto, {
		stretch: Stretch.Fill,
		selectionAdorned: false,
		layout: new GridLayout({
			wrappingColumn: options?.wrappingCloumn ?? 1,
			spacing: new Size(16, 16),
		}),
	}).add(
		// background
		new Shape('RoundedRectangle', {
			parameter1: 6,
			fill: 'white',
		}),

		innerNode,
	);
}
