import { DateRange } from '@mui/x-date-pickers-pro';
import { AxiosResponse } from 'axios';
import { flow, makeAutoObservable } from 'mobx';
import moment, { Moment } from 'moment';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';
import { parseError } from 'utilities/errors/errorUtils';
import { deleteBalance } from './services';

export type BalancesErrors = {
	startDate: string[];
	endDate: string[];
};

export type Currency = {
	code: string;
	name: string;
};

export type CurrencyConfiguration = {
	reportingCurrencyCode: Currency;
	activeCurrencies: Currency[];
};

export class BalancesModel {
	private _loading: boolean;
	private _errors: BalancesErrors;
	private _error?: string;

	private _startDate: Moment;
	private _endDate: Moment;
	private _maxDate: Moment;
	private _balanceList: BalanceListItem[];

	constructor(startDate: Moment, endDate: Moment) {
		makeAutoObservable(this);

		this._loading = false;
		this._error = undefined;
		this._errors = { startDate: [], endDate: [] };

		this._balanceList = [];
		this._startDate = startDate;
		this._endDate = endDate;
		this._maxDate = moment().subtract(1, 'days');
	}

	public get loading() {
		return this._loading;
	}

	public get error() {
		return this._error;
	}
	public get errors() {
		return this._errors;
	}

	public get balances() {
		return this._balanceList;
	}

	public async deleteBalance(balanceId: string) {
		await deleteBalance(balanceId);
		this.removeFromList(balanceId);
	}

	private removeFromList(balanceId: string) {
		this._balanceList = this._balanceList.filter(
			(balance) => balance.balanceId !== balanceId,
		);
	}

	public setDateRange = ([
		startDate,
		endDate,
	]: DateRange<moment.Moment | null>) => {
		if (startDate) this._startDate = startDate;
		if (endDate) this._endDate = endDate;
	};

	public get startDate() {
		return this._startDate;
	}
	public get endDate() {
		return this._endDate;
	}

	public get dateDefaults(): DateRange<Moment> {
		return [
			moment().subtract(8, 'days').startOf('day'),
			moment().endOf('day'),
		];
	}

	public get maxDate() {
		return this._maxDate;
	}

	public load = flow(function* (
		this: BalancesModel,
		legalEntityGroupIds: string[] = [],
	) {
		try {
			this._loading = true;
			this._error = undefined;
			this._errors = { startDate: [], endDate: [] };

			let queryParams = new URLSearchParams();
			queryParams.append('startDate', this._startDate.format('YYYY-MM-DD'));
			queryParams.append('endDate', this._endDate.format('YYYY-MM-DD'));

			legalEntityGroupIds?.forEach((id) => {
				queryParams.append('legalEntityGroupIds', id);
			});

			const response: AxiosResponse<ApiResponse<BalanceListItem[]>> =
				yield customerApi.get<ApiResponse<BalanceListItem[]>>(`/balances`, {
					params: queryParams,
				});

			if (response.data.errors)
				this._errors = response.data.errors as BalancesErrors;
			else if (response.data.error) this._error = response.data.error;
			else this._balanceList = response.data.value ?? [];
		} catch (error: any) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});
}

export type BalanceListItem = {
	balanceId: string;
	statementDate: string;
	balanceImportedDate: string;
	lastUpdatedDate: string | null;
	c4AccountNumber: string | null;
	e4AccountNumber: string | null;
	e4AccountName: string | null;
	e4AccountId: string | null;
	bankCode: string | null;
	bankName: string | null;
	openingLedgerBalance: number | null;
	openingLedgerBalance_ReportingCurrency: number | null;
	openingAvailableBalance: number | null;
	openingAvailableBalance_ReportingCurrency: number | null;
	totalCreditTransactions: number | null;
	totalCreditTransactions_ReportingCurrency: number | null;
	totalDebitTransactions: number | null;
	totalDebitTransactions_ReportingCurrency: number | null;
	closingLedgerBalance: number | null;
	closingLedgerBalance_ReportingCurrency: number | null;
	closingAvailableBalance: number | null;
	closingAvailableBalance_ReportingCurrency: number | null;
	forwardAvailableBalance0Day: number | null;
	forwardAvailableBalance0Day_ReportingCurrency: number | null;
	forwardAvailableBalance1Day: number | null;
	forwardAvailableBalance1Day_ReportingCurrency: number | null;
	forwardAvailableBalance2Day: number | null;
	forwardAvailableBalance2Day_ReportingCurrency: number | null;
	forwardAvailableBalance3Day: number | null;
	forwardAvailableBalance3Day_ReportingCurrency: number | null;
	transactionCount: number | null;
	debitTransactionsCount: number | null;
	creditTransactionsCount: number | null;
	currency: string | null;
	foreignExchangeRate: number | null;
	foreignExchangeDate: string | null;
	note: string | null;
};
