import { groupContainer } from 'features/entity4/visualizations/_shared/_templates/groupContainer';
import { Placeholder, Point } from 'gojs';

export type StandaloneLegalEntitiesData = {
	key: string;
	isGroup: true;
	isOrphanGroupVisible: boolean;
	loc?: Point;
};

export function standaloneLegalEntities() {
	return groupContainer(
		'Standalone Entities',
		new Placeholder({
			padding: 8,
		}).bind(
			'position',
			'',
			(data: StandaloneLegalEntitiesData) => data.loc ?? undefined,
		),
		{
			wrappingCloumn: 3,
			addSeparator: true,
		},
	).bind(
		'visible',
		'',
		(data: StandaloneLegalEntitiesData) => data.isOrphanGroupVisible ?? true,
	);
}
