import { DiagramProps, ReactDiagram } from 'gojs-react';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import '../_extensions/hyperlinkText';

export type T4DiagramProps = DiagramProps & {
	diagramListeners?: [go.DiagramEventName, go.DiagramEventHandler][];
};

export const T4Diagram: FC<T4DiagramProps> = observer(
	({ diagramListeners, ...rest }) => {
		return <ReactDiagram {...rest} />;
	},
);
