import { AxiosInstance, AxiosResponse } from 'axios';
import { ApiEndpoints, api } from './api';
import { ReferenceDataEndpoints, referenceData } from './api/referenceData';
import { Cash4Endpoints, cash4 } from './cash4';
import { Entity4Endpoints, entity4 } from './entity4';
import { SigmaEmbedEndpoints, sigmaEmbed } from './sigmaEmbed';
import { TransactionEndpoints, transactions } from './transactions';
import { UserPreferenceEndpoints, userPreference } from './userPreference';

export type T4CustomResponse<
	TResult,
	TInput = {},
	TInputValidationErrors = {},
> = {
	success: boolean;
	failure: boolean;
	error: string | null;
	errors:
		| {
				[key in keyof (TInput & TInputValidationErrors)]?: string[];
		  }
		| null;
	value: TResult;
};

export type T4CustomerClient = {
	api: ApiEndpoints;
	entity4: Entity4Endpoints;
	cash4: Cash4Endpoints;
	transactions: TransactionEndpoints;
	referenceData: ReferenceDataEndpoints;
	userPreference: UserPreferenceEndpoints;
	sigmaEmbed: SigmaEmbedEndpoints;
	downloadPdf: (chart: Blob, fileName: string) => Promise<AxiosResponse>;
};

export function getCustomerApiClient(
	axiosInstance: AxiosInstance,
): T4CustomerClient {
	return {
		api: api(axiosInstance),
		entity4: entity4(axiosInstance),
		cash4: cash4(axiosInstance),
		transactions: transactions(axiosInstance),
		referenceData: referenceData(axiosInstance),
		userPreference: userPreference(axiosInstance),
		sigmaEmbed: sigmaEmbed(axiosInstance),
		downloadPdf: async (chart, fileName) => {
			const form = new FormData();
			if (chart) {
				form.append('chartData', chart);
			}
			if (fileName) {
				form.append('fileName', fileName);
			}

			return await axiosInstance.post(
				'/api/visualizations/generate-pdf',
				form,
				{
					responseType: 'blob',
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);
		},
	};
}
