import {
	Margin,
	Panel,
	Picture,
	Point,
	Shape,
	Spot,
	Stretch,
	TextBlock,
	Wrap,
} from 'gojs';
import { groupContainer } from '../../_shared/_templates/groupContainer';
import {
	legendItemFont,
	legendItemHeaderFont,
	textColor,
} from '../../_shared/constants';
import { getSvgIconForAccountType } from '../_utilities/getSvgIcon';
import {
	AccountPurposeColors,
	AccountStatusColors,
	RegionColors,
} from '../models/accountMapTypes';

export type AccountMapLegendNodeData = {
	key: string;
	isGroup: true;
	category: string;
	loc?: Point;

	accountTypes: string[];
	entityRegions: string[];
	accountStatuses: string[];
	accountPurposes: string[];
	isAccountTypeVisible: boolean;
	isEntityRegionVisible: boolean;
	isAccountStatusVisible: boolean;
	isAccountPurposeVisible: boolean;
	isSubaccountVisible: boolean;
	isLegendVisible: boolean;
	hasAutomaticLink: boolean;
	hasManualLink: boolean;
	hasOneWayLink: boolean;
	hasTwoWayLink: boolean;
	hasSubaccount: boolean;
	hasForeignAccount: boolean;
};

export function accountMapLegend() {
	const headerMargin = new Margin(8, 0, 8, 0);
	const panelMargin = new Margin(8, 8, 8, 8);
	const spaceBetweenShapeAndText = new Margin(8, 0, 0, 8);

	return groupContainer(
		'Legend',
		new Panel(Panel.Vertical, {
			stretch: Stretch.Horizontal,
		}).add(
			// connectors
			new Panel(Panel.Vertical, {
				stretch: Stretch.Horizontal,
			})
				.add(
					new Shape('LineH', {
						stretch: Stretch.Horizontal,
						height: 0,
						stroke: '#e2e2e3',
						strokeWidth: 2,
					}),
					new Panel(Panel.Vertical, {
						alignment: Spot.TopLeft,
						padding: 8,
					}).add(
						new TextBlock('Connectors', {
							font: legendItemHeaderFont,
							stroke: textColor,
							alignment: Spot.TopLeft,
							margin: headerMargin,
							wrap: Wrap.Fit,
						}),

						new Panel(Panel.Horizontal, {
							alignment: Spot.TopLeft,
							margin: panelMargin,
						})
							.add(
								new Shape('LineH', {
									width: 35,
									height: 1,
									stroke: 'black',
									strokeWidth: 2,
								}),
								new TextBlock('Automatic Funding', {
									font: legendItemFont,
									stroke: textColor,
									margin: spaceBetweenShapeAndText,
								}),
							)
							.bind(
								'visible',
								'',
								(data: AccountMapLegendNodeData) => !!data.hasAutomaticLink,
							),

						new Panel(Panel.Horizontal, {
							alignment: Spot.TopLeft,
							margin: panelMargin,
						})
							.add(
								new Shape('LineH', {
									width: 35,
									height: 1,
									stroke: 'black',
									strokeWidth: 2,
									strokeDashArray: [5, 2],
								}),
								new TextBlock('Manual Funding', {
									font: legendItemFont,
									stroke: textColor,
									margin: spaceBetweenShapeAndText,
								}),
							)
							.bind(
								'visible',
								'',
								(data: AccountMapLegendNodeData) => !!data.hasManualLink,
							),

						new Panel(Panel.Horizontal, {
							alignment: Spot.TopLeft,
							margin: panelMargin,
						})
							.add(
								new Shape('LineH', {
									width: 25,
									height: 1,
									stroke: 'black',
									strokeWidth: 2,
								}),
								new Shape({
									fromArrow: 'Standard',
								}),
								new TextBlock('One-Way Funding', {
									font: legendItemFont,
									stroke: textColor,
									margin: spaceBetweenShapeAndText,
								}),
							)
							.bind(
								'visible',
								'',
								(data: AccountMapLegendNodeData) => !!data.hasOneWayLink,
							),

						new Panel(Panel.Horizontal, {
							alignment: Spot.TopLeft,
							margin: panelMargin,
						})
							.add(
								new Shape({ toArrow: 'Backward' }),
								new Shape('LineH', {
									width: 15,
									height: 1,
									stroke: 'black',
									strokeWidth: 2,
								}),
								new Shape({ fromArrow: 'Standard' }),
								new TextBlock('Two-Way Funding', {
									font: legendItemFont,
									stroke: textColor,
									margin: spaceBetweenShapeAndText,
								}),
							)
							.bind(
								'visible',
								'',
								(data: AccountMapLegendNodeData) => !!data.hasTwoWayLink,
							),

						new Panel(Panel.Horizontal, {
							alignment: Spot.TopLeft,
							margin: panelMargin,
						})
							.add(
								new Shape('LineH', {
									width: 35,
									height: 1,
									stroke: '#F6802C',
									strokeWidth: 2,
									strokeDashArray: [5, 2],
								}),
								new TextBlock('Subaccount', {
									font: legendItemFont,
									stroke: textColor,
									margin: spaceBetweenShapeAndText,
								}),
							)
							.bind('visible', '', (data: AccountMapLegendNodeData) => {
								return !!data.isSubaccountVisible && !!data.hasSubaccount;
							}),
					),
				)
				.bind('visible', '', (data: AccountMapLegendNodeData) => {
					return (
						!!data.hasAutomaticLink ||
						!!data.hasManualLink ||
						!!data.hasOneWayLink ||
						!!data.hasTwoWayLink ||
						(!!data.isSubaccountVisible && !!data.hasSubaccount)
					);
				}),

			// account types
			new Panel(Panel.Vertical, {
				stretch: Stretch.Horizontal,
			})
				.add(
					new Shape('LineH', {
						stretch: Stretch.Horizontal,
						height: 0,
						stroke: '#e2e2e3',
						strokeWidth: 2,
					}),
					new Panel(Panel.Vertical, {
						alignment: Spot.TopLeft,
						padding: 8,
					}).add(
						new TextBlock('Account Types', {
							font: legendItemHeaderFont,
							stroke: textColor,
							alignment: Spot.TopLeft,
							margin: headerMargin,
						}),
						new Panel(Panel.Vertical, {
							alignment: Spot.TopLeft,
							itemTemplate: new Panel(Panel.Horizontal, {
								alignment: Spot.Left,
								margin: panelMargin,
							}).add(
								new Picture(undefined, {
									margin: new Margin(0, 8, 0, 8),
									alignment: Spot.Left,
									width: 24,
									height: 24,
								}).bind('source', '', (data: string) =>
									getSvgIconForAccountType(data),
								),
								new TextBlock({
									font: legendItemFont,
									stroke: textColor,
									margin: spaceBetweenShapeAndText,
								}).bind('text', '', (data: string) => data || ''),
							),
						}).bind(
							'itemArray',
							'',
							(data: AccountMapLegendNodeData) => data.accountTypes ?? [],
						),
					),
				)
				.bind(
					'visible',
					'',
					(data: AccountMapLegendNodeData) =>
						!!data.isAccountTypeVisible && (data.accountTypes?.length ?? 0) > 0,
				),

			// entity regions
			new Panel(Panel.Vertical, {
				stretch: Stretch.Horizontal,
			})
				.add(
					new Shape('LineH', {
						stretch: Stretch.Horizontal,
						height: 0,
						stroke: '#e2e2e3',
						strokeWidth: 2,
					}),
					new Panel(Panel.Vertical, {
						alignment: Spot.TopLeft,
						padding: 8,
					}).add(
						new TextBlock('Entity Regions', {
							font: legendItemHeaderFont,
							stroke: textColor,
							alignment: Spot.TopLeft,
							margin: headerMargin,
						}),
						new Panel(Panel.Vertical, {
							alignment: Spot.TopLeft,
							itemTemplate: new Panel(Panel.Horizontal, {
								alignment: Spot.Left,
								margin: panelMargin,
							}).add(
								new Shape({
									margin: new Margin(0, 8, 0, 8),
									alignment: Spot.Left,
									strokeWidth: 0,
									width: 24,
									height: 24,
								}).bind(
									'fill',
									'',
									(data: string) => RegionColors.get(data) || 'white',
								),
								new TextBlock({
									font: legendItemFont,
									stroke: textColor,
									margin: spaceBetweenShapeAndText,
								}).bind('text', '', (data: string) => data || ''),
							),
						}).bind(
							'itemArray',
							'',
							(data: AccountMapLegendNodeData) => data.entityRegions ?? [],
						),
					),
				)
				.bind(
					'visible',
					'',
					(data: AccountMapLegendNodeData) =>
						!!data.isEntityRegionVisible &&
						(data.entityRegions?.length ?? 0) > 0,
				),

			// account statuses
			new Panel(Panel.Vertical, {
				stretch: Stretch.Horizontal,
			})
				.add(
					new Shape('LineH', {
						stretch: Stretch.Horizontal,
						height: 0,
						stroke: '#e2e2e3',
						strokeWidth: 2,
					}),
					new Panel(Panel.Vertical, {
						alignment: Spot.TopLeft,
						padding: 8,
					}).add(
						new TextBlock('Account Statuses', {
							font: legendItemHeaderFont,
							stroke: textColor,
							alignment: Spot.TopLeft,
							margin: headerMargin,
						}),
						new Panel(Panel.Vertical, {
							alignment: Spot.TopLeft,
							itemTemplate: new Panel(Panel.Horizontal, {
								alignment: Spot.Left,
								margin: panelMargin,
							}).add(
								new Shape({
									margin: new Margin(0, 8, 0, 8),
									alignment: Spot.Left,
									strokeWidth: 0,
									width: 24,
									height: 24,
								}).bind(
									'fill',
									'',
									(data: string) => AccountStatusColors.get(data) || 'white',
								),
								new TextBlock({
									font: legendItemFont,
									stroke: textColor,
									margin: spaceBetweenShapeAndText,
								}).bind('text', '', (data: string) => data || ''),
							),
						}).bind(
							'itemArray',
							'',
							(data: AccountMapLegendNodeData) => data.accountStatuses ?? [],
						),
					),
				)
				.bind(
					'visible',
					'',
					(data: AccountMapLegendNodeData) =>
						!!data.isAccountStatusVisible &&
						(data.accountStatuses?.length ?? 0) > 0,
				),

			// account purposes
			new Panel(Panel.Vertical, {
				stretch: Stretch.Horizontal,
			})
				.add(
					new Shape('LineH', {
						stretch: Stretch.Horizontal,
						height: 0,
						stroke: '#e2e2e3',
						strokeWidth: 2,
					}),
					new Panel(Panel.Vertical, {
						alignment: Spot.TopLeft,
						padding: 8,
					}).add(
						new TextBlock('Account Purposes', {
							font: legendItemHeaderFont,
							stroke: textColor,
							alignment: Spot.TopLeft,
							margin: headerMargin,
						}),
						new Panel(Panel.Vertical, {
							alignment: Spot.TopLeft,
							itemTemplate: new Panel(Panel.Horizontal, {
								alignment: Spot.Left,
								margin: panelMargin,
							}).add(
								new Shape({
									margin: new Margin(0, 8, 0, 8),
									alignment: Spot.Left,
									strokeWidth: 0,
									width: 24,
									height: 24,
								}).bind(
									'fill',
									'',
									(data: string) => AccountPurposeColors.get(data) || 'white',
								),
								new TextBlock({
									font: legendItemFont,
									stroke: textColor,
									margin: spaceBetweenShapeAndText,
								}).bind('text', ''),
							),
						}).bind(
							'itemArray',
							'',
							(data: AccountMapLegendNodeData) => data.accountPurposes ?? [],
						),
					),
				)
				.bind(
					'visible',
					'',
					(data: AccountMapLegendNodeData) =>
						!!data.isAccountPurposeVisible &&
						(data.accountPurposes?.length ?? 0) > 0,
				),

			// additional visuals
			new Panel(Panel.Vertical, {
				stretch: Stretch.Horizontal,
			})
				.add(
					new Shape('LineH', {
						stretch: Stretch.Horizontal,
						height: 0,
						stroke: '#e2e2e3',
						strokeWidth: 2,
					}),
					new Panel(Panel.Vertical, {
						alignment: Spot.TopLeft,
						padding: 8,
					}).add(
						new TextBlock('Additional Visuals', {
							font: legendItemHeaderFont,
							stroke: textColor,
							alignment: Spot.TopLeft,
							margin: headerMargin,
						}),
						new Panel(Panel.Horizontal, {
							alignment: Spot.TopLeft,
							margin: panelMargin,
						}).add(
							new Picture(getSvgIconForAccountType('public'), {
								margin: new Margin(0, 8, 0, 8),
								alignment: Spot.Left,
								width: 24,
								height: 24,
							}),
							new TextBlock('Foreign Account', {
								font: legendItemFont,
								stroke: textColor,
								margin: spaceBetweenShapeAndText,
							}),
						),
					),
				)
				.bind(
					'visible',
					'',
					(data: AccountMapLegendNodeData) => !!data.hasForeignAccount,
				),
		),
	)
		.bind(
			'visible',
			'',
			(data: AccountMapLegendNodeData) => data.isLegendVisible ?? true,
		)
		.bind(
			'location',
			'',
			(data: AccountMapLegendNodeData) => data.loc ?? undefined,
		);
}
