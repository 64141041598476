/* eslint-disable mobx/missing-observer */
import { Box, CircularProgress } from '@mui/material';
import { FC, useCallback } from 'react';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import {
	ACCESS_DENIED_MESSAGING,
	NOT_FOUND_MESSAGING,
} from 'shared/constants/cannotDisplayMessaging';
import { useUser } from 'shared/hooks/useUser';
import { TransactionListItem } from '../../models';
import { useEditRule } from '../../providers/useEditRule';
import { ManualCategorizationProvider } from '../../providers/useManualCategorization';
import ManualCategorization from './ManualCategorization';
import RulesView from './RulesView';
import TransactionDetails from './TransactionDetails';
import TransactionNotes from './TransactionNotes';
import TransactionAttributes from './TransactionAttributes';
import { Action } from '../../../shared/components/T4ActionMenu';
import ReconciliationSummaryView from '../ReconciliationSummaryView';

export type TransactionDrawerProps = {
	loading: boolean;
	transaction: TransactionListItem | undefined;
	disableLink?: boolean;
	transactionActions?: Action[];
};

export const TransactionDrawer: FC<TransactionDrawerProps> = ({
	loading,
	transaction,
	disableLink,
	transactionActions,
}) => {
	const { cash4 } = useUser();
	const { isEditing, setIsEditing } = useEditRule();

	const handleGoBack = useCallback(() => {
		setIsEditing(false);
	}, [setIsEditing]);

	if (loading) {
		return (
			<Box
				sx={{
					display: 'flex',
					width: '100%',
					padding: '1rem',
					justifyContent: 'center',
				}}
			>
				<CircularProgress />
			</Box>
		);
	}

	if (!cash4.isViewer) {
		return (
			<CannotDisplay
				headingText={ACCESS_DENIED_MESSAGING.HEADING}
				bodyText={ACCESS_DENIED_MESSAGING.BODY}
				imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
			/>
		);
	}
	if (!transaction) {
		return (
			<CannotDisplay
				headingText="Not Found"
				bodyText="Reported transaction doesn't exist."
				imageSrc={NOT_FOUND_MESSAGING.IMAGE}
			/>
		);
	}

	return (
		<ManualCategorizationProvider>
			{!isEditing ? (
				<>
					<TransactionDetails
						transaction={transaction}
						disableLink={disableLink}
						transactionActions={transactionActions}
					/>
					<RulesView transaction={transaction} />
					{transaction.reconciliationSummary && (
						<ReconciliationSummaryView
							summary={transaction.reconciliationSummary}
							currencyCode={transaction.currency}
						/>
					)}
					{cash4.isAuthor === true ? (
						<TransactionAttributes
							transactionId={transaction.id}
							isForecastModelExcluded={transaction.isForecastModelExcluded}
						/>
					) : undefined}
					<TransactionNotes
						transactionId={transaction.id}
						transactionNote={transaction.noteContent}
					/>
				</>
			) : (
				<ManualCategorization onBack={handleGoBack} transaction={transaction} />
			)}
		</ManualCategorizationProvider>
	);
};
