import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useState } from 'react';

const defaultCurrencyCode = 'USD';

export interface GridRowCalculationModel {
	id: string;
	accountAmount: number;
	accountCurrencyCode: string;
	reportingAmount: number;
	reportingCurrencyCode: string;
}

export type UseRowSelectionCalculationsProps = {
	selectionCount: number;
	selectionTotal: number;
	selectionCurrency: string;
	setRowSelectionHandler: (gridSelectionModel: GridRowSelectionModel) => void;
};

export const useRowSelectionCalculations = (
	rows?: GridRowCalculationModel[],
): UseRowSelectionCalculationsProps => {
	const [selectionCount, setSelectionCount] = useState<number>(0);
	const [selectionTotal, setSelectionTotal] = useState<number>(0);
	const [selectionCurrency, setSelectionCurrency] =
		useState<string>(defaultCurrencyCode);

	const rowSelection = (gridSelectionModel: GridRowSelectionModel) => {
		// Calculate Count
		const selection = gridSelectionModel as GridRowId[];
		const count = selection.length;
		setSelectionCount(count);

		// Calculate Currency Code
		const uniqueCurrencyCodes = new Set<string>();
		var reportingCurrency = defaultCurrencyCode;
		rows?.forEach((value: GridRowCalculationModel) => {
			if (selection.includes(value.id)) {
				uniqueCurrencyCodes.add(value.accountCurrencyCode);
				reportingCurrency = value.reportingCurrencyCode || defaultCurrencyCode;
			}
		});
		var useReportingCurrency: boolean = false;
		if (uniqueCurrencyCodes.size === 0) {
			setSelectionCurrency(defaultCurrencyCode);
		} else if (uniqueCurrencyCodes.size === 1) {
			setSelectionCurrency([...uniqueCurrencyCodes][0]);
		} else {
			useReportingCurrency = true;
			setSelectionCurrency(reportingCurrency);
		}

		// Calculate Total Amount
		var total = 0;
		rows?.forEach((value: GridRowCalculationModel) => {
			if (selection.includes(value.id)) {
				total += useReportingCurrency
					? value.reportingAmount
					: value.accountAmount;
			}
		});
		setSelectionTotal(total);
	};

	return {
		selectionCount,
		selectionTotal,
		selectionCurrency,
		setRowSelectionHandler: rowSelection,
	};
};
