import { Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { PaymentTemplate } from 'modules/clients/apiGateway/payments4/paymentTemplates';
import { FC, JSXElementConstructor } from 'react';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { USER_PREFERENCES_FIELD_OPTIONS } from 'shared/utilities/dataGrid/dataGridUtils';

export type PaymentTemplatesTabProps = {
	tableId: string;
	columns: GridColDef[];
	templates: PaymentTemplate[];
	isLoading: boolean;
	gridNoRowsOverlay: JSXElementConstructor<any>;
	gridHeading: JSX.Element;
};

export const PaymentTemplatesTab: FC<PaymentTemplatesTabProps> = ({
	tableId,
	columns,
	templates,
	isLoading,
	gridNoRowsOverlay,
	gridHeading,
}) => {
	return (
		<div role="tabpanel" style={{ height: '100%' }}>
			<Grid
				container
				direction="column"
				wrap="nowrap"
				sx={{ height: '100%', gap: 2 }}
			>
				{gridHeading}

				<Grid item xs={true} sx={{ height: '50vh' }}>
					<UserPreferencesDataGrid<PaymentTemplate>
						stonlyId={`payment-templates-${tableId}`}
						tableKey={`payment-templates-${tableId}`}
						columns={columns}
						rows={templates}
						getRowId={(row) => `${row.id}-${row.templateVersion}`}
						loading={isLoading}
						showToolbar
						showCustomViewButton
						initialState={{
							sorting: {
								sortModel: [
									{
										field: 'initiatorName',
										sort: 'asc',
									},
									{
										field: 'name',
										sort: 'asc',
									},
								],
							},
							pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
						}}
						slots={{
							noRowsOverlay: gridNoRowsOverlay,
						}}
					/>
				</Grid>
			</Grid>
		</div>
	);
};
