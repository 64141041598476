import {
	ProjectedItem,
	Reconciliation,
	ReportedItem,
} from 'modules/clients/customer-api/src/api/cash4';
import { useMemo } from 'react';

export enum ReconciliationStatus {
	Unreconciled = 'Unreconciled',
	PartiallyReconciled = 'PartiallyReconciled',
	Reconciled = 'Reconciled',
	Posted = 'Posted',
}

enum FlowDirection {
	Inflow = 'Inflow',
	Outflow = 'Outflow',
}

export function normalizeReconciliationStatus(status: string): string {
	return status === ReconciliationStatus.PartiallyReconciled
		? 'Partially Reconciled'
		: status === ReconciliationStatus.Posted
		? 'No Reconciliation'
		: status;
}

export function convertToPercentage(value: number): string {
	return `${Math.round((value ?? 0) * 10000) / 100}%`;
}

export function getCurrencyCode(
	reconciliation:
		| Reconciliation
		| [projectedItems: ProjectedItem[], reportedItems: ReportedItem[]]
		| undefined,
) {
	let projectedItems: ProjectedItem[] = [];
	let reportedItems: ReportedItem[] = [];
	if (Array.isArray(reconciliation)) {
		const [_projectedItems, _reportedItems] = reconciliation;
		projectedItems = _projectedItems;
		reportedItems = _reportedItems;
	} else {
		projectedItems = reconciliation?.projectedTransactions ?? [];
		reportedItems = reconciliation?.reportedItems ?? [];
	}

	const currencyCodes = new Map<string, number>();
	projectedItems.forEach((item) => {
		currencyCodes.set(
			item.currencyCode,
			(currencyCodes.get(item.currencyCode) ?? 0) + item.amount,
		);
	});
	reportedItems.forEach((item) => {
		if (item.currencyCode) {
			currencyCodes.set(
				item.currencyCode,
				(currencyCodes.get(item.currencyCode) ?? 0) + item.amount,
			);
		}
	});

	let count = 0;
	let currencyCode = 'USD';
	currencyCodes.forEach((value, key) => {
		if (value > count) {
			count = value;
			currencyCode = key;
		}
	});

	return currencyCode;
}

export function getProjectedItemsTotal(reconciliation: Reconciliation) {
	return reconciliation.projectedTransactions.reduce(
		(acc, item) => acc + item.amount,
		0,
	);
}

export function getReportedItemsTotal(reconciliation: Reconciliation) {
	return reconciliation.reportedItems.reduce(
		(acc, item) => acc + item.amount,
		0,
	);
}

export function calculateVariance(reconciliation: Reconciliation) {
	const projectedItemsTotal = getProjectedItemsTotal(reconciliation);
	const reconciliationDifference = calculateDifference(reconciliation);

	if (reconciliationDifference === 0) {
		// There's no difference; therefore, variance is 0.00%.
		return 0;
	} else if (projectedItemsTotal === 0) {
		// Projected transactions sum amount is zero; therefore, it's a 100% variance.
		return 1;
	} else {
		// Otherwise, calculate the variance from the projected transactions point of view.
		return reconciliationDifference / projectedItemsTotal;
	}
}

export function calculateDifference(reconciliation: Reconciliation) {
	return (
		getProjectedItemsTotal(reconciliation) -
		getReportedItemsTotal(reconciliation)
	);
}

export function calculateAmount(reconciliation: Reconciliation): number {
	const projectedReconciledAmount =
		reconciliation.projectedTransactions.reduce<number>(
			(acc, item) =>
				acc +
				(item.reconciliationStatus === ReconciliationStatus.Reconciled
					? item.amount
					: 0),
			0,
		);
	const reportedReconciledAmount = reconciliation.reportedItems.reduce<number>(
		(acc, item) =>
			acc +
			(item.reconciliationStatus === ReconciliationStatus.Reconciled
				? item.amount
				: 0),
		0,
	);

	if (projectedReconciledAmount >= 0 && reportedReconciledAmount >= 0) {
		/*
		Projected				Reported
		$100 - Reconciled		$100 - Reconciled
		$100 - Partial			$50 - Reconciled
		$100 - Unreconciled		$25 - Reconciled
		Total Reconciled
		$100					$175
		** We take the greater of the two side.
		*/

		return Math.max(projectedReconciledAmount, reportedReconciledAmount);
	} else {
		/*
		Projected				Reported
		-$100 - Reconciled		-$100 - Reconciled
		-$100 - Partial			-$50 - Reconciled
		-$100 - Unreconciled	-$25 - Reconciled
		Total Reconciled
		-$100					-$175
		** We take the lesser of the two side.
		*/

		return Math.min(projectedReconciledAmount, reportedReconciledAmount);
	}
}

export type UseReconciliationCalculationsProps = {
	projectedAmount: number;
	reportedAmount: number;
	records: number;
	difference: number;
	variance: number;
	amount: number;
};

export function useReconciliationCalculations(
	reconciliation: Reconciliation | undefined,
): UseReconciliationCalculationsProps {
	const projectedAmount = useMemo<
		UseReconciliationCalculationsProps['projectedAmount']
	>(() => {
		return reconciliation === undefined
			? 0
			: getProjectedItemsTotal(reconciliation);
	}, [reconciliation]);

	const reportedAmount = useMemo<
		UseReconciliationCalculationsProps['reportedAmount']
	>(() => {
		return reconciliation === undefined
			? 0
			: getReportedItemsTotal(reconciliation);
	}, [reconciliation]);

	const records = useMemo<UseReconciliationCalculationsProps['records']>(() => {
		return (
			(reconciliation?.projectedTransactions?.length ?? 0) +
			(reconciliation?.reportedItems?.length ?? 0)
		);
	}, [
		reconciliation?.projectedTransactions?.length,
		reconciliation?.reportedItems?.length,
	]);

	const difference = useMemo<
		UseReconciliationCalculationsProps['difference']
	>(() => {
		return reconciliation === undefined
			? 0
			: calculateDifference(reconciliation);
	}, [reconciliation]);

	const variance = useMemo<
		UseReconciliationCalculationsProps['variance']
	>(() => {
		const getPercentage = (value: number) => {
			return Math.round(value * 10000) / 100;
		};
		return getPercentage(
			reconciliation === undefined ? 1 : calculateVariance(reconciliation),
		);
	}, [reconciliation]);

	const amount = useMemo<UseReconciliationCalculationsProps['amount']>(() => {
		return reconciliation === undefined ? 0 : calculateAmount(reconciliation);
	}, [reconciliation]);
	return {
		projectedAmount: projectedAmount,
		reportedAmount: reportedAmount,
		records: records,
		difference: difference,
		variance: variance,
		amount: amount,
	};
}
