import { useAuth0, User } from '@auth0/auth0-react';
import { customClaimNamespace, roles } from 'modules/auth0';
import { useCallback } from 'react';

export interface IUserProps {
	org: string;
	user: User;
	id: string;
	name: string;
	isAuthor: boolean;
	isViewer: boolean;
	isApprover: boolean;
	isAccountUser: boolean;
	isAdmin: boolean;
	isDataImporter: boolean;
	isStaffUser: boolean;
	cash4: {
		isAuthor: boolean;
		isViewer: boolean;
		isDataImporter: boolean;
		isIntegrationsAdmin: boolean;
	};
	payments4: {
		isAdmin: boolean;
		isPaymentInitiator: boolean;
		isPaymentApprover: boolean;
		isTemplateCreator: boolean;
		isTemplateApprover: boolean;
	};
}

export function useUser(): IUserProps {
	const { user } = useAuth0();

	if (user === undefined) throw new Error('User is not defined.');

	const hasRole = useCallback(
		(role: string) =>
			(user[`${customClaimNamespace}/roles`] ?? []).some(
				(userRole: string) => userRole === role,
			),
		[user],
	);

	return {
		org: user['org_id'],
		user: user,
		id: user.sub ?? '',
		name: user.name ?? '',
		isAuthor: hasRole(roles.Author),
		isViewer: hasRole(roles.Viewer),
		isApprover: hasRole(roles.Approver),
		isAccountUser: hasRole(roles.AccountUser),
		isAdmin: hasRole(roles.Admin),
		isDataImporter: hasRole(roles.Entity4DataImporter),
		isStaffUser: hasRole(roles.StaffUser),
		cash4: {
			isAuthor: hasRole(roles.Cash4Author),
			isViewer: hasRole(roles.Cash4Viewer),
			isDataImporter: hasRole(roles.Cash4DataImporter),
			isIntegrationsAdmin: hasRole(roles.Cash4IntegrationsAdmin),
		},
		payments4: {
			isAdmin: hasRole(roles.Payments4.Admin),
			isPaymentInitiator: hasRole(roles.Payments4.PaymentInitiator),
			isPaymentApprover: hasRole(roles.Payments4.PaymentApprover),
			isTemplateCreator: hasRole(roles.Payments4.TemplateCreator),
			isTemplateApprover: hasRole(roles.Payments4.TemplateApprover),
		},
	};
}
