/* eslint-disable mobx/missing-observer */
import { TrendingUp } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import {
	GridColDef,
	GridRenderCellParams,
	GridSortModel,
} from '@mui/x-data-grid-pro';
import { DateRange } from '@mui/x-date-pickers-pro';
import {
	QueryKey,
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import moment, { Moment } from 'moment/moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { NoRowsOverlay } from 'shared/components/dataGrid/noRowsOverlay';
import { useUser } from 'shared/hooks/useUser';
import {
	ConfirmationDialog,
	IConfirmationDialogProps,
} from '../../../../shared/components/confirmationDialog';
import { UserPreferencesDataGrid } from '../../../../shared/components/dataGrid/userPreferencesDataGrid';
import T4DateRangePicker from '../../../../shared/components/dateRangePicker/t4DateRangePicker';
import { DeleteMenuItem } from '../../../../shared/components/deleteMenuItem';
import { OverflowMenu } from '../../../../shared/components/overflowMenu';
import {
	PageHeader,
	pageHeaderStonlyIds,
} from '../../../../shared/components/pageHeader';
import { T4View } from '../../../../shared/components/t4View';
import {
	getOptionsMenuColDef,
	USER_PREFERENCES_FIELD_OPTIONS,
} from '../../../../shared/utilities/dataGrid/dataGridUtils';
import { isValidDateRange } from '../../../../shared/utilities/dateUtilities';
import { stonlyData } from '../../../../stonly/functions';
import { InvestmentTransaction } from '../models';
import { deleteInvestmentTransaction, fetchTransactions } from '../services';
import { stonlyIds } from '../stonlyIds';
import {
	getCurrencyColumnDefinition,
	getDateColumnDefinition,
	getDecimalColumnDefinition,
} from 'shared/utilities/dataGrid/columnDefinitions';

const InvestmentTransactionsRoute: React.FC = () => {
	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.investmentTransactionsPage}
					title="Investment Transactions"
				/>
			}
		>
			<TransactionsPage />
		</T4View>
	);
};

export default InvestmentTransactionsRoute;

const TransactionsPage: React.FC = () => {
	const statementDateColumnName = 'statementDate';
	const queryKeyTransactions = 'investment-transactions';
	const defaultDateRange: DateRange<Moment> = [
		moment().subtract(7, 'days').startOf('day'),
		moment().subtract(0, 'days').endOf('day'),
	];
	const [selectedDialog, setSelectedDialog] =
		useState<IConfirmationDialogProps>({} as IConfirmationDialogProps);
	const [dateRange, setDateRange] =
		useState<DateRange<Moment>>(defaultDateRange);
	const [openDialog, setOpenDialog] = useState(false);
	const [confirmationModalLoading, setConfirmationModalLoading] =
		useState(false);

	const [sortModel, setSortModel] = useState<GridSortModel>([
		{
			field: statementDateColumnName,
			sort: 'desc',
		},
	]);

	const handleDateRangeChange = (dateRange: DateRange<Moment>) => {
		if (isValidDateRange(dateRange)) {
			setDateRange(dateRange);
		}
	};
	const { enqueueSnackbar } = useSnackbar();
	const { cash4 } = useUser();
	const { data: transactions, isLoading: transactionsLoading } = useQuery(
		[queryKeyTransactions, dateRange],
		() => fetchTransactions(dateRange),
		{
			initialData: [],
		},
	);
	const queryClient = useQueryClient();

	useEffect(() => {
		setSortModel([
			{
				field: statementDateColumnName,
				sort: 'desc',
			},
		]);
	}, [transactions]);

	const customNoRowOverlay = useMemo(
		() => () => (
			<NoRowsOverlay
				icon={TrendingUp}
				heading={<Typography variant="h3">No Transactions Found</Typography>}
				body={
					<Typography variant="body1">
						Looks like there are no investment transactions. Please adjust the
						date range or contact Customer Service at{' '}
						<a href="mailto:support@treasury4.com">support@treasury4.com</a> to
						add investment transaction data.
					</Typography>
				}
			/>
		),
		[],
	);

	const getRowId = (row: any) => row.investmentTransactionId;

	const handleClickDelete = (row: InvestmentTransaction) => {
		setSelectedDialog({
			title: 'Delete investment transaction?',
			text: 'This investment transaction will be permanently deleted from the system. This action cannot be undone.',
			primaryButtonText: 'DELETE',
			onPrimaryButtonClick: () => {
				handleDeleteInvestmentTransaction(row.investmentTransactionId);
			},
			secondaryButtonText: 'CANCEL',
			onSecondaryButtonClick: handleCloseAll,
		} as IConfirmationDialogProps);

		handleOpenDialog();
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleCloseAll = () => {
		handleCloseDialog();
	};

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleDeleteInvestmentTransaction = (
		investmentTransactionId: string,
	) => {
		setConfirmationModalLoading(true);
		try {
			if (investmentTransactionId) {
				mutation.mutate({
					InvestmentTransactionId: investmentTransactionId,
				});
			}
			handleCloseDialog();
		} catch {
		} finally {
			setConfirmationModalLoading(false);
		}
	};

	const deleteTransaction = (data: { InvestmentTransactionId: string }) => {
		const { InvestmentTransactionId } = data;
		return deleteInvestmentTransaction(InvestmentTransactionId);
	};

	const handleRefetch = (queryKey: QueryKey) => {
		queryClient.refetchQueries(queryKey);
	};

	const mutation = useMutation(deleteTransaction, {
		onSuccess: () => {
			handleRefetch([queryKeyTransactions]);
			enqueueSnackbar('Successfully deleted transaction', {
				variant: 'success',
			});
		},
		onError: () => {
			enqueueSnackbar('Failed to delete transaction', {
				variant: 'error',
			});
		},
	});

	let columns: GridColDef[] = [
		{
			field: 'cusip',
			headerName: 'CUSIP',
			width: 150,
		},
		{
			field: 'isin',
			headerName: 'ISIN',
			width: 150,
		},
		{
			...getDateColumnDefinition(),
			field: statementDateColumnName,
			headerName: 'Statement Date',
			width: 150,
		},
		{
			field: 'e4AccountName',
			headerName: 'Account Name',
			width: 300,
		},
		{
			field: 'accountNumber',
			headerName: 'C4 Account Number',
			width: 300,
		},
		{
			field: 'e4AccountNumber',
			headerName: 'Account Number',
			width: 150,
		},
		{
			field: 'bankCode',
			headerName: 'Bank Code',
			width: 150,
		},
		{
			field: 'currencyCode',
			headerName: 'Currency',
			width: 150,
		},
		{
			...getDecimalColumnDefinition(),
			field: 'shares',
			headerName: 'Shares',
			width: 150,
		},
		{
			...getCurrencyColumnDefinition(),
			field: 'tradePrice',
			headerName: 'Trade Price',
			width: 150,
		},
		{
			...getDateColumnDefinition(),
			field: 'tradeDate',
			headerName: 'Trade Date',
			width: 150,
		},
		{
			...getCurrencyColumnDefinition(),
			field: 'settlementAmount',
			headerName: 'Settlement Amount',
			width: 150,
		},
		{
			...getDateColumnDefinition(),
			field: 'settlementDate',
			headerName: 'Settlement Date',
			width: 150,
		},
		{
			field: 'providerTransactionId',
			headerName: 'Provider Transaction Id',
			width: 150,
		},
		{
			field: 'providerTransactionType',
			headerName: 'Provider Transaction Type',
			width: 150,
		},
		{
			field: 'providerTransactionTypeDescription',
			headerName: 'Provider Transaction Type Description',
			width: 150,
		},
	];

	if (cash4.isAuthor) {
		columns.push({
			...getOptionsMenuColDef(
				(params: GridRenderCellParams<InvestmentTransaction>) => {
					if (!params) {
						return null;
					}

					return (
						<OverflowMenu
							id={`investment-transactions-action-menu-${params.row.investmentTransactionId}`}
							iconButtonProps={{
								...stonlyData({
									id: 'investment-transactions-action-menu-button',
								}),
							}}
						>
							<DeleteMenuItem
								onClick={() => handleClickDelete(params.row)}
								{...stonlyData({
									id: `investment-transactions-delete`,
								})}
							/>
						</OverflowMenu>
					);
				},
			),
		});
	}

	return (
		<>
			<Grid
				container
				direction="column"
				wrap="nowrap"
				rowGap={1}
				sx={{ height: '100%' }}
			>
				<Grid container item xs={1} columnGap={3}>
					<Grid item>
						<T4DateRangePicker
							value={dateRange}
							onChange={(newValue) => {
								handleDateRangeChange(newValue);
							}}
							disableFuture
							showShortcuts
							shortcutResetDefaults={defaultDateRange}
							id={stonlyIds.investmentsTransactionsRoot}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12} sx={{ height: '65vh' }}>
					<UserPreferencesDataGrid
						stonlyId={stonlyIds.investmentsTransactionsGrid}
						getRowId={getRowId}
						tableKey="investment-transactions-page"
						columns={columns}
						rows={transactions}
						loading={transactionsLoading}
						calculateColumnWidths
						columnVisibilityModel={{
							e4AccountNumber: false,
							settlementDate: false,
							providerTransactionId: false,
							providerTransactionType: false,
							providerTransactionTypeDescription: false,
						}}
						sortModel={sortModel}
						onSortModelChange={(newSortModel) => {
							setSortModel(newSortModel);
						}}
						initialState={{
							pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
						}}
						showToolbar
						showCustomViewButton
						slotProps={{
							toolbar: {
								quickFilterProps: {
									inputProps: {
										...stonlyData({
											id: stonlyIds.investmentsTransactionsGridFilter,
										}),
									},
								},
							},
						}}
						slots={{
							noRowsOverlay: customNoRowOverlay,
						}}
						pagination
						hideFooter={false}
						autoPageSize
					/>
				</Grid>
			</Grid>
			<ConfirmationDialog
				open={openDialog}
				onClose={handleCloseDialog}
				title={selectedDialog.title || ''}
				text={selectedDialog.text}
				primaryButtonText={selectedDialog.primaryButtonText}
				secondaryButtonText={selectedDialog.secondaryButtonText}
				onPrimaryButtonClick={selectedDialog.onPrimaryButtonClick}
				onSecondaryButtonClick={selectedDialog.onSecondaryButtonClick}
				stonlyIds={{
					confirmationModal:
						stonlyIds.investmentsTransactionsDeleteConfirmationModal,
					secondaryButton:
						stonlyIds.investmentsTransactionsDeleteConfirmationCancelButton,
					primaryButton:
						stonlyIds.investmentsTransactionsDeleteConfirmationDeleteButton,
				}}
				loading={confirmationModalLoading}
			/>
		</>
	);
};
