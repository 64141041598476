import { AxiosInstance } from 'axios';
import { Read, T4ApiResponse, Write } from 'modules/clients/types';
import { Payment, PaymentAmount, PaymentParty } from '../payments';
import { User } from '..';

export enum PaymentTemplateStatusTypes {
	Created = 1,
	Approved = 2,
	Rejected = 3,
	Deleted = 4,
	Draft = 5,
	Archived = 6,
}

export type PaymentTemplate = {
	id: string;
	version: number;
	templateVersion: number;
	name: string;
	currentStatus: string;
	paymentType: string;
	priorityType: string;
	currencyCode: string;
	initiator: PaymentParty;
	payee: PaymentParty;
	referenceData: string | null;
	statusHistory: PaymentTemplateStatusHistory[];
	isDraftVersion: boolean;
	isCurrentVersion: boolean;
	hasDraftVersion: boolean;
	createdOn: string;
};

export type PaymentTemplateStatusHistory = {
	paymentTemplateStatusType: string;
	templateVersion: number;
	reason: string | null;
	createdBy: User | null;
	createdOn: string;
};

// #region Requests

export type CreatePaymentTemplateRequest = {
	name: string;
	paymentType: string;
	priorityType: string;
	initiator: PaymentParty;
	payee: PaymentParty;
	currencyCode: string;
	referenceData: string | null;
};

export type UpdatePaymentTemplateRequest = {
	name: string;
	initiator: PaymentParty;
	payee: PaymentParty;
	referenceData: string | null;
};

export type RejectPaymentTemplateRequest = {
	reason: string;
};

export type SubmitPaymentRequest = {
	paymentTemplateVersion: number;
	instructedAmount: PaymentAmount;
	valueDate: string; // DateOnly
	referenceData: string | null;
};

// #endregion

export type PaymentTemplateEndpoints = {
	get: Read<
		T4ApiResponse<PaymentTemplate>,
		{ id: string; version?: number | null }
	>;
	getDraft: Read<T4ApiResponse<PaymentTemplate>, string>;
	getAll: Read<T4ApiResponse<PaymentTemplate[]>>;
	create: Write<T4ApiResponse<PaymentTemplate>, CreatePaymentTemplateRequest>;
	update: Write<
		T4ApiResponse<PaymentTemplate>,
		{ id: string; data: UpdatePaymentTemplateRequest }
	>;
	approve: Write<T4ApiResponse<PaymentTemplate>, string>;
	reject: Write<
		T4ApiResponse<PaymentTemplate>,
		{ id: string; data: RejectPaymentTemplateRequest }
	>;
	submitPayment: Write<
		T4ApiResponse<Payment>,
		{ id: string; data: SubmitPaymentRequest }
	>;
	delete: Write<T4ApiResponse<string>, string>;
};

export function paymentTemplates(
	axiosInstance: AxiosInstance,
): PaymentTemplateEndpoints {
	return {
		get: async ({ id, version }, config = {}) => {
			return await axiosInstance.get(`payments4/paymenttemplates/${id}`, {
				...config,
				params: !!version && {
					version: version,
				},
			});
		},
		getDraft: async (id, config = {}) => {
			return await axiosInstance.get(`payments4/paymenttemplates/${id}/draft`, {
				...config,
			});
		},
		getAll: async (_, config = {}) => {
			return await axiosInstance.get('payments4/paymenttemplates', {
				...config,
			});
		},
		create: async (data, config = {}) => {
			return await axiosInstance.post('payments4/paymenttemplates', data, {
				...config,
			});
		},
		update: async ({ id, data }, config: any = {}) => {
			return await axiosInstance.put(`payments4/paymenttemplates/${id}`, data, {
				...config,
			});
		},
		approve: async (id, config: any = {}) => {
			return await axiosInstance.put(
				`payments4/paymenttemplates/${id}/approve`,
				{
					...config,
				},
			);
		},
		reject: async ({ id, data }, config: any = {}) => {
			return await axiosInstance.put(
				`payments4/paymenttemplates/${id}/reject`,
				data,
				{
					...config,
				},
			);
		},
		submitPayment: async ({ id, data }, config: any = {}) => {
			return await axiosInstance.post(
				`payments4/paymenttemplates/${id}/payments/submit`,
				data,
				{ ...config },
			);
		},
		delete: async (id, config: any = {}) => {
			return await axiosInstance.delete(`payments4/paymenttemplates/${id}`, {
				...config,
			});
		},
	};
}
