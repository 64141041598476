import { GridValidRowModel } from '@mui/x-data-grid';
import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { DataLists } from 'modules/clients/customer-api/src/api/referenceData';
import { useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ObjectsPathParams } from 'shared/constants/paths';
import { useReferenceDataFetcher } from 'shared/hooks/useReferenceDataFetcher';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';
import { Option, ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { convertDate } from 'shared/utilities/dateUtilities';
import { jsonToReferenceData } from 'shared/utilities/referenceDataUtilities';
import { IFieldUpdate } from '../entities/entitiesApiTypes';
import { EntityRepository } from '../entities/entityRepository';
import {
	EntityType,
	entityTypeToEntityTypeId,
	getCodeFieldForObjectType,
} from '../entity4Constants';
import { FieldDataType } from '../shared/fieldSets/fieldTypes';
import FrontendRepository, {
	IListColumnDto,
	IListPageDto,
} from '../shared/repositories/frontendRepository';

export type DeleteResult = {
	errors?: { [key: string]: string[] };
};

export type UseListPageProps = {
	objectType: EntityType;
	parentObjectId?: string;
	parentObjectType?: EntityType;

	loading: boolean;
	error: string | undefined;
	refetch: () => Promise<QueryObserverResult<IListPageDto, Error>>;

	updateField: (
		entityId: string,
		fieldIdentifier: string,
		value: any,
	) => Promise<void>;

	getReferenceList: (referenceListName: string) => ReferenceDataValue[];
	getOptionList: (optionListId: string) => Option[];

	initialColumns: IListColumnDto[];
	initialRows: GridValidRowModel[];
	creationFields: IListColumnDto[];
};

export const useListPage = (): UseListPageProps => {
	const { objectId, objectType } = useParams<
		ObjectsPathParams & {
			objectId?: string;
		}
	>();
	const location = useLocation();
	const { fetch } = useReferenceDataFetcher();

	//#region State

	const subObjectType = useMemo(
		() =>
			location.pathname.includes('subaccounts')
				? EntityType.Subaccount
				: undefined,
		[location.pathname],
	);

	const entityRepository = useMemo(() => {
		let repository = new EntityRepository(objectType, objectId);

		if (subObjectType !== undefined) {
			repository = new EntityRepository(
				subObjectType,
				undefined,
				objectType,
				objectId,
			);
		}

		return repository;
	}, [objectId, objectType, subObjectType]);

	//#endregion

	//#region Data Fetching

	const {
		isLoading: areLegalEntityGroupsLoading,
		isRefetching: areLegalEntityGroupsFetching,
		legalEntityGroupIds,
		legalEntityGroupCollections,
	} = useLegalEntityGroups();

	const {
		data: listPageResponse,
		error: listPageError,
		isFetching: isListFetching,
		refetch: refetchList,
	} = useQuery<IListPageDto, Error>(
		[objectType, subObjectType, objectId, legalEntityGroupIds],
		async () =>
			await FrontendRepository.getListPage(
				subObjectType ?? objectType,
				objectId,
				legalEntityGroupIds,
			),
		{
			enabled:
				!!objectType &&
				!areLegalEntityGroupsLoading &&
				!areLegalEntityGroupsFetching,
			refetchOnWindowFocus: false,
		},
	);

	const referenceListNames = useMemo(
		() => [
			...new Set(
				listPageResponse?.columns
					.filter((x) => x.referenceCollectionName !== undefined)
					.map((x) => x.referenceCollectionName!) ?? [],
			),
		],
		[listPageResponse],
	);

	const optionListIds = useMemo(
		() => [
			...new Set(
				listPageResponse?.columns
					.filter((x) => x.optionListId !== undefined)
					.map((x) => x.optionListId!) ?? [],
			),
		],
		[listPageResponse],
	);

	const { data: referenceDataResponse, isFetching: isReferenceDataFetching } =
		useQuery<DataLists, Error>(
			[objectType, subObjectType, objectId, 'reference-lists'],
			async () => {
				const response = await fetch({
					objectTypeId: entityTypeToEntityTypeId.get(
						subObjectType ?? objectType,
					)!,
					referenceListNames,
					optionListIds,
				});

				if (response) return response;
				throw new Error('Unable to retrieve reference data.');
			},
			{
				enabled: !!listPageResponse && !isListFetching,
				refetchOnWindowFocus: false,
			},
		);

	const initialColumns = useMemo(
		() => listPageResponse?.columns ?? [],
		[listPageResponse],
	);
	const creationFields = useMemo(
		() => initialColumns.filter((x) => x.isRecommendedCreationField),
		[initialColumns],
	);
	const initialRows = useMemo(
		() =>
			listPageResponse?.rows.map((row) => ({
				...row.fields.reduce((total: GridValidRowModel, current) => {
					const definition = listPageResponse.columns.find(
						(x) => x.identifier === current.identifier,
					);

					const isCodeField =
						definition?.identifier ===
						getCodeFieldForObjectType(subObjectType ?? objectType);

					if (isCodeField) {
						total[current.identifier] =
							current.approvedStringValue ?? current.pendingStringValue;
					} else if (definition) {
						switch (definition.fieldType) {
							case FieldDataType.text:
							case FieldDataType.longText:
								total[current.identifier] = current.approvedStringValue;
								break;
							case FieldDataType.date:
								total[current.identifier] = convertDate(
									current.approvedDateValue,
								);
								break;
							case FieldDataType.boolean:
								total[current.identifier] = current.approvedBooleanValue;
								break;
							case FieldDataType.options:
								total[`${current.identifier}_data`] = current.approvedOptionId
									? ({
											id: current.approvedOptionId,
											displayName: current.displayValue,
											code: '',
									  } as Option)
									: undefined;
								total[current.identifier] = current.displayValue;
								break;
							default:
								total[current.identifier] = current.displayValue;
								break;
						}

						if (definition.referenceCollectionName) {
							const refValue = jsonToReferenceData(current.approvedStringValue);
							total[`${current.identifier}_data`] = refValue ?? undefined;
							total[current.identifier] = refValue?.displayName;
						}
					} else {
						total[current.identifier] = current.displayValue;
					}

					return total;
				}, {} as GridValidRowModel),
				id: row.id,
				hasChanges: row.hasChanges,
				hasRejections: row.hasRejections,
				tags: row.tags,
				taxIds: row.taxIds,
				legalEntityGroups: legalEntityGroupCollections.flatMap((y) =>
					y.legalEntityGroups.filter((z) =>
						z.legalEntities.some((a) => a.id === row.id),
					),
				),
			})) ?? [],
		[objectType, subObjectType, legalEntityGroupCollections, listPageResponse],
	);

	// #endregion

	//#region Methods

	const updateField = useCallback(
		async (entityId: string, fieldIdentifier: string, value: any) => {
			await entityRepository.updateField({
				fieldIdentifier,
				value,
				entityId,
			} as IFieldUpdate);
		},
		[entityRepository],
	);

	const getReferenceList = useCallback(
		function (referenceListName: string) {
			return referenceDataResponse?.referenceLists[referenceListName] ?? [];
		},
		[referenceDataResponse],
	);

	const getOptionList = useCallback(
		function (optionListId: string) {
			return referenceDataResponse?.optionLists[optionListId]?.options ?? [];
		},
		[referenceDataResponse],
	);

	//#endregion

	return {
		objectType: subObjectType ?? objectType,
		parentObjectId: objectId,
		parentObjectType: subObjectType ? objectType : undefined,

		loading:
			areLegalEntityGroupsLoading ||
			areLegalEntityGroupsFetching ||
			isListFetching ||
			isReferenceDataFetching,
		error: listPageError?.message,
		refetch: refetchList,

		updateField,

		getReferenceList,
		getOptionList,

		initialColumns,
		initialRows,
		creationFields,
	};
};
