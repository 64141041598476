import { useT4Parameter } from 'features/cash4/_shared/_utilities/useT4Parameter';
import {
	useT4Query,
	UseT4QueryOptions,
	UseT4QueryProps,
} from 'features/cash4/_shared/_utilities/useT4Query';
import { VisualizationDataResponse } from 'modules/clients/customer-api/src/api/visualizations';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';

export type UseVisualizationDataQueryProps =
	UseT4QueryProps<VisualizationDataResponse>;

export function useVisualizationDataQuery(
	_includeAccounts: boolean = true,
	options?: UseT4QueryOptions,
): UseVisualizationDataQueryProps {
	const { customerApiClient } = useClients();
	const { property: includeAccounts } = useT4Parameter(_includeAccounts);

	const query = useCallback(async () => {
		const response = await customerApiClient.api.visualizations.data({
			params: {
				includeAccounts: includeAccounts,
			},
		});

		return response.data?.data;
	}, [customerApiClient.api.visualizations, includeAccounts]);
	const queryContext = useT4Query(query, options);

	return queryContext;
}
