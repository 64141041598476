import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { formatCurrency } from 'utilities/currencyUtils';
import { C4AlertBold } from './c4AlertBold';

export type SelectedTotalProps = {
	amount: number;
	currencyCode: string | undefined;
};

export const SelectedTotal: FC<SelectedTotalProps> = observer(
	({ amount, currencyCode = 'USD' }) => (
		<T4Alert severity="info" fullWidth sx={{ width: '100% ' }}>
			{`The selected transactions have a total of `}
			<C4AlertBold>{`${formatCurrency(amount, {
				currency: currencyCode,
			})} ${currencyCode}`}</C4AlertBold>
			{'.'}
		</T4Alert>
	),
);
