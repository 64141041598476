import { DateRange } from '@mui/x-date-pickers-pro';
import {
	useProjectedItemsQuery,
	UseProjectedItemsQueryProps,
} from 'features/cash4/_queries/useProjectedItemsQuery';
import {
	useReconciliationsQuery,
	UseReconciliationsQueryProps,
} from 'features/cash4/_queries/useReconciliationsQuery';
import {
	useReportedItemsQuery,
	UseReportedItemsQueryProps,
} from 'features/cash4/_queries/useReportedItemsQuery';
import { observer } from 'mobx-react-lite';
import {
	ProjectedItem,
	Reconciliation,
	ReportedItem,
} from 'modules/clients/customer-api/src/api/cash4';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import {
	createContext,
	FC,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useClients } from 'shared/hooks/useClients';
import {
	ReconciliationMode,
	ReconciliationTab,
} from '../_components/reconciliationDrawer';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

//#region Context

type ReconciliationsContextProps = {
	open: boolean;
	mode: ReconciliationMode;
	tab: ReconciliationTab;
	reconciliationQueryContext: UseReconciliationsQueryProps;
	projectedItemsQueryContext: UseProjectedItemsQueryProps;
	reportedItemsQueryContext: UseQueryResult<ReportedItem[] | undefined>;
	reconciliation: Reconciliation | undefined;
	dateRange: DateRange<Moment> | undefined;
	notes: string | undefined;
	projectedItemDrawerOpen: boolean;
	selectedProjectedItem: ProjectedItem | undefined;
	selectedTransaction: ReportedItem | undefined;
	setProjectedItemDrawerOpen: (open: boolean) => void;
	setSelectedProjectedItem: (item: ProjectedItem | undefined) => void;
	setSelectedTransaction: (transaction: ReportedItem | undefined) => void;
	setOpen: (open: boolean) => void;
	setMode: (mode: ReconciliationMode) => void;
	setTab: (tab: ReconciliationTab) => void;
	setDateRange: (dateRange: DateRange<Moment> | undefined) => void;
	setNotes: (notes: string | undefined) => void;
	startReconciliationCreation: () => void;
	updateReconciliation: (
		projectedItems: string[],
		reportedItems: string[],
	) => Promise<void>;
	viewReconciliation: (reconciliation: string | Reconciliation) => void;
	editReconciliation: (reconciliation: Reconciliation) => void;
	deleteReconciliation: (reconciliation: Reconciliation) => void;
	onDrawerClose: () => void;
};

const ReconciliationsContext = createContext<ReconciliationsContextProps>(
	{} as ReconciliationsContextProps,
);

//#endregion

//#region Provider

export type CreateReconciliationTab = 'selection' | 'details';

export type ReconciliationCreateRecord = {
	reportedTransactions: string[];
	projectedTransactions: string[];
	note?: string;
};

export type ReconciliationsProviderProps = {
	children: ReactNode;
};

export const ReconciliationsProvider: FC<ReconciliationsProviderProps> =
	observer(({ children }) => {
		const { customerApiClient } = useClients();
		const { enqueueSnackbar } = useSnackbar();

		//#region Data

		const [dateRange, setDateRange] = useState<DateRange<Moment> | undefined>([
			moment().subtract(1, 'day'),
			moment(),
		]);

		const reconciliationsQueryContext = useReconciliationsQuery();
		const projectedItemsQueryContext = useProjectedItemsQuery({
			reconciliationStatuses: ['Unreconciled'],
		});
        
		const reportedItemsQueryContext = useQuery(
			['reportedItems', dateRange],
			() =>
				customerApiClient.api.cash4
					.reported({
						startDate: dateRange?.[0]?.format('YYYY-MM-DD') ?? '',
						endDate: dateRange?.[1]?.format('YYYY-MM-DD') ?? '',
						reconciliationStatuses: ['Posted'],
					})
					.then((response) => response.data?.data ?? []),
			{
				enabled: !!dateRange && !!dateRange[0] && !!dateRange[1],
				refetchOnWindowFocus: false,
                refetchInterval: 0
			},
		);
		//#endregion

		//#region State

		const [open, setOpen] = useState(false);
		const [mode, setMode] = useState<ReconciliationMode>(
			ReconciliationMode.Create,
		);
		const [reconciliation, setReconciliation] = useState<Reconciliation>();
		const [tab, setTab] = useState<ReconciliationTab>(
			ReconciliationTab.Selection,
		);
		const [notes, setNotes] = useState<string>();

		const [projectedItemDrawerOpen, setProjectedItemDrawerOpen] =
			useState(false);
		const [selectedProjectedItem, setSelectedProjectedItem] =
			useState<ProjectedItem>();
		const [selectedTransaction, setSelectedTransaction] =
			useState<ReportedItem>();

		const onDrawerClose = useCallback(() => {
			setOpen(false);
			setReconciliation(undefined);
			setDateRange([moment().subtract(1, 'day'), moment()]);
			setNotes(undefined);
			setTab(ReconciliationTab.Selection);
		}, []);

		const startReconciliationCreation = useCallback(() => {
			setMode(ReconciliationMode.Create);
			setTab(ReconciliationTab.Selection);
			setOpen(true);
		}, []);

		const viewReconciliation = useCallback(
			async (reconciliation: string | Reconciliation) => {
				if (typeof reconciliation === 'string') {
					try {
						const response = await customerApiClient.api.cash4.reconciliation(
							reconciliation,
						);

						if (response.data?.data) {
							setReconciliation(response.data.data);
							setMode(ReconciliationMode.View);
							setOpen(true);
						} else {
							throw new Error(
								'An error occured while retrieving the reconciliation.',
							);
						}
					} catch {
						enqueueSnackbar(
							'An error occured while retrieving the reconciliation.',
							{
								variant: 'error',
							},
						);
					}
				} else {
					setReconciliation(reconciliation);
					setMode(ReconciliationMode.View);
					setOpen(true);
				}
			},
			[customerApiClient.api.cash4, enqueueSnackbar],
		);

		const editReconciliation = useCallback((reconciliation: Reconciliation) => {
			if (reconciliation) {
				setReconciliation(reconciliation);
				setNotes(reconciliation.notes);
				setMode(ReconciliationMode.Edit);
				setTab(ReconciliationTab.Selection);
				setOpen(true);
			}
		}, []);

		const updateReconciliation = useCallback(
			async (projectedIds: string[], reportedIds: string[]) => {
				if (
					reconciliation &&
					projectedIds &&
					projectedIds.length > 0 &&
					reportedIds &&
					reportedIds.length > 0
				) {
					const response =
						await customerApiClient.api.cash4.updateReconciliation(
							reconciliation.id,
							{
								projectedTransactions: projectedIds,
								reportedTransactions: reportedIds,
								note: (notes?.trim()?.length ?? 0) > 0 ? notes : undefined,
							},
						);

					if (response.data?.data) {
						reconciliationsQueryContext.refetch();
						projectedItemsQueryContext.refetch();
						reportedItemsQueryContext.refetch();
						onDrawerClose();
					} else {
						throw new Error('Unable to update reconciliation.');
					}
				} else {
					enqueueSnackbar(
						'An error occured and the reconciliation could not be updated.',
						{
							variant: 'error',
						},
					);
				}
			},
			[
				customerApiClient.api.cash4,
				enqueueSnackbar,
				notes,
				onDrawerClose,
				projectedItemsQueryContext,
				reconciliation,
				reconciliationsQueryContext,
				reportedItemsQueryContext,
			],
		);

		const deleteReconciliation = useCallback(
			async (reconciliation: Reconciliation) => {
				if (reconciliation) {
					const response =
						await customerApiClient.api.cash4.deleteReconciliation(
							reconciliation.id,
						);

					if (response.data?.data) {
						reconciliationsQueryContext.refetch();
						enqueueSnackbar('The reconciliation was successfully deleted.', {
							variant: 'success',
						});
					} else {
						throw new Error('Unable to delete reconciliation.');
					}
				} else {
					enqueueSnackbar(
						'An error occured and the reconciliation could not be deleted.',
						{
							variant: 'error',
						},
					);
				}
			},
			[
				customerApiClient.api.cash4,
				enqueueSnackbar,
				reconciliationsQueryContext,
			],
		);

		useEffect(() => {
			if (reconciliation) {
				setReconciliation(
					reconciliationsQueryContext.data.find(
						(x) => x.id === reconciliation.id,
					) ?? reconciliation,
				);
			}
		}, [reconciliation, reconciliationsQueryContext.data]);

		//#endregion

		return (
			<ReconciliationsContext.Provider
				value={{
					open: open,
					mode: mode,
					tab: tab,
					reconciliationQueryContext: reconciliationsQueryContext,
					projectedItemsQueryContext: projectedItemsQueryContext,
					reportedItemsQueryContext: reportedItemsQueryContext,
					reconciliation: reconciliation,
					dateRange: dateRange,
					notes: notes,
					projectedItemDrawerOpen: projectedItemDrawerOpen,
					selectedProjectedItem: selectedProjectedItem,
					selectedTransaction: selectedTransaction,
					setProjectedItemDrawerOpen: setProjectedItemDrawerOpen,
					setSelectedProjectedItem: setSelectedProjectedItem,
					setSelectedTransaction: setSelectedTransaction,
					setOpen: setOpen,
					setMode: setMode,
					setTab: setTab,
					setDateRange: setDateRange,
					setNotes: setNotes,
					startReconciliationCreation: startReconciliationCreation,
					updateReconciliation: updateReconciliation,
					viewReconciliation: viewReconciliation,
					editReconciliation: editReconciliation,
					deleteReconciliation: deleteReconciliation,
					onDrawerClose: onDrawerClose,
				}}
			>
				{children}
			</ReconciliationsContext.Provider>
		);
	});

//#endregion

//#region Hook

export type UseReconciliationsContextProps = ReconciliationsContextProps;

export function useReconciliationsContext(): UseReconciliationsContextProps {
	return useContext(ReconciliationsContext);
}

//#endregion
