import {
	Add,
	Cancel,
	CheckCircle,
	FileCopyOutlined,
	Pending,
} from '@mui/icons-material';
import {
	Box,
	Grid,
	ListItemText,
	MenuItem,
	Tab,
	Tabs,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	GridColDef,
	GridRenderCellParams,
	GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import {
	PaymentTemplate,
	PaymentTemplateStatusTypes,
} from 'modules/clients/apiGateway/payments4/paymentTemplates';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { NoRowsOverlay } from 'shared/components/dataGrid/noRowsOverlay';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { FormModal } from 'shared/components/formModal';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import {
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { paths, validIdRegex } from 'shared/constants/paths';
import { useClients } from 'shared/hooks/useClients';
import { useReferenceDataFetcher } from 'shared/hooks/useReferenceDataFetcher';
import { useUser } from 'shared/hooks/useUser';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import {
	DataGridColumnWidths,
	getOptionsMenuColDef,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { stonlyData } from 'stonly/functions';
import { useGetAllPaymentTemplates } from '../hooks/usePaymentTemplates';
import { SubmitPaymentDrawer } from '../submitPaymentDrawer';
import { CreateTemplateDrawer } from './createTemplateDrawer';
import { EditTemplateDrawer } from './editTemplateDrawer';
import { PaymentTemplatesTab } from './paymentTemplatesTab';
import { ReviewTemplateDrawer } from './reviewTemplateDrawer';
import { TemplateDetailsDrawer } from './templateDetailsDrawer/templateDetailsDrawer';
import { getPaymentTemplateStatusText } from './utilities';

const stonlyIds = {
	grid: 'payment-templates',
	optionsMenu: 'payment-template-option-menu',
	sumbmitPaymentMenuItem: 'option-menu-submit-payment',
	editTemplateMenuItem: 'option-menu-edit-template',
	deleteTemplateMenuItem: 'option-menu-delete-template',
	createButton: 'create-payment-template-button',
};

export const TemplatesPageRoutes: FC = () => {
	return (
		<Switch>
			<Route
				path={`${paths.payments4.templates.href}/:templateId`.concat(
					validIdRegex,
				)}
				exact
			>
				<TemplatesPage />
			</Route>
			<Route
				path={
					`${paths.payments4.templates.href}/:templateId`.concat(validIdRegex) +
					'/edit'
				}
				exact
			>
				<TemplatesPage />
			</Route>
			<Route
				path={
					`${paths.payments4.templates.href}/:templateId`.concat(validIdRegex) +
					'/review'
				}
				exact
			>
				<TemplatesPage />
			</Route>
			<Route path={paths.payments4.templates.href} exact>
				<TemplatesPage />
			</Route>

			<Route>
				<CannotDisplay
					headingText={NOT_FOUND_MESSAGING.HEADING}
					bodyText={NOT_FOUND_MESSAGING.BODY}
					imageSrc={NOT_FOUND_MESSAGING.IMAGE}
					buttonText={RETURN_TO_HOME}
					buttonHref={paths.root.href}
				/>
			</Route>
		</Switch>
	);
};

export const TemplatesPage: FC = () => {
	// #region State

	const { payments4 } = useUser();
	const { applicationApiClient } = useClients();
	const { fetch } = useReferenceDataFetcher();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const { templateId: templateIdQueryParam } = useParams<{
		templateId: string | undefined;
	}>();
	const templateVersion = useMemo(
		() =>
			Number.parseInt(
				new URLSearchParams(window.location.search).get('version') ?? '1',
			),
		[],
	);

	const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState<boolean>(false);
	const [templateIdToDelete, setTemplateIdToDelete] = useState<string | null>(
		null,
	);
	const [isDeleteTemplateLoading, setIsDeleteTemplateLoading] =
		useState<boolean>(false);

	const [currencyCodes, setCurrencyCodes] = useState<string[]>([]);

	const { isLoading, isFetching, data, error, refetch } =
		useGetAllPaymentTemplates();
	useEffect(() => {
		if (!isLoading && error?.message) {
			enqueueSnackbar(error.message, {
				variant: 'error',
			});
		}
	}, [isLoading, error, enqueueSnackbar]);

	const [tabIndex, setTabIndex] = useState<number>(0);

	const [templateToSubmitPayment, setTemplateToSubmitPayment] =
		useState<PaymentTemplate | null>(null);

	const [viewTemplateIdentity, setViewTemplateIdentity] = useState<{
		id: string;
		version: number | null;
	} | null>(null);
	const [editTemplateId, setEditTemplateId] = useState<string | null>(null);
	const [reviewTemplateId, setReviewTemplateId] = useState<string | null>(null);
	useEffect(() => {
		if (templateIdQueryParam !== undefined) {
			if ((history.location?.pathname as string | undefined)?.endsWith('edit'))
				setEditTemplateId(templateIdQueryParam);
			else if (
				(history.location?.pathname as string | undefined)?.endsWith('review')
			)
				setReviewTemplateId(templateIdQueryParam);
			else
				setViewTemplateIdentity({
					id: templateIdQueryParam,
					version: templateVersion,
				});
		}
	}, [templateIdQueryParam, templateVersion, history]);

	useEffect(() => {
		(async () => {
			const response = await fetch({
				referenceListNames: ['FunctionalCurrency'],
			});
			if (response) {
				setCurrencyCodes(
					response.referenceLists['FunctionalCurrency'].map((x) => x.value),
				);
			}
		})();

		return () => {
			setCurrencyCodes([]);
		};
	}, [fetch]);

	const deleteTemplate = useCallback(
		async (templateId: string) => {
			try {
				setIsDeleteTemplateLoading(true);
				const response =
					await applicationApiClient.payments4.paymentTemplates.delete(
						templateId,
					);
				if (response.status === 200) {
					enqueueSnackbar('Successfully deleted template.', {
						variant: 'success',
					});
					refetch();
				} else throw new Error();
			} catch {
				enqueueSnackbar('Unable to delete template. Please try again later.', {
					variant: 'error',
				});
			} finally {
				setIsDeleteTemplateLoading(false);
			}
		},
		[refetch, applicationApiClient, enqueueSnackbar],
	);

	// #endregion

	// #region Memoized Values

	const columns: GridColDef[] = useMemo(
		() => [
			{
				...getOptionsMenuColDef(
					(params: GridRenderCellParams<PaymentTemplate>) => (
						<OverflowMenu
							id={stonlyIds.optionsMenu}
							iconButtonProps={{
								...stonlyData({ id: stonlyIds.optionsMenu }),
							}}
						>
							<MenuItem
								onClick={() => {
									setViewTemplateIdentity({
										id: params.row.id,
										version: params.row.templateVersion,
									});
									history.push(
										`${paths.payments4.templates.href}/${params.row.id}?version=${params.row.templateVersion}`,
									);
								}}
							>
								<ListItemText>View Details</ListItemText>
							</MenuItem>
							{params.row.isDraftVersion && (
								<Tooltip
									title={
										!payments4.isTemplateApprover
											? 'You do not have the correct permission to review payment templates. Contact your administrator if you require access.'
											: ''
									}
									placement="right"
									arrow
								>
									<span>
										<MenuItem
											onClick={() => {
												setReviewTemplateId(params.row.id);
												history.push(
													`${paths.payments4.templates.href}/${params.row.id}/review`,
												);
											}}
											disabled={!payments4.isTemplateApprover}
										>
											<ListItemText>Review Template</ListItemText>
										</MenuItem>
									</span>
								</Tooltip>
							)}
							{params.row.currentStatus !==
								PaymentTemplateStatusTypes[
									PaymentTemplateStatusTypes.Rejected
								] && (
								<Tooltip
									title={
										!payments4.isTemplateCreator
											? 'You do not have the correct permission to edit templates. Contact your administrator if you require access.'
											: params.row.isDraftVersion
											? 'Cannot edit a template that is pending approval.'
											: ''
									}
									placement="right"
									arrow
								>
									<span>
										<MenuItem
											{...stonlyData({ id: stonlyIds.editTemplateMenuItem })}
											onClick={() => {
												setEditTemplateId(params.row.id);
												history.push(
													`${paths.payments4.templates.href}/${params.row.id}/edit`,
												);
											}}
											disabled={
												!payments4.isTemplateCreator ||
												params.row.isDraftVersion
											}
										>
											<ListItemText>Edit Template</ListItemText>
										</MenuItem>
									</span>
								</Tooltip>
							)}
							{params.row.currentStatus !==
								PaymentTemplateStatusTypes[
									PaymentTemplateStatusTypes.Rejected
								] && (
								<Tooltip
									title={
										!payments4.isPaymentInitiator
											? 'You do not have the correct permission to submit payments. Contact your administrator if you require access.'
											: !(
													params.row.currentStatus ===
														PaymentTemplateStatusTypes[
															PaymentTemplateStatusTypes.Approved
														] && !params.row.hasDraftVersion
											  )
											? 'Cannot create a payment using a template that is pending approval.'
											: ''
									}
									placement="right"
									arrow
								>
									<span>
										<MenuItem
											{...stonlyData({ id: stonlyIds.sumbmitPaymentMenuItem })}
											onClick={() => setTemplateToSubmitPayment(params.row)}
											disabled={
												!payments4.isPaymentInitiator ||
												!(
													params.row.currentStatus ===
														PaymentTemplateStatusTypes[
															PaymentTemplateStatusTypes.Approved
														] && !params.row.hasDraftVersion
												)
											}
										>
											<ListItemText>Create Payment</ListItemText>
										</MenuItem>
									</span>
								</Tooltip>
							)}
							{params.row.currentStatus !==
								PaymentTemplateStatusTypes[
									PaymentTemplateStatusTypes.Rejected
								] && (
								<Tooltip
									title={
										!payments4.isTemplateCreator
											? 'You do not have the correct permission to delete templates. Contact your administrator if you require access.'
											: params.row.isDraftVersion
											? 'Cannot delete a template draft that is pending approval.'
											: ''
									}
									placement="right"
									arrow
								>
									<span>
										<DeleteMenuItem
											{...stonlyData({ id: stonlyIds.deleteTemplateMenuItem })}
											onClick={() => setTemplateIdToDelete(params.row.id)}
											disabled={
												!payments4.isTemplateCreator ||
												params.row.isDraftVersion
											}
										/>
									</span>
								</Tooltip>
							)}
						</OverflowMenu>
					),
				),
			},
			{
				field: 'currentStatus',
				headerName: 'Status',
				description: 'Status',
				type: 'string',
				minWidth: 200,
				renderCell: (params: GridRenderCellParams<PaymentTemplate>) => {
					const getIcon = () => {
						if (params.row.hasDraftVersion || params.row.isDraftVersion)
							return <Pending color="warning" />;
						else if (
							params.row.currentStatus ===
							PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Approved]
						)
							return <CheckCircle color="success" />;
						else if (
							params.row.currentStatus ===
							PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Rejected]
						)
							return <Cancel color="error" />;
						return null;
					};

					return (
						<Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
							{getIcon()}
							{getPaymentTemplateStatusText(params.row.currentStatus)}
						</Box>
					);
				},
			},
			{
				field: 'name',
				headerName: 'Template Name',
				description: 'Template Name',
				type: 'string',
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'initiatorName',
				headerName: 'Initiator',
				description: 'Initiator',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<PaymentTemplate>) =>
					params.row.initiator.displayName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'initiatorAccountName',
				headerName: 'Initiator Account',
				description: 'Initiator Account',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<PaymentTemplate>) =>
					params.row.initiator.accountDisplayName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'initiatorAccountNumber',
				headerName: 'Initiator Account Number',
				description: 'Initiator Account Number',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<PaymentTemplate>) =>
					params.row.initiator.accountNumber,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'initiatorBank',
				headerName: 'Initiator Bank',
				description: 'Initiator Bank',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<PaymentTemplate>) =>
					params.row.initiator.bank.displayName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeName',
				headerName: 'Payee',
				description: 'Payee',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<PaymentTemplate>) =>
					params.row.payee.displayName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeAccountName',
				headerName: 'Payee Account',
				description: 'Payee Account',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<PaymentTemplate>) =>
					params.row.payee.accountDisplayName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeAccountNumber',
				headerName: 'Payee Account Number',
				description: 'Payee Account Number',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<PaymentTemplate>) =>
					params.row.payee.accountNumber,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeBank',
				headerName: 'Payee Bank',
				description: 'Payee Bank',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<PaymentTemplate>) =>
					params.row.payee.bank.displayName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'paymentType',
				headerName: 'Payment Type',
				description: 'Payment Type',
				type: 'string',
				minWidth: 125,
			},
			{
				field: 'currencyCode',
				headerName: 'CCY',
				description: 'Currency Code',
				type: 'string',
				minWidth: 125,
			},
			{
				...getDateColumnDefinition(),
				field: 'createdOn',
				headerName: 'Created Date',
				description: 'Created Date',
			},
		],
		[payments4, history],
	);

	const CreatePaymentTemplateButton = useMemo(
		() => (
			<T4Button
				id={stonlyIds.createButton}
				{...stonlyData({ id: stonlyIds.createButton })}
				variant="outlined"
				startIcon={<Add />}
				onClick={() => setIsCreateDrawerOpen(true)}
				disabled={!payments4.isTemplateCreator || isFetching}
			>
				Create Template
			</T4Button>
		),
		[payments4, isFetching],
	);

	const GridHeading = useMemo(
		() => (
			<Grid
				item
				xs="auto"
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
				}}
			>
				<Tooltip
					disableFocusListener
					disableTouchListener
					title={
						!payments4.isTemplateCreator
							? 'You do not have the correct permission to create payment templates. Contact your administrator if you require access.'
							: ''
					}
					placement="top"
				>
					<span>{CreatePaymentTemplateButton}</span>
				</Tooltip>
			</Grid>
		),
		[payments4, CreatePaymentTemplateButton],
	);

	const SharedGridNoRowsOverlay = useCallback(
		() => (
			<NoRowsOverlay
				icon={FileCopyOutlined}
				heading={<Typography variant="h3">Nothing here to review</Typography>}
				body={
					<Typography variant="body1">
						Check the Approved templates tab
					</Typography>
				}
			/>
		),
		[],
	);

	const ApprovedTemplatesTab = useMemo(() => {
		const gridNoRowsOverlay = () => (
			<NoRowsOverlay
				icon={FileCopyOutlined}
				heading={
					<Typography variant="h3">No approved templates available</Typography>
				}
				body={
					payments4.isTemplateCreator ? CreatePaymentTemplateButton : undefined
				}
			/>
		);
		return tabIndex === 0 ? (
			<PaymentTemplatesTab
				tableId="approved-templates"
				templates={
					data?.filter(
						(x) =>
							x.currentStatus ===
								PaymentTemplateStatusTypes[
									PaymentTemplateStatusTypes.Approved
								] && !x.hasDraftVersion,
					) ?? []
				}
				columns={columns}
				isLoading={isLoading || isFetching}
				gridNoRowsOverlay={gridNoRowsOverlay}
				gridHeading={GridHeading}
			/>
		) : null;
	}, [
		payments4,
		tabIndex,
		data,
		columns,
		isLoading,
		isFetching,
		CreatePaymentTemplateButton,
		GridHeading,
	]);

	const PendingApprovaTemplatesTab = useMemo(
		() =>
			tabIndex === 1 ? (
				<PaymentTemplatesTab
					tableId="pending-approval"
					templates={data?.filter((x) => x.isDraftVersion === true) ?? []}
					columns={columns}
					isLoading={isLoading || isFetching}
					gridNoRowsOverlay={SharedGridNoRowsOverlay}
					gridHeading={GridHeading}
				/>
			) : null,
		[
			tabIndex,
			data,
			columns,
			isLoading,
			isFetching,
			SharedGridNoRowsOverlay,
			GridHeading,
		],
	);

	const RejectedTemplatesTab = useMemo(
		() =>
			tabIndex === 2 ? (
				<PaymentTemplatesTab
					tableId="rejected"
					templates={
						data?.filter(
							(x) =>
								x.currentStatus ===
								PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Rejected],
						) ?? []
					}
					columns={columns}
					isLoading={isLoading || isFetching}
					gridNoRowsOverlay={SharedGridNoRowsOverlay}
					gridHeading={GridHeading}
				/>
			) : null,
		[
			tabIndex,
			data,
			columns,
			isLoading,
			isFetching,
			SharedGridNoRowsOverlay,
			GridHeading,
		],
	);

	const SubmitDrawer = useMemo(
		() => (
			<SubmitPaymentDrawer
				isOpen={!!templateToSubmitPayment}
				onClose={() => setTemplateToSubmitPayment(null)}
				template={templateToSubmitPayment}
			/>
		),
		[templateToSubmitPayment],
	);

	const CreateDrawer = useMemo(
		() => (
			<CreateTemplateDrawer
				isOpen={isCreateDrawerOpen}
				onClose={() => setIsCreateDrawerOpen(false)}
				refetch={refetch}
				currencyCodes={currencyCodes}
			/>
		),
		[isCreateDrawerOpen, refetch, currencyCodes],
	);

	const DetailsDrawer = useMemo(
		() => (
			<TemplateDetailsDrawer
				templateId={viewTemplateIdentity?.id ?? null}
				version={viewTemplateIdentity?.version ?? null}
				onClose={() => {
					setViewTemplateIdentity(null);
					history.push(paths.payments4.templates.href);
				}}
			/>
		),
		[viewTemplateIdentity, history],
	);

	const EditDrawer = useMemo(
		() => (
			<EditTemplateDrawer
				templateId={editTemplateId}
				onClose={() => {
					setEditTemplateId(null);
					history.push(paths.payments4.templates.href);
				}}
				refetchTemplatesList={refetch}
			/>
		),
		[editTemplateId, history, refetch],
	);

	const ReviewDrawer = useMemo(
		() => (
			<ReviewTemplateDrawer
				templateId={reviewTemplateId}
				onClose={() => {
					setReviewTemplateId(null);
					history.push(paths.payments4.templates.href);
				}}
				refetch={refetch}
			/>
		),
		[reviewTemplateId, history, refetch],
	);

	const DeleteTemplateModal = useMemo(
		() => (
			<FormModal
				title="Delete template?"
				description="This template will be permanently deleted from the system. This action cannot be undone."
				open={!!templateIdToDelete}
				onClose={() => setTemplateIdToDelete(null)}
				onSubmit={async () => {
					if (templateIdToDelete !== null) {
						await deleteTemplate(templateIdToDelete);
						setTemplateIdToDelete(null);
					}
				}}
				loading={isDeleteTemplateLoading}
				submitButtonLabel="Delete"
				submitButtonColor="error"
				submitDisabled={isDeleteTemplateLoading}
			/>
		),
		[templateIdToDelete, deleteTemplate, isDeleteTemplateLoading],
	);

	// #endregion

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.payments4.templatesPage}
					title="Templates"
				/>
			}
		>
			<Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
				<Tabs
					indicatorColor="primary"
					value={tabIndex}
					onChange={(_, index) => setTabIndex(index)}
					sx={{ marginBottom: '1rem' }}
				>
					<Tab label="Approved" tabIndex={0} />
					<Tab label="Pending Approval" tabIndex={1} />
					<Tab label="Rejected" tabIndex={2} />
				</Tabs>

				{ApprovedTemplatesTab}
				{PendingApprovaTemplatesTab}
				{RejectedTemplatesTab}
			</Box>

			{SubmitDrawer}

			<FormModal
				title="Delete template?"
				description="This template will be permanently deleted from the system. This action cannot be undone."
				open={!!templateIdToDelete}
				onClose={() => setTemplateIdToDelete(null)}
				onSubmit={async () => {
					if (templateIdToDelete !== null) {
						await deleteTemplate(templateIdToDelete);
						setTemplateIdToDelete(null);
					}
				}}
				loading={isDeleteTemplateLoading}
				submitButtonLabel="Delete"
				submitButtonColor="error"
				submitDisabled={isDeleteTemplateLoading}
			/>

			{CreateDrawer}
			{EditDrawer}
			{ReviewDrawer}
			{DetailsDrawer}
			{DeleteTemplateModal}
		</T4View>
	);
};
