import { Grid } from '@mui/material';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { FormModal } from 'shared/components/formModal';
import { useAddFolderButton } from 'shared/hooks/useAddFolderButton';

type AddFolderButtonProps = {
	open: boolean;
	setOpen: (open: boolean) => void;
	parentId: string;
};

export const AddFolderButton: FC<AddFolderButtonProps> = observer(
	({ open, setOpen, parentId }) => {
		const { isLoading, error, isSubmitDisabled, name, setName, onSubmit } =
			useAddFolderButton({ open, setOpen, parentId });

		const handleClose = () => setOpen(false);

		return (
			<>
				<FormModal
					open={open}
					onClose={handleClose}
					onSubmit={() => onSubmit()}
					fullWidth
					title="Create Folder"
					description="Create a new folder"
					loading={isLoading}
					submitButtonLabel="Create"
					submitDisabled={isSubmitDisabled}
					loadingMessage="Creating"
				>
					<Grid container spacing={2} direction="column">
						<Grid item>
							<T4TextFieldV2
								id="report-name"
								label="Name"
								value={name ?? ''}
								onChange={setName}
								required
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<T4AlertStack error={error} />
					</Grid>
				</FormModal>
			</>
		);
	},
);
